import { decamelizeKeys } from "humps";
import client from "../utils/client";
import RestApi from "../utils/restApi";

const save = async (hierarchyTrees, siteConfiguration) => {
  const response = await client.put(`/hierarchy_trees/update`, {
    hierarchy_trees: decamelizeKeys(hierarchyTrees),
    parameters: decamelizeKeys(siteConfiguration),
  });
  
  return response;
};

const fetch = async (search = {}) => {
  const response = await client.get('/hierarchy_trees.json');
  return response;
}

const HierarchyTrees = {
  ...RestApi({ resourceName: "hierarchy_tree" }),
  save,
  fetch
};

export default HierarchyTrees;