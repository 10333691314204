//@ts-nocheck
import React, { useState } from "react";
import {
  Col,
  Divider,
  Button,
  Layout,
  Collapse,
} from "antd";
import some from 'lodash/some';
import { useIntl } from "react-intl";
import AntForm from "@9troisquarts/ant-form.ant-form";
import { User, ApplicationType } from "../types";
import TranslationProvider from '../utils/TranslationProvider';
import styles from "./index.module.sass";
import client from "../utils/client";
import { getTenantColorClassName } from '../utils/tenantTheme';
import NotificationForm from "../pages/User/components/UserEdit/EditNotification";
import CxLogo from "../../../../public/cx_logo.png";
import castAttributesFromModel from "../utils/castAttributesFromDefinition";
import { getApplication } from "../pages/User/components/UserEdit/utils";
import PasswordRules from "../components/PasswordRules";

const { Panel } = Collapse;
const logoForType = {
  cxfirst: CxLogo,
  "network-manager": CxLogo,
  "micro-training": CxLogo,
};

interface IProps {
  user: User;
  availableLocales: string[];
  redirectUrl: string;
  applicationName: string;
  tenantUrl: string;
  applications: ApplicationType[];
  availableStreams: string[];
}

const profileSchema = (user, intl) => {
  let schema = [{
    name: "firstname",
    label: intl.formatMessage({ id: "activerecord.attributes.user.firstname" }),
    input: {
      type: "string",
    }
  },
  {
    name: "lastname",
    label: intl.formatMessage({ id: "activerecord.attributes.user.lastname" }),
    input: {
      type: "string",
    }
  },
  {
    name: "email",
    label: intl.formatMessage({ id: "activerecord.attributes.user.email" }),
    input: {
      type: "string",
    }
  }];

  schema = schema.concat([{
    name: "avatar",
    label: intl.formatMessage({ id: "activerecord.attributes.user.avatar" }),
    input: {
      type: "upload",
      inputProps: {
        maxCount: 1,
      }
    }
  },
  //@ts-ignore
    <>
      {!user.avatar && user.avatarUrl && (
        <img src={user.avatarUrl} style={{ width: "100px" }} alt="avatar" />
      )}
    </>
  ]);

  return schema;
};

const Profile: React.FC<IProps> = props => {
  const {
    user,
    availableLocales,
    applicationName,
    tenantUrl,
    tenant,
    applications,
    availableStreams,
    defaultKeys,
  } = props;

  const intl = useIntl();
  const [editingUser, setEditingUser] = useState(user);
  const [editingAppSettings, setEditingAppSettings] = useState(user.applicationSettings);
  const [editingPassword, setEditingPassword] = useState({});
  const [errors, setErrors] = useState<any>({});

  const onSubmit = () => {
    client.put(`/profile/${editingUser.id}/update`, { user: editingUser, application_settings: editingAppSettings, password: editingPassword }).then((data: any) => {
      if (Object.keys(data?.errors).length === 0) {
        client.put(`/profile/${editingUser.id}/update`, castAttributesFromModel({
          id: "String",
          avatar: "File",
          email: "String",
        }, editingUser, "user")).then((data: any) => {
          if (Object.keys(data.errors).length === 0) {
            window.close();
          }
        }).catch(({ errors }) => {
          setErrors(errors);
        });
      }
    }).catch(({ errors }) => {
      setErrors(errors);
    });
  };

  const onChangeAppSettings = (values, appSetting) => {
    const newAppSettings = [...editingAppSettings];
    const appSettingIndex = newAppSettings.findIndex(app => app.id === appSetting.id);
    const newAppSetting = { ...newAppSettings[appSettingIndex], ...values };
    newAppSettings[appSettingIndex] = newAppSetting;
    setEditingAppSettings(newAppSettings);
  };

  const hasNotification = () => {
    if (!availableStreams) return false;

    const cxfirstAppSetting = editingAppSettings?.filter(app => app.type === "cxfirst");

    if (cxfirstAppSetting?.filter(appSetting => appSetting.roles?.includes("admin") || appSetting.roles?.includes("data_manager")).length > 0) {
      return true;
    }
    
    if (cxfirstAppSetting?.filter(appSetting => availableStreams[appSetting.applicationId || ""].filter(stream => appSetting.streamIds?.includes(stream.id)).filter(stream => stream.notificationsActive && appSetting.streamIds?.includes(stream.id)).length > 0).length === 0) {
      return false;
    }

    return true;
  };
  
  return (
    <Layout
      className={`${styles.layoutWrapper} ${getTenantColorClassName(tenant.navigationColor, tenant.primaryColor, tenant.secondaryColor)}`}
      id="layout-network-manager"
    >
      <div className={styles.sidebar} style={{ backgroundColor: tenant.navigationColor }} />
      <div className={styles.contentWrapper}>
        <div
          className={styles.headerWrapper}
          style={{ paddingLeft: "80px" }}
        >
          <div className={styles.header}>
            <img style={{ width: "150px" }} src={tenant.logo} alt="tenant-logo" />
            &nbsp;
            <h3 style={{ fontSize: "16px" }}>{tenant.title}</h3>
          </div>
        </div>
        <div style={{ padding: "80px 15px 15px 15px", height: "100%" }}>
          <div className={styles.formWrapper}>
            <Col span={10}>
              <Collapse ghost accordion defaultActiveKey={defaultKeys}>
                <Panel header={intl.formatMessage({ id: "profile.user" })} key="user">
                  <AntForm
                    object={editingUser}
                    onChange={(values) => setEditingUser({ ...editingUser, ...values })}
                    layout="vertical"
                    errors={errors?.user}
                    schema={profileSchema(editingUser, intl)}
                  />
                </Panel>
                { (editingAppSettings || editingUser.networkManagerAccess) && (
                  <>
                    <Panel header={intl.formatMessage({ id: "profile.locale" })} id="locale-panel">
                      {editingAppSettings && editingAppSettings.length > 0 && editingAppSettings.map(appSetting => (
                        <AntForm
                          object={appSetting}
                          onChange={(values) => onChangeAppSettings(values, appSetting)}
                          layout="vertical"
                          errors={errors?.application_setting}
                          schema={[{
                            name: "locale",
                            label: getApplication(applications, appSetting.applicationId).name,
                            input: {
                              type: "select",
                              inputProps: {
                                getPopupContainer: () => document.getElementById('locale-panel')
                              },
                              options: availableLocales[appSetting.applicationId].map(locale => ({ value: locale, label: intl.formatMessage({ id: `locales.${locale}` }) }))
                            }
                          }]}
                        />
                      ))}
                      {user.networkManagerAccess && (
                        <AntForm
                          object={editingUser}
                          onChange={(values) => setEditingUser({ ...editingUser, ...values })}
                          layout="vertical"
                          errors={errors?.user}
                          schema={[{
                            name: "locale",
                            label: "Network Manager",
                            input: {
                              type: "select",
                              inputProps: {
                                getPopupContainer: () => document.getElementById('locale-panel')
                              },
                              options: availableLocales?.networkManager?.map(locale => ({ value: locale, label: intl.formatMessage({ id: `locales.${locale}` }) }))
                            }
                          }]}
                        />
                      )}
                    </Panel>
                    {editingAppSettings && editingAppSettings.filter(appSetting => appSetting.type === "cxfirst").length > 0 && hasNotification() && (
                      <Panel header={"Notifications"} key="notifications">
                        <NotificationForm
                          {...props}
                          editingUser={{ applicationSettings: editingAppSettings.filter(appSetting => appSetting.type === "cxfirst") }}
                          applications={applications}
                          availableStreams={availableStreams}
                          onChange={(values) => setEditingAppSettings(values.applicationSettings)}
                          hideTitle
                          locale={editingAppSettings[0].locale}
                        />
                      </Panel>
                    )}
                  </>
                )}
                {!(tenant.authProviders && tenant.authProviders.length > 0) && (
                  <Panel header={intl.formatMessage({ id: "profile.password" })}>
                    <div className={styles.passwordPanelWrapper}>
                      <AntForm
                        object={{}}
                        onChange={(values) => setEditingPassword({ ...editingPassword, ...values })}
                        layout="vertical"
                        errors={errors?.password}
                        schema={[
                          {
                            name: "currentPassword",
                            label: intl.formatMessage({ id: "activerecord.attributes.user.current_password" }),
                            input: {
                              type: "password",
                            }
                          },
                          {
                            name: "password",
                            label: intl.formatMessage({ id: "activerecord.attributes.user.password" }),
                            input: {
                              type: "password",
                            },
                          },
                          {
                            name: "passwordConfirmation",
                            label: intl.formatMessage({ id: "activerecord.attributes.user.password_confirmation" }),
                            input: {
                              type: "password",
                            }
                          },
                        ]}
                        />
                      <PasswordRules />
                    </div>
                  </Panel>
                )}
              </Collapse>
            </Col>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.actionWrapper}>
            <Button
              onClick={() => window.close()}
            >
              { intl.formatMessage({ id: "words.cancel" })}
            </Button>
            <div>
              <Button
                onClick={onSubmit}
                type="primary"
              >
                { intl.formatMessage({ id: "words.save" })}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TranslationProvider(Profile);