import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, Drawer, Modal, Space, Tooltip } from "antd";
import without from 'lodash/without';
import { useIntl } from "react-intl";
import last from "lodash/last";
import { ApplicationType, User, HierarchyTree, Group, ApplicationSettings } from "../../../../types";
import styles from "./index.module.sass";
import AccountForm from './AccountForm';
import PortalList from './PortalList';
import LocalesForm from './LocalesForm';
import Loader from "../../../../Loader";
import HierarchyFiltersForm from "./HierarchyFiltersForm";
import RolesForm from "./RolesForm";
import StreamListForm from "./StreamListForm";
import PublicationLevelForms from './PublicationLevelsForm';
import NotificationForm, { canNotifyOnImport } from './EditNotification';
import EditSummary from "./EditSummary";
import memoOnlyForKeys from "../../../../utils/memoOnlyForKeys";
import { hasRole } from "../../../../utils/Authorization";
import { DeleteOutlined } from "@ant-design/icons";

interface IProps {
  visible: boolean;
  user: User;
  currentUser: User;
  wizardSteps: string[];
  tenantLocales: string[];
  applications: ApplicationType[];
  onCancel: () => void;
  editParams: any;
  editLoading: boolean;
  hierarchyTrees: HierarchyTree[];
  onSubmit: (editedUser: User) => void;
  errors: { [key: string]: string[] };
  onEdit: (id: string) => void;
  onDelete: (id?: string) => void;
  saveLoading: boolean;
  group: Group;
  authProviders: { name: string; provider: string }[];
  hasApiKey: boolean;
}

const FormForStep = {
  account: {
    component: AccountForm
  },
  portals: {
    component: PortalList
  },
  language: {
    component: LocalesForm
  },
  hierarchy_filters: {
    component: HierarchyFiltersForm,
  },
  roles: {
    component: RolesForm,
  },
  streams: {
    component: StreamListForm,
  },
  publication_levels: {
    component: PublicationLevelForms,
  },
  notifications: {
    component: NotificationForm
  },
  summary: {
    component: EditSummary,
    errors: true
  }
};

const UserEdit: React.FC<IProps> = props => {
  const {
    visible,
    user,
    wizardSteps,
    applications,
    editParams,
    editLoading,
    hierarchyTrees,
    errors,
    currentUser,
    saveLoading,
    tenantLocales,
    group,
    authProviders,
    hasApiKey,
    onCancel,
    onDelete,
    onEdit,
    onSubmit,
  } = props;
  const intl = useIntl();

  const [nextIsAvailable, setNextIsAvailable] = useState(true);
  const [editingUser, setEditingUser] = useState<User>(user);
  const [keys, setKeys] = useState<string[]>([wizardSteps[0] || "account"]);

  useEffect(() => {
    setEditingUser(user);
    if (user.persisted) setKeys(wizardSteps);
  }, [visible, user, wizardSteps]);

  const goToStep = step => {
    setNextIsAvailable(true);
    const newKeys = [...keys];
    newKeys.splice(step);
    setKeys(newKeys);
  };

  const stepConfig = FormForStep[last(keys) || ["account"]];
  const StepComponent = stepConfig.component;

  const onChange = (values) => {
    setEditingUser({ ...editingUser, ...values });
  };

  const onPreviousStep = () => {
    const newKeys = [...keys];
    setNextIsAvailable(true);
    newKeys.pop();
    setKeys(newKeys);
  };

  const handleSubmit = (user: User) => {
    if (!user) {
      return onSubmit(user);
    }

    const newAppSettings: ApplicationSettings[] = [];

    let uniqueLocale: (string | null) = null;
    if (editingUser.uniqLocale && editingUser.applicationSettings && editingUser.applicationSettings.length > 0) {
      uniqueLocale = editingUser.applicationSettings[0].locale || null;
    }

    user?.applicationSettings?.forEach((appSetting) => {
      const newAppSetting = { ...appSetting };
      if (!canNotifyOnImport(appSetting)) {
        newAppSetting.notifyOnImportErrorActive = false;
      }

      if (!newAppSetting.locale) {
        /**
         * Add locale (specially in case of unique locale mode) when
         * user add new application
        */
        const availableLocalesForApp = editParams.availableLocales[newAppSetting.id || ""] || [];
        const availableLocale = (availableLocalesForApp.length > 0) ? availableLocalesForApp[0] : null;
        newAppSetting.locale = uniqueLocale || availableLocale;
      }

      newAppSettings.push(newAppSetting);
    });

    const newUser = {
      ...user,
      applicationSettings: newAppSettings,
    };

    return onSubmit(newUser);
  };

  const selectedApp = applications.filter(app => (editingUser?.applicationSettings || []).findIndex(appSetting => appSetting.applicationId === app.id) >= 0);
  const availableTrees = hierarchyTrees.filter(hierarchyTree => selectedApp.findIndex(app => app.hierarchyTreeIds.includes(hierarchyTree.id || '')) >= 0);

  const getCurrentSteps = (steps, availableStreams) => {
    let filteredSteps = [...steps];
    if (!editingUser || !availableStreams) {
      return filteredSteps;
    }
    if (!hasRole(group, ["user_network_manager"]) && currentUser.applicationSettings && currentUser.applicationSettings?.length === 1) {
      filteredSteps = without(filteredSteps, 'portals');
      if (editingUser?.applicationSettings) {
        const id = currentUser.applicationSettings[0].applicationId;
        const index = editingUser.applicationSettings.findIndex(appSetting => appSetting.applicationId === id);
        if (index === -1) {
          const newAppSetting: ApplicationSettings = {
            applicationId: currentUser.applicationSettings[0].applicationId,
            roles: ['normal'],
            type: currentUser.applicationSettings[0].type,
            editable: true
          };

          if (!wizardSteps.includes("locale")) {
            newAppSetting.locale = editingUser.locale;
          }
          onChange({ applicationSettings: [newAppSetting] });
        }
      }
    }
    if (editingUser.applicationSettings?.filter(appSetting => (!appSetting._destroy && appSetting.editable)).length === 0 && !editingUser.networkManagerAccess) {
      filteredSteps = without(filteredSteps, 'language');
    }
    if (editingUser.applicationSettings?.filter(appSetting => (!appSetting._destroy && appSetting.editable)).length === 0) {
      filteredSteps = without(filteredSteps, 'hierarchy_filters', 'streams', 'publication_levels', 'notifications');
    }
    if (editingUser.applicationSettings?.filter(appSetting => (!appSetting._destroy && appSetting.editable)).length === 0 && (currentUser.roles && currentUser?.roles.length > 0 && !editingUser.networkManagerAccess)) {
      if (currentUser.roles && currentUser.roles.length > 0) {
        if (!editingUser.networkManagerAccess) filteredSteps = without(filteredSteps, 'roles');
      } else {
        filteredSteps = without(filteredSteps, 'roles');
      }
    }
    const cxfirstAppSetting = editingUser.applicationSettings?.filter(appSetting => appSetting.type === "cxfirst" && (!appSetting._destroy && appSetting.editable));

    if (cxfirstAppSetting?.length === 0) {
      filteredSteps = without(filteredSteps, 'streams', 'publication_levels', 'notifications');
    }
    if (cxfirstAppSetting && cxfirstAppSetting.filter(appSetting => availableStreams[appSetting.applicationId || ""].length === 0).length > 0) {
      filteredSteps = without(filteredSteps, 'streams', 'publication_levels', 'notifications');
    }
    if (cxfirstAppSetting?.filter(appSetting => availableStreams[appSetting.applicationId || ""].filter(stream => appSetting.streamIds?.includes(stream.id)).length > 0).length === 0) {
      filteredSteps = without(filteredSteps, 'notifications', 'publication_levels');
    }
    if (cxfirstAppSetting?.filter(appSetting => availableStreams[appSetting.applicationId || ""].filter(stream => (stream.notificationsActive && appSetting.streamIds?.includes(stream.id)) || (appSetting.roles?.includes('admin') || appSetting.roles?.includes('data_manager'))).length > 0).length === 0) {
      filteredSteps = without(filteredSteps, 'notifications');
    }
    if (cxfirstAppSetting?.filter(appSetting => availableStreams[appSetting.applicationId || ""].filter(stream => stream.hasPublicationState && appSetting.streamIds?.includes(stream.id)).length > 0).length === 0) {
      filteredSteps = without(filteredSteps, 'publication_levels');
    }
    return filteredSteps;
  };

  const drawerTitle = editingUser.lastname ? `${editingUser.lastname} ${editingUser.firstname}` : "Nouvel utilisateur"

  const handleDestroy = (id) => Modal.confirm({
    okText: 'Supprimer',
    okButtonProps: {
      danger: true
    },
    cancelText: 'Annuler',
    title: "Voulez-vous vraiment supprimer cet utilisateur ?",
    content:  "Cette action est irréversible, l'utilisateur pourra être réinviter, mais certaines des données qui lui sont rattaché pourrait être perdues.",
    onOk: () => onDelete(id),
  })

  return (
    <Drawer
      title={editLoading ? undefined : drawerTitle}
      visible={visible}
      width="60%"
      key="user-edit-modal"
      maskClosable={false}
      onClose={onCancel}
      footer={!editLoading ? (
        <div className={styles.actionWrapper}>
          <Space>
            {keys.length > 1 && !editingUser.persisted && (
              <Button
                onClick={onPreviousStep}
              >
                { intl.formatMessage({ id: 'words.previous' })}
              </Button>
            )}
            { hasRole(group, ["user_delete"]) && editingUser.persisted && (
              <Tooltip
                {...(editingUser.canDisable ? { visible: false } : {})}
                title={intl.formatMessage({ id: 'sentences.cannot_destroy_user_tooltip'})}
              >
                <Button
                  danger
                  disabled={!editingUser.canDestroy}
                  icon={<DeleteOutlined />}
                  onClick={() => handleDestroy(editingUser.id)}
                >
                  { intl.formatMessage({ id: "words.delete" })}
                </Button>
              </Tooltip>
            )}
            { editingUser.persisted && (
              <Tooltip
                {...(editingUser.canDisable ? { visible: false } : {})}
                title={intl.formatMessage({ id: 'sentences.cannot_disable_user_tooltip'})}
              >
                <Button
                  disabled={!editingUser.canDisable}
                  onClick={() => handleSubmit({ ...editingUser, active: !editingUser.active })}
                >
                  { intl.formatMessage({ id: editingUser.active ? "user.wizard.deactivate" : "user.wizard.activate" })}
                </Button>
              </Tooltip>
            )}
          </Space>
          <div className={styles.submitWrapper}>
            <Space>
              <Button
                onClick={onCancel}
                type="text"
              >
                { intl.formatMessage({ id: 'words.cancel' })}
              </Button>
              {last(keys) !== last(getCurrentSteps(wizardSteps, editParams.streamsForApp)) && (
                <Button
                  onClick={() => setKeys([...keys, getCurrentSteps(wizardSteps, editParams.streamsForApp)[keys.length]])}
                  type="primary"
                  disabled={!nextIsAvailable}
                >
                  { intl.formatMessage({ id: 'words.next' })}
                </Button>
              )}
              {last(keys) === last(getCurrentSteps(wizardSteps, editParams.streamsForApp)) && (
                <Button
                  type="primary"
                  loading={saveLoading}
                  onClick={() => handleSubmit(editingUser)}
                >
                  { intl.formatMessage({ id: editingUser.persisted ? 'words.save' : "user.wizard.send_invitation" })}
                </Button>
              )}
            </Space>
          </div>
        </div>
      ) : null}
    >
      { editLoading && (
        <div className={styles.loaderWrapper}>
          <Loader key="user-edit-loader" />
        </div>
      )}
      { !editLoading && (
        <div className={styles.userWizardWrapper}>
          { !editingUser.persisted && (
            <Breadcrumb
              className={styles.breadcrumbWrapper}
              separator=">"
              key="wizard-breadcrumb"
            >
              {getCurrentSteps(last(keys) === "summary" ? wizardSteps : keys, editParams.streamsForApp).map((key, i) => (
                <Breadcrumb.Item>
                  <a onClick={i === keys.length - 1 ? () => null : () => goToStep(i + 1)}>{intl.formatMessage({ id: `user.wizard.step.${key}` })} </a>
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          )}
          <StepComponent
            key={`component-${FormForStep[last(keys)]}`}
            editingUser={editingUser}
            onChange={onChange}
            currentUser={currentUser}
            applications={applications}
            availableLocales={editParams.availableLocales}
            sameLocale={editParams.sameLocale}
            availableTrees={availableTrees}
            availableStreams={editParams.streamsForApp}
            availableRolesForApp={editParams.availableRolesForApp}
            nmGroupOptions={editParams.nmGroupOptions}
            concurrentLocales={editParams.concurrentLocales}
            concurrentTrees={editParams.concurrentTrees}
            hasIdenticalHierarchyFilters={editParams.hasIdenticalFilters}
            wizardSteps={getCurrentSteps(wizardSteps, editParams.streamsForApp)}
            locale={currentUser?.locale ? currentUser?.locale : "fr"}
            setNextIsAvailable={setNextIsAvailable}
            onEdit={onEdit}
            tenantLocales={tenantLocales}
            group={group}
            errors={stepConfig.errors ? errors : null}
            authProviders={authProviders}
            hasApiKey={hasApiKey}
          />
        </div>
      )}
    </Drawer>
  );
};

export default React.memo(UserEdit, memoOnlyForKeys(["visible", "user", "editLoading", "errors", "saveLoading"]));