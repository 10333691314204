import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import move from "lodash-move";
import styles from "./index.module.sass";

let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    const className = [restProps.className];
    if (isOver) {
      if (restProps.index > dragingIndex) className.push(styles.dropOverDownward);
      if (restProps.index < dragingIndex) className.push(styles.dropOverUpward);
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className.join(" ")} style={style} />,
      ),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveRow(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

const useDragSorting = ({ initialData, afterSort }) => {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const onMove = (dragIndex, hoverIndex) => {
    const nextData = move(data, dragIndex, hoverIndex);
    if (afterSort) afterSort(nextData, data[dragIndex], hoverIndex + 1);
    else setData(nextData);
  };

  return {
    onMove,
    data,
  };
};

const DraggableTable = props => {
  const {
    onRow, dataSource, onMoveEnd, ...restProps
  } = props;

  const { data, onMove } = useDragSorting({
    initialData: dataSource,
    afterSort: onMoveEnd,
  });

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  return (
    <Table
      components={components}
      dataSource={data}
      {...restProps}
      onRow={(record, index) => ({
        index,
        moveRow: onMove,
        ...onRow(record, index),
      })}
    />
  );
};

DraggableTable.defaultProps = {
  onMoveEnd: null,
  onRow: () => {},
};

DraggableTable.propTypes = {
  onMoveEnd: PropTypes.func,
  onRow: PropTypes.func,
};

const DraggableProvider = (WrappedComponent) => (props) => {
  const locale = "en";
  return (
    <DndProvider backend={HTML5Backend} >
      <WrappedComponent {...props} />
    </DndProvider>
  )
};


export default DraggableProvider(DraggableTable);
