import React, {
  createContext,
  ReactNode,
} from "react";

import useThirdParty from '../../../packs/useThirdParty';

interface IProps {
  children: ReactNode;
  currentUser: any;
  currentTenant: any;
  thirdParty: any;
}

export const ThirdPartyContext = createContext<any | undefined>(undefined);

export const ThirdPartyProvider: React.FC<IProps> = props => {
  const {
    children,
    currentUser,
    currentTenant,
    thirdParty,
  } = props;

  // Call init as early on the page as possible. Some recording data may be lost if it is called too late
  const thirdPartyObj = useThirdParty({ thirdParty, currentUser, currentTenant });

  return (
    <ThirdPartyContext.Provider
      value={thirdPartyObj}
    >
      {children}
    </ThirdPartyContext.Provider>
  );
};