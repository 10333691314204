import React from "react";
import { connect } from "react-redux";
import uniqBy from "lodash/uniqBy";
import flatten from "lodash/flatten";
import EditSite from "./SiteShow";

const SiteEditContainer = props => {
  const {
    visible,
    errors,
    onSubmit,
    onEditCancel,
    group,
    hierarchyTrees,
  } = props;

  if (!visible) return null;

  const branches = uniqBy(
    flatten(
      hierarchyTrees?.map(ht => ht?.hierarchyBranches?.map(({ slug, title }) => ({ slug, title })))
    ),
    'slug',
  );

  return (
    <EditSite
      site={{ active: true }}
      open={visible}
      onClose={onEditCancel}
      branches={branches}
      errors={errors}
      onSubmit={(params) => onSubmit(params)}
      group={group}
    />
  );
};

const mapStateToProps = state => ({
  visible: state.sites.open,
  errors: state.sites.errors,
  hierarchyTrees: state.hierarchyTrees.list,
  group: state.group.current,
});

const mapDispatchToProps = dispatch => ({
  onEditCancel: () => {
    dispatch({ type: 'site/setOpen', payload: false });
    dispatch({ type: 'site/setCurrent', payload: {} });
    dispatch({ type: 'site/setErrors', payload: {} });
  },
  onSubmit: (params) => {
    dispatch({ type: 'site/saveSite', payload: params });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteEditContainer);