

import React, { useState } from "react";
import {
  Progress,
  Row,
  Col,
  Popover,
  notification,
  Tooltip,
  Badge,
} from "antd";
import {
  FolderOutlined,
  CheckCircleFilled,
  DownloadOutlined,
  SyncOutlined,
  ClockCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { find } from "lodash";
import moment from "moment";
import style from "./index.module.sass";
import { ExportFile } from "../../types";
import useInterval from "../../utils/useInterval";

const FileState = (props) => {
  const { file, handleDownload } = props;
  let state = file.state;
  const intl = useIntl();
  if (state === "processing") {
    const at = parseInt(file.at || 0, 10);
    if (at === 0) {
      state = "awaiting";
    }
  }

  switch (state) {
    case "processed":
      return (
        <>
          <Col className={style.iconWrapper} span={2}>
            <CheckCircleFilled style={{ color: "#21BA45", fontSize: "2em" }} />
          </Col>
          <Col span={14} className={style.labelWrapper}>
            <div>
              <Tooltip title={intl.formatMessage({ id: "words.download" })}>
                <span
                  onClick={() => handleDownload(file)}
                  className={style.downloadWrapper}
                >
                  {file.title}
                  <DownloadOutlined style={{ paddingLeft: "5px" }} />
                </span>
              </Tooltip>
            </div>
            <div style={{ color: "#b3b3b3", fontSize: "smaller" }}>
              {`${intl.formatMessage({ id: "export_files.exported_on" })} ${moment(
                file.createdAt
              ).format("lll")}`}
              {file.downloadedAt
                ? ` ${intl.formatMessage({ id: "running_export.downloaded_on" })} ${moment(
                  file.downloadedAt
                ).format("lll")}`
                : ""}
            </div>
          </Col>
        </>
      );
    case "processing":
      return (
        <>
          <Col className={style.iconWrapper} span={2}>
            <SyncOutlined style={{ color: "#2185D0", fontSize: "2em" }} />
          </Col>
          <Col span={14} className={style.labelWrapper}>
            <div>{file.title}</div>
            <div>
              <Progress percent={parseInt(file.at || 0, 10)} />
            </div>
          </Col>
        </>
      );
    case "error":
      return (
        <>
          <Col className={style.iconWrapper} span={2}>
            <CloseCircleOutlined
              style={{ color: "#DB2828", fontSize: "2em" }}
            />
          </Col>
          <Col span={14} className={style.labelWrapper}>
            <div>{file.title}</div>
            <div>{intl.formatMessage({ id: "export_files.error" })}</div>
          </Col>
        </>
      );
    case "awaiting":
      return (
        <>
          <Col className={style.iconWrapper} span={2}>
            <ClockCircleFilled style={{ color: "#2185D0", fontSize: "2em" }} />
          </Col>
          <Col span={14} className={style.labelWrapper}>
            <div>{file.title}</div>
            <div>{intl.formatMessage({ id: "export_files.awaiting" })}</div>
          </Col>
        </>
      );
    default:
      return <p>{file.title}</p>;
  }
};

const PopOverContent = (exportFiles, handleClick, onHandleDownload, intl) => {
  //@ts-ignore
  const size = exportFiles.length
  return (
    <div className={style.popoverContent}>
      { exportFiles && size === 0 && (
        <p>{intl.formatMessage({ id: "running_export.no_recent_file" })}</p>
      )}
      {exportFiles?.map((file) => (
        <Row
          key={`exportfile-${file.id}`}
          className={`ant-row-flex ${style.fileStateWrapper}`}
          style={{ width: 400 }}
        >
          <FileState file={file} handleDownload={onHandleDownload} />
        </Row>
      ))}
    </div>
  );
};

const downloadableFile = (exportFiles) => exportFiles.some((file) => file.state === "processed" && !file.downloadedAt);

const RunningExports = (props) => {
  const {
    onPopoverOpen,
    onHandleDownload,
    fetchData,
    open,
    exportFiles,
  } = props;
  const intl = useIntl();

  const handleVisibleChange = (show) => {
    onPopoverOpen(show);
  };
  const onAllExportOpen = () => {
    onPopoverOpen(false);
  };

  const [processingFinished, setProcessingFinished] = useState<boolean>();
  const [currentProcessing, setCurrentProcessing] = useState<ExportFile>();
  useInterval(
    () => {
      fetchData();
      const fileProcessing = find(
        exportFiles,
        (file) => file.state === "processing"
      );
      if (fileProcessing) setCurrentProcessing(fileProcessing);

      if (
        currentProcessing &&
        (!fileProcessing ||
          (fileProcessing && currentProcessing.id !== fileProcessing.id))
      ) setProcessingFinished(true);
    },
    open || currentProcessing ? 5000 : 30000
  );

  if (processingFinished) {
    setProcessingFinished(false);
    setCurrentProcessing(undefined);
    if (!open) {
      notification["success"]({
        message: "Le fichier a été téléchargé" || null,
        description: "Téléchargement terminée" || null,
        placement: "topRight",
        top: 70,
        duration: 3,
      });
    }
  }

  return (
    <div className={style.popoverWrapper}>
      <Popover
        style={{ width: 500 }}
        title={intl.formatMessage({ id: "export" })}
        content={PopOverContent(
          exportFiles.length > 0 ? exportFiles : [],
          onAllExportOpen,
          onHandleDownload,
          intl
        )}
        trigger="click"
        visible={open}
        onVisibleChange={handleVisibleChange}
        placement="bottom"
      >
        <div className={style.wrapper}>
          <Badge
            className={style.badge}
            dot={downloadableFile(exportFiles)}
            offset={[0, 5]}
          >
            <FolderOutlined
              className={`${style.icon} ${
                downloadableFile(exportFiles) ? style.active : ""
              }`}
            />
          </Badge>
        </div>
      </Popover>
    </div>
  );
};

RunningExports.defaultProps = {
  exportFiles: [
    {
      state: "awaiting",
      title: "Fichier 1",
    },
    {
      state: "error",
      title: "Fichier 2",
    },
    {
      state: "processing",
      title: "Fichier 3",
    },
    {
      state: "processed",
      title: "Fichier 4",
    },
  ],
};

const mapStateToProps = state => ({
  loading: state.exportFiles.loading,
  open: state.exportFiles.open,
  exportFiles: state.exportFiles.list,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => dispatch({ type: "exportFiles/fetch" }),
  onPopoverOpen: (isOpen) => {
    dispatch({ type: "exportFiles/setOpen", payload: isOpen });
  },
  onHandleDownload: (file) => dispatch({ type: "exportFiles/setDownloadedFile", payload: file }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RunningExports);
