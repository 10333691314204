import produce from 'immer';

export interface ImmerReducerType<StateType> {
  [k: string]: (state: StateType, action?: any) => StateType
}

export function createImmerReducer<StateType>(initialState, handlers: ImmerReducerType<StateType>) {
  return function reducer(state: StateType = initialState, action: any) {
    const pro = produce(state, (draft: StateType) => {
      if (action.type === 'global/reset') draft = initialState;
      if (handlers[action.type]) draft = handlers[action.type](draft, action);
      return draft;
    });
    return pro;
  };
}

export default createImmerReducer