//@ts-nocheck
import { AntSchema } from "@9troisquarts/ant-form.ant-form";
import { camelize } from "humps";
import client from "../../../utils/client";
import { hasRole } from "../../../utils/Authorization";

const fetchOptions = (branchCode) => (search) => {
  return client
    .get(`/hierarchy_trees/codes`, {
      params: {
        search,
        branch: branchCode
      },
    })
    .then((data) => {
      return data;
    });
};

const schema = (branches, intl, group, site): AntSchema => {
  const formSchema: AntSchema = [];


  if (hasRole(group, ["site_code_update"]) || (!site.persisted && hasRole(group, ["site_create"]))) {
    formSchema.push({
      label: intl.formatMessage({ id: "activerecord.attributes.site.code" }),
      name: "code",
      required: true,
      input: {
        type: "string",
      },
    });
  }

  if (hasRole(group, ["site_title_update"]) || (!site.persisted && hasRole(group, ["site_create"]))) {
    formSchema.push({
      label: intl.formatMessage({ id: "activerecord.attributes.site.title" }),
      name: "title",
      required: true,
      input: {
        type: "string",
      }
    });
  }

  if (hasRole(group, ["site_deactivate"]) || (!site.persisted && hasRole(group, ["site_create"]))) {
    formSchema.push({
      label: intl.formatMessage({ id: "activerecord.attributes.site.active" }),
      name: "active",
      input: {
        type: "switch",
      }
    });
  }

  if (hasRole(group, ["site_branch_update"]) || (!site.persisted && hasRole(group, ["site_create"]))) {
    branches.forEach(k => {
      formSchema.push({
        label: k.title,
        name: camelize(k.slug),
        input: {
          type: 'reactSelect',
          loadOptions: fetchOptions(k.slug),
          inputProps: {
            isMulti: true,
          },
          options: {
            optionLabel: 'label',
            optionValue: 'value',
          }
        }
      });
    });
  }

  return formSchema;
};

export default schema;