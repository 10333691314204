import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { Alert, Layout as AntdLayout } from 'antd';
import { getTenantColorClassName } from '../utils/tenantTheme';
import { Group, Tenant } from '../types';
import styles from './index.module.sass';
import Navigation from '../Navigation';
import TranslationProvider from '../utils/TranslationProvider';
import Header from '../Header';
import AccessDenied from '../AccessDenied';

interface IProps {
  children: React.ReactNode;
  fetchData: () => void;
  fetchApplication: () => void;
  tenant: Tenant;
  user: any;
  group: Group;
  pageAccessDenied: boolean;
}

const Layout: React.FC<IProps> = props => {
  const {
    children,
    tenant,
    user,
    fetchData,
    fetchApplication,
    group,
    pageAccessDenied
  } = props;
  const intl = useIntl();
  const [collapsed, setCollapsed] = useState(localStorage.getItem("collapsed") === "true");

  // @ts-ignore
  window.I18n = {
    t: (key) => intl.formatMessage({ id: key }),
  };

  useEffect(() => {
    fetchData();
    fetchApplication();
  }, []);

  if (!tenant) return null;

  document.body.classList.add(getTenantColorClassName(tenant.navigationColor, tenant.primaryColor, tenant.secondaryColor));

  const handleCollapsed = (collapsed) => {
    if (collapsed) localStorage.setItem('collapsed', 'true');
    else localStorage.setItem('collapsed', "false");
    setCollapsed(collapsed);
  };
  
  const paddingLeft = collapsed ? 80 : 250

  return (
    <AntdLayout
      hasSider
      className={`${styles.antLayoutWrapper}`}
      id="layout-network-manager"
    >
      <div className={styles.layoutWrapper}>
        <AntdLayout.Sider
          className={styles.sider}
          collapsed={collapsed}
        >
          <Navigation
            collapsed={collapsed}
            tenant={tenant}
            group={group}
            internalUser={user.internalUser}
            setCollapsed={handleCollapsed}
          />
        </AntdLayout.Sider>
        <div
          className={styles.contentWrapper}
        >
          <div
            className={styles.headerWrapper}
            style={{ paddingLeft }}
          >
            <Header
              tenant={tenant}
              user={user}
            />
          </div>
          <div style={{ padding: collapsed ? "80px 15px 15px 15px" : "80px 15px 15px 65px", paddingLeft: paddingLeft + 15 }}>
            { !tenant.logo && (
              <Alert
                style={{ marginTop: '15px' }}
                type="error"
                message={(
                  <div style={{ textAlign: 'center' }}>{intl.formatMessage({ id: "warning.missing_logo" })}</div>
                )}
              />
            )}
            {pageAccessDenied && (
              <>
                <AccessDenied
                  tenant={tenant}
                  displayMessage={false}
                />
                <div style={{ width: "100%", textAlign: "center", fontSize: "24px", marginTop: "-30px", color: "#737878", fontWeight: 300 }}>
                  {intl.formatMessage({id: "errors.access_denied.page" })}
                </div>
              </>
            )}
            {!pageAccessDenied && (
              <>
                { children }
              </>
            )}
          </div>
        </div>
      </div>
    </AntdLayout>
  );
};

const mapStateToProps = state => ({
  tenant: state.tenants.current,
  group: state.group.current,
});

const mapDispatchToProps = dispatch => ({
  fetchData: () => dispatch({ type: 'tenant/fetch' }),
  fetchApplication: () => dispatch({ type: 'application/fetch' }),
});

export default TranslationProvider(connect(mapStateToProps, mapDispatchToProps)(Layout));