// @ts-nocheck
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  PageHeader,
  Divider,
  Card,
} from "antd";
import { omit, capitalize } from "lodash";
import { useIntl } from "react-intl";
import AntForm, { AntSchema } from "@9troisquarts/ant-form.ant-form";
import { AntFormColorPicker } from "@9troisquarts/ant-form.ant-form-color-picker";
import styles from './index.module.sass';

AntForm.addField('color', {
  component: AntFormColorPicker
});

const availableLocales = [
  "fr",
  "de",
  "en",
  "bg",
  "hr",
  "es",
  "hu",
  "it",
  "lt",
  "lv",
  "nl",
  "pl",
  "pt",
  "ro",
  "cs",
  'nb',
];

const schema = (tenant, availableLocalesOptions, intl): AntSchema => [
  {
    name: "title",
    label: intl.formatMessage({ id: "activerecord.attributes.tenant.title" }),
    input: {
      type: "string",
    },
  },
  {
    name: "logo",
    label: intl.formatMessage({ id: "activerecord.attributes.tenant.logo" }),
    input: {
      type: "upload",
      inputProps: {
        maxCount: 1,
        showUploadList: {
          showRemoveIcon: false,
        },
      }
    }
  },
  <>
    {tenant.logo && typeof tenant.logo === "string" && (
    <img src={tenant.logo} style={{ width: "100px", marginBottom: "24px" }} alt="logo" />
    )}
  </>,
  {
    name: "availableLocales",
    label: intl.formatMessage({ id: "activerecord.attributes.tenant.available_locales" }),
    input: {
      type: "checkboxes",
      layout: "vertical",
      options: availableLocalesOptions,
    },
  },
  <Divider key="divider-theme" orientation="left">
    {intl.formatMessage({ id: "settings.theme" })}
  </Divider>,
  [
    {
      name: "navigationColor",
      label: intl.formatMessage({ id: "activerecord.attributes.tenant.navigation_color" }),
      input: {
        type: "color",
        inputProps: {
          type: "chrome",
        },
      },
      colProps: {
        xs: 16,
        md: 5,
      },
    },
    {
      name: "primaryColor",
      label: intl.formatMessage({ id: "activerecord.attributes.tenant.primary_color" }),
      input: {
        type: "color",
        inputProps: {
          type: "chrome",
        },
      },
      colProps: {
        xs: 16,
        md: 5,
      },
    },
    {
      name: "secondaryColor",
      label: intl.formatMessage({ id: "activerecord.attributes.tenant.secondary_color" }),
      input: {
        type: "color",
        inputProps: {
          type: "chrome",
        },
      },
      colProps: {
        xs: 16,
        md: 5,
      },
    },
  ]
];

const SettingsEdit = props => {
  const { onSubmit, loading, tenant } = props;
  const intl = useIntl();
  const [object, setObject] = useState(tenant);

  const availableLocalesOptions = availableLocales.map(locale => ({
    value: locale,
    label: capitalize(intl.formatMessage({ id: `locales.${locale}` })),
  }));

  useEffect(() => {
    setObject(tenant);
  }, [tenant]);

  const handleSubmit = values => {
    const newObject = {
      id: tenant.id,
      ...values,
      themeColors: [...values.themeColors, ...values.newColors]
    };
    onSubmit(omit(newObject, ['newColors', typeof newObject.logo === "string" ? 'logo' : '']));
  };

  const onChange = (values) => {
    setObject({ ...object, ...values });
  };

  return (
    <>
      <PageHeader title={intl.formatMessage({ id: "settings.header" })} />
      <Card style={{ marginTop: 16 }} className={styles.settingForm}>
        <Row>
          <Col xs={16} md={8}>
            <AntForm
              object={object}
              onChange={onChange}
              onSubmit={handleSubmit}
              schema={schema(object, availableLocalesOptions, intl)}
              loading={loading}
              layout="vertical"
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  tenant: state.tenants.current,
  loading: state.tenants.loading,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: values => dispatch({ type: "tenant/save", payload: values }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsEdit);
