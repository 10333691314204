import React, { useEffect, useState } from "react";
import without from 'lodash/without';
import {
  PageHeader,
  Card,
  Table,
  Checkbox,
  TableColumnsType,
  Button
} from 'antd';
import { connect } from "react-redux";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useQuery } from 'react-query';
import client from "../../utils/client";
import styles from "./index.module.sass";
import GroupShow from "./components/GroupShow";
import { ApplicationType, Group } from "../../types";
import { hasRole } from "../../utils/Authorization";

const defaultGroup = ["super-administrateur", "administrateur","lecture", "maintenance"]

const getPermissions = async({ queryKey: [key]}) => {
  const response = await client.get('/permissions.json');
  return response;
};

const groupsColumn = (groups, onChange, intl, onEdit, applications, currentGroup) => {
  const columns: TableColumnsType = [{
    dataIndex: "title",
    title: intl.formatMessage({ id: "activerecord.attributes.permission.title" }),
    render: (title, record: any) => {
      if (record.key) {
        return (
          <div>
            {intl.formatMessage({ id: `group.${record.key}` })}
          </div>
        );
      }

      return title;
    }
  }];

  groups.forEach(group => {
    columns.push({
      title: (
        <div>
          {group.name} {defaultGroup.includes(group.slug) ? "" : <EditOutlined onClick={() => onEdit(group.id)}/>}
        </div>
      ),
      align: "center",
      render: (checked, record: any) => {
        if (record.key) return null;
        return (
          <Checkbox
            disabled={defaultGroup.includes(group.slug) || !hasRole(currentGroup, ["group_permission_update"])}
            checked={group.roles.includes(record.slug)}
            onChange={(e) => onChange(group.id, record.slug, e.target.checked)}
          />
        );
      },
    });
  });

  return columns;
};

type PermissionLine = {
  key: string;
}
const addPermissionsLine = permissions => {
  const newPermissions: Array<PermissionLine> = [];
  const groupedByTypes = permissions.reduce((acc, permission) => {
    const type = permission.slug.split('_')[0];
    if (!acc[type]) acc[type] = [];
    acc[type].push(permission);
    return acc;
  }, {});
  Object.keys(groupedByTypes).forEach(type => {
    newPermissions.push({
      key: type,
    });
    newPermissions.push(...groupedByTypes[type]);
  });

  return newPermissions;
};

interface IProps {
  applications: ApplicationType[];
  currentGroup: Group;
}
const Groups: React.FC<IProps> = (props) => {
  const {
    applications,
    currentGroup,
  } = props;

  const intl = useIntl();
  const [groups, setGroups] = useState([]);
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState({});
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    client.get('/groups.json').then((data) => {
      //@ts-ignore
      setGroups(data.groups);
    });
  }, []);

  const onEdit = (groupId) => client.get(`/groups/${groupId}/edit`).then(data => {
    //@ts-ignore
    setGroup(data.group);
    setOpen(true);
  });

  const { isLoading, data } = useQuery(['permissions'], getPermissions, {
  });

  const handleChange = (groupId, role, checked) => {
    const newGroups: any = [...groups];
    const groupIndex = newGroups.findIndex(g => g.id === groupId);
    let newRoles = newGroups[groupIndex].roles;
    if (checked) {
      newRoles.push(role);
    } else {
      newRoles = without(newRoles, role);
    }
    newGroups[groupIndex].roles = newRoles;

    setChanged(true);
    setGroups(newGroups);
  };

  const onSubmit = (group) => {
    if (group.id) {
      client.put(`/groups/${group.id}`, group).then((data)=> {
        const newGroups: any = [...groups];
        const groupIndex = newGroups.findIndex(g => g.id === group.id);
        //@ts-ignore
        newGroups[groupIndex] = data.group;
        setGroups(newGroups);
        setOpen(false);
      });
    } else {
      client.post('/groups', group).then(data => {
        //@ts-ignore
        const newGroups: any = [...groups, data.group];
        setGroups(newGroups);
        setOpen(false);
      });
    }
  };
  

  const onEditCancel = () => {
    setOpen(false);
    setGroup({});
  };

  return (
    <div className={styles.applicationWrapper}>
      <div className={styles.groupWrapper}>
        <PageHeader
          className={styles.pageHeader}
          title={intl.formatMessage({ id: "groups.header" })}
        />
        {hasRole(currentGroup, ["group_permission_update"]) && (
          <div
            className={styles.actionsWrapper}
          >
            <Button icon={<PlusOutlined />} onClick={() => setOpen(true)}>Nouveau groupe</Button>
          </div>
        )}
      </div>
      <Card>
        { groups.length === 0 && isLoading && (
          <div>Loading</div>
        )}
        { groups.length > 0 && !isLoading && (
          <Table
            className={styles.tableWrapper}
            size="small"
            bordered
            expandable={{
              defaultExpandAllRows: true,
            }}
            rowClassName={(record: any) => {
              if(record.key) return "permissionGroupRow";

              return "";
            }}
            //@ts-ignore
            columns={groupsColumn(groups, (groupId, permissionId, checked) => handleChange(groupId, permissionId, checked), intl, onEdit, applications, currentGroup)}
            //@ts-ignore
            dataSource={addPermissionsLine(data?.permissions)}
            pagination={false}
          />
        )}
        {changed && (
          <div className={styles.actionsWrapper} style={{ marginTop: "15px" }}>
            <Button
              style={{ marginRight: "10px"}}
              onClick={() => client.get('/groups.json').then((data) => {
                //@ts-ignore
                setGroups(data.groups);
                setChanged(false);
              })}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              type="primary"
              onClick={() => client.put('/groups/save', { groups }).then(() => setChanged(false))}
            >
              {intl.formatMessage({ id: "save" })}
            </Button>
          </div>
        )}
      </Card>
      { open && (
        <GroupShow
          onSubmit={onSubmit}
          open={open}
          onCancel={onEditCancel}
          group={group}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  applications: state.applications.list,
  currentGroup: state.group.current,
});
export default React.memo(connect(mapStateToProps, null)(Groups));