import createImmerReducer from "../lib/createReducer";

type GroupStateType = {
  current: any;
};

const initialState: GroupStateType = {
  current: {},
};

const groupReducer = createImmerReducer<GroupStateType>(initialState, {
  "group/setCurrent": (state, { payload }) => {
    state.current = payload;
    return state;
  },
});

export default groupReducer;