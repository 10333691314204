import { AntForm } from '@9troisquarts/ant-form.ant-form';
import {
  Modal,
  Button,
  Spin,
  Result,
} from "antd";
import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import { ApplicationType } from '../../../../types';
import schema from './schema';
import styles from './index.module.sass';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {
  application: ApplicationType | any;
  errors: any;
  onClose: () => void;
  onSubmit: (application: ApplicationType) => void;
  open: boolean;
  //@ts-ignore
  availableAddons?: any;
  loading: boolean;
}

const ApplicationEdit: React.FC<IProps> = props => {
  const {
    application,
    errors,
    onClose,
    onSubmit,
    open,
    availableAddons,
    loading,
  } = props;
  const intl = useIntl();

  const [editingApplication, setEditingApplication] = useState(application);

  const onChange = (values) => {
    setEditingApplication({ ...editingApplication, ...values });
  };

  return (
    <Modal
      title={application.name}
      visible={open}
      width={600}
      onCancel={onClose}
      footer={loading ? false : [
        <div className={styles.footerAction}>
          {editingApplication?.persisted && (
            <Button
              danger
              onClick={() => onSubmit({ ...editingApplication, persisted: editingApplication.persisted, active: !editingApplication.active })}
            >
              {intl.formatMessage({ id: editingApplication.active ? "applications.deactivate_application" : "applications.activate_application" })}
            </Button>
          )}
          <div className="ant-form-modal-actions" key="modalActions">
            <Button
              type="text"
              key="cancel"
              onClick={onClose}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              type="primary"
              key="submit"
              disabled={!(editingApplication.name && editingApplication.applicationType && editingApplication.subdomain)}
              onClick={() => {
                onSubmit({ ...editingApplication, persisted: editingApplication.persisted });
              }}
            >
              {intl.formatMessage({ id: "save" })}
            </Button>
          </div>
        </div>
      ]}
    >
      <div>
        {loading && (
          <Result
            className={styles.result}
            icon={<LoadingOutlined style={{ fontSize: 40 }} spin />}
            title={intl.formatMessage({ id: "sentences.creating_portal" })}
            extra={intl.formatMessage({ id: "sentences.please_wait" })}
          />
        )}
        {!loading && (
          <AntForm
            schema={schema(application, editingApplication, availableAddons, intl)}
            key="application-form"
            errors={errors}
            layout="vertical"
            onChange={(values) => onChange(values)}
            object={editingApplication}
          />
        )}
      </div>
    </Modal>
  );
};

export default ApplicationEdit;
