import { HierarchyTree, HierarchyBranch } from '../../../../types'

interface HierarchyUIProps {
  hierarchyTrees: HierarchyTree[];
  siteHierarchyTitleTranslations: any;
};

interface HierarchyUI {
  nextHierarchyTrees: HierarchyTree[];
  changed: boolean;
  currentBranch?: HierarchyBranch | {};
  editBranchVisible: boolean;
  activeKey: string;
  parameters?: { [key: string]: string; }
};

export enum ActionType {
  setNextHierarchyTrees = 'setNextHierarchyTrees',
  setChanged = 'setChanged',
  setCurrentBranch = 'setCurrentBranch',
  setEditBranchVisible = 'setEditBranchVisible',
  setActiveKey = 'setActiveKey',
  setParameters = "setParameters",
}

type ActionDispatcher = {
  type: ActionType;
  payload: any;
};

const reducer = (state: HierarchyUI, action: ActionDispatcher) => {
  switch (action.type) {
    case ActionType.setNextHierarchyTrees:
      return { ...state, nextHierarchyTrees: action.payload };
    case ActionType.setChanged:
      return { ...state, changed: action.payload };
    case ActionType.setCurrentBranch:
      return { ...state, currentBranch: action.payload };
    case ActionType.setEditBranchVisible:
      return { ...state, editBranchVisible: action.payload };
    case ActionType.setActiveKey:
      return { ...state, activeKey: action.payload };
    case ActionType.setParameters:
      return { ...state, parameters: action.payload };
    default:
      throw new Error();
  }
};

export const initialState = (props: HierarchyUIProps) => {
  const {
    hierarchyTrees,
  } =  props;

  return {
    changed: false,
    currentBranch: {},
    editBranchVisible: false,
    nextHierarchyTrees: hierarchyTrees,
    activeKey: "0",
  }
}

export default reducer;