import React from "react";
import {
  Modal
} from "antd";
import { useIntl } from "react-intl";
import Importer from "../../../components/Importer";

interface IProps {
  importerOpen: boolean;
  loading: boolean;
  onImport: (file: any) => void;
  onImporterOpen: (open: boolean) => void;
}

const SiteImporter: React.FC<IProps> = props => {
  const {
    importerOpen,
    loading,
    onImporterOpen,
    onImport,
  } = props;
  const intl = useIntl();

  return (
    <Modal
      key="sites-import-modal"
      visible={importerOpen}
      onCancel={() => onImporterOpen(false)}
      title={intl.formatMessage({ id: "sites.importer" })}
      footer={null}
    >
      <Importer
        showSeparator={false}
        onSubmit={onImport}
        loading={loading}
      >
        <div style={{ marginTop: "15px" }}>
          <strong>Le fichier importé doit être au format XLSX</strong>
        </div>
      </Importer>
    </Modal>
  )
}

export default SiteImporter;