import React from 'react';
import { Tenant } from '../types';
import styles from './index.module.sass';
import logo from '../../../../public/cx_logo.png';
import TranslationProvider from '../utils/TranslationProvider';
import { useIntl } from "react-intl";
interface IProps {
  tenant: Tenant;
}

const Banner: React.FC<IProps> = props => {
  const intl = useIntl();
  return (
    <div className={styles.banner}>
      { intl.formatMessage({ id: "banner.welcome_phrase" }) }
      <img src={logo} style={{ width: "40px", marginLeft: "15px" }} alt="logo" />
    </div>
  );
};

export default TranslationProvider(Banner);