import React from "react";
import { connect } from "react-redux";

const withAuth = Component => {
  const AuthWrapper = props => <Component {...props} />;

  const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    group: state.group.current,
  });

  return connect(mapStateToProps)(AuthWrapper);
};

export default withAuth;
