import React from "react";
import isNil from "lodash/isNil";
import { List } from "antd";
import styles from "./index.module.sass";

interface IProps {
  items: { title: string, description: string, action: any, active: boolean }[]
}

const DangerZone: React.FC<IProps> = (props) => {
  const { items } = props;  
  return (
    <div className={styles.container}>
      <List
        dataSource={items.filter(i => isNil(i.active) || i.active)}
        itemLayout="horizontal"
        pagination={false}
        renderItem={(item: any) => (
          <List.Item key={item.title} actions={[item.action]}>
            <List.Item.Meta title={item.title} description={item.description} />
          </List.Item>
        )}
      />
    </div>
  );
}
  
export default DangerZone;
