import React, { useState, useEffect } from 'react';
import {Button, Modal } from "antd";
import AntForm from "@9troisquarts/ant-form.ant-form";
import { camelize } from "humps";
import { useIntl } from 'react-intl';
import schema from "./schema";
import styles from "./index.module.sass";
import { Group } from '../../../types';


interface Props {
  site: any;
  errors: any;
  open: boolean;
  onSubmit: (values) => void;
  onClose: () => void;
  branches: any[];
  group: Group;
}

const EditSite: React.FC<Props> = props => {
  const {
    site,
    open,
    onSubmit,
    onClose,
    branches,
    errors,
    group,
  } = props;

  const intl = useIntl();
  const [newSite, setNewSite] = useState(site || {});
  useEffect(() => setNewSite(site), [site, open]);

  const handleChange = (values) => {
    const editingSite = { ...values, id: site.id };
    branches.forEach(branch => {
      const branchSlug = camelize(branch.slug);
      if (values[branchSlug]) {
        editingSite[branchSlug] = values[branchSlug].map(option => option.value);
      }
    });
    setNewSite({ ...newSite, ...editingSite });
  };

  const transformInOption = (site) => {
    const editingSite = { ...site };
    branches.forEach(branch => {
      const branchSlug = camelize(branch.slug);
      if (site[branchSlug]) {
        editingSite[branchSlug] = site[branchSlug].map(code => ({ value: code, label: code }));
      }
    });
    return editingSite;
  };

  return (
    <Modal
      title={site.id ? `Edition du site ${site.title}` : "Ajouter un site"}
      open={open}
      width={600}
      onCancel={onClose}
      footer={[
        <div className={styles.footerAction}>
          <div className="ant-form-modal-actions" key="modalActions">
            <Button key="cancel" onClick={onClose}>
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              loading={false}
              type="primary"
              key="submit"
              disabled={!newSite?.code}
              onClick={() => {
                onSubmit({ ...newSite, persisted: site.persisted });
              }}
            >
              {intl.formatMessage({ id: "save" })}
            </Button>
          </div>
        </div>,
      ]}
    >
      <div>
        <AntForm
          schema={schema(branches, intl, group, newSite)}
          key="site-form"
          errors={errors}
          layout="vertical"
          // @ts-ignore
          onChange={(_v, values) => handleChange(values)}
          object={transformInOption(newSite)}
        />
      </div>
    </Modal>
  );
};

export default EditSite;