import client from "../utils/client";

export const synchronizeSite = async (id) => {
  const response = await client.put(`/sites/${id}/synchronize.json`)
  return response;
}

const fetch = async (page, pageSize, search = {}) => {
  const response = await client.get('/sites.json', {
    params: { 
      page,
      pageSize,
      ...search
    }
  })
  return response;
};

const fetchOne = async (id) => {
  const response = await client.get(`/sites/${id}.json`)
  
  return response;
}

const save = async (site) => {
  let response;
  if (site.site.id && site.site.persisted ) {
    response = await client.put(`/sites/${site.site.id}`, site)
  }
  else {
    response = await client.post(`/sites`, site)
  }
  return response
}

const deleteAll = async () => {
  let response;
  response = await client.delete(`/sites/delete.json`);
  
  return response;
}

const destroy = async (id) => {
  let response; 
  response = await client.delete(`/sites/${id}/destroy.json`)

  return response;
}

const globalSync = async () => {
  let response;
  response = await client.get(`/sites/sync`)

  return response;
}

const importFile = async ({ separator, file }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("separator", separator);
  const response = await client.post(`/sites/import.json`, formData);

  return response
};

const Site = {
  fetch,
  fetchOne,
  save,
  importFile,
  deleteAll,
  destroy,
  globalSync,
};

export default Site;