import { all } from "@redux-saga/core/effects";
import { connectRouter } from 'connected-react-router';
import { combineReducers } from "redux";
import hierarchyReducer, { hierarchyTreeSaga } from "./hierarchyTree";
import tenantReducer, { tenantSaga } from "./tenant";
import siteReducer, { siteSaga } from './site'
import userReducer, { userSaga } from "./user";
import applicationReducer, { applicationSaga } from "./applications";
import exportFileReducer, { exportFileSaga } from "./exportFiles";
import localeReducer from "./locales";
import groupReducer from "./group";

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  hierarchyTrees: hierarchyReducer,
  tenants: tenantReducer,
  sites: siteReducer,
  users: userReducer,
  applications: applicationReducer,
  locales: localeReducer,
  exportFiles: exportFileReducer,
  group: groupReducer,
});

export function* rootSaga() {
  yield all([
    hierarchyTreeSaga(),
    tenantSaga(),
    siteSaga(),
    applicationSaga(),
    userSaga(),
    exportFileSaga(),
  ]);
}

export default rootReducer;