import intersection from "lodash/intersection";

import withAuth from "./withAuth";

export const hasRole = (group, allowedRoles) => allowedRoles
  && group
  && group.roles
  && intersection(group.roles, allowedRoles).length > 0;

const PrivateComponent = props => {
  const {
    group, allowedRoles, children, onlyInternal,
  } = props;
  if (hasRole(group, allowedRoles)) return children;

  return null;
};

export default withAuth(PrivateComponent);
