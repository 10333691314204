import React from "react";
import AntForm from "@9troisquarts/ant-form.ant-form";
import capitalize from "lodash/capitalize";
import without from "lodash/without";
import { useIntl } from "react-intl";
import {
  PageHeader,
  Card,
  Checkbox
} from "antd";
import { User, ApplicationType, Group } from "../../../../types";
import { getApplication, logoForApp } from "./utils";
import styles from './index.module.sass';
import { hasRole } from "../../../../utils/Authorization";

interface IProps {
  editingUser: User;
  onChange: (values) => void;
  applications: ApplicationType[];
  availableLocales: {
    [key: string]: string[];
  };
  concurrentLocales: boolean;
  tenantLocales: string[];
  hideTitle: boolean;
  group: Group;
  errors: any;
}

const LocalesForm: React.FC<IProps> = props => {
  const {
    editingUser,
    onChange,
    availableLocales,
    concurrentLocales,
    applications,
    hideTitle,
    tenantLocales,
    group,
    errors,
  } = props;

  const intl = useIntl();

  const handleChange = (values, index = 0) => {
    const newApps = [...(editingUser.applicationSettings || [])];
    if (editingUser.uniqLocale) {
      newApps.forEach((appSetting, i) => {
        const newAppSetting = { ...newApps[i] };
        newAppSetting.locale = values.locale;
        newApps[i] = newAppSetting;
      });
    } else {
      const currentApp = { ...newApps[index] };
      currentApp.locale = values.locale;
      newApps[index] = currentApp;
    }
    const newUser: User = { applicationSettings: newApps };
    if (editingUser.uniqLocale && editingUser && editingUser.roles && editingUser.roles.length > 0) {
      newUser.locale = values.locale;
    }
    if (editingUser.uniqLocale) {
      newUser.commonLocale = values.locale;
    }
    onChange(newUser);
  };

  const selectedApplications = (editingUser.applicationSettings || []).filter(appSetting => (!appSetting._destroy && appSetting.editable));

  if (concurrentLocales && editingUser.uniqLocale && editingUser && editingUser.roles && editingUser.roles.length > 0) {
    selectedApplications.push({
      type: "network-manager",
      applicationId: "network-manager"
    });
  }

  //@ts-ignore
  const hasConcurrentLocales = (selectedApplications) => {
    if (!selectedApplications || selectedApplications.length === 1) return false;

    let concurrentLocale = true;
    let previousLocale = [];
    selectedApplications.forEach((app, index) => {
      if (concurrentLocale) {
        //@ts-ignore
        const appLocale: string[] = availableLocales[app.applicationId || ""] || [];
        if (index > 0) {
          concurrentLocale = (previousLocale.length === appLocale.length);
        }
        if (without(previousLocale, ...appLocale).length !== 0) {
          concurrentLocale = false;
        } else {
          //@ts-ignore
          previousLocale = appLocale;
        }
      }
    });

    if (hasRole(group, ["user_network_manager"])) {
      concurrentLocale = without(previousLocale, ...tenantLocales).length === 0;
    }

    return concurrentLocale;
  };

  return (
    <div>
      {!hideTitle && (
        <PageHeader
          title={intl.formatMessage({ id: "user.wizard.step.language" })}
          style={{ paddingLeft: "0px" }}
        />
      )}
      {hasConcurrentLocales(selectedApplications) && (
        <Checkbox
          style={{ marginLeft: "5px" }}
          checked={editingUser.uniqLocale}
          key="check-use-same-filter"
          onChange={e => {
            onChange({ uniqLocale: !editingUser.uniqLocale });
          }}
        >
          {intl.formatMessage({ id: "user.wizard.share_locale" })}
        </Checkbox>
      )}
      { hasConcurrentLocales(selectedApplications) && editingUser.uniqLocale && (
        <div
          key="roles-form-all"
          className={styles.checkboxesWrapper}
        >
          <span className={styles.appTitle}>
            { selectedApplications.map((appSetting, i) => (
              <span className={styles.appTitle}>
                <img
                  width={30}
                  alt={`logo-for-${logoForApp[appSetting.type || '']['compact']}`}
                  src={logoForApp[appSetting.type || '']['compact']}
                  style={{ marginRight: "5px", marginBottom: "5px", marginLeft: i > 0 ? "10px" : '' }}
                />
                { getApplication(applications, appSetting.applicationId)?.name }
                { i < selectedApplications.length ? ',' : ''}
              </span>
            ))}
          </span>
          <Card>
            <AntForm
              object={{ locale: (editingUser.uniqLocale) ? selectedApplications[0]?.locale : editingUser.commonLocale }}
              onChange={(values) => handleChange(values)}
              layout="vertical"
              schema={[{
                name: "locale",
                input: {
                  type: "select",
                  options: (availableLocales[selectedApplications[0]?.applicationId || ""] || []).map(locale => ({ value: locale, label: capitalize(intl.formatMessage({ id: `locales.${locale}` })) }))
                }
              }]}
            />
          </Card>
        </div>
      )}
      { (!editingUser.uniqLocale || !hasConcurrentLocales(selectedApplications)) && selectedApplications.map((appSetting, i) => {
        const error = (errors || []).find(error => error.applicationId === appSetting.applicationId);
        return (
          <div
            key={`roles-form-${appSetting.type}-${i}`}
            className={styles.checkboxesWrapper}
          >
            <span className={styles.appTitle}>
              <img
                width={30}
                alt={`logo-for-${logoForApp[appSetting.type || '']['compact']}`}
                src={logoForApp[appSetting.type || '']['compact']}
                style={{ marginRight: "5px", marginBottom: "5px" }}
              />
              { getApplication(applications, appSetting.applicationId).name }
            </span>
            <Card>
              <AntForm
                object={appSetting}
                onChange={(values) => handleChange(values, i)}
                layout="vertical"
                errors={error ? { locale: error.errors } : undefined}
                schema={[{
                  name: "locale",
                  input: {
                    type: "select",
                    options: (availableLocales[appSetting.applicationId || ""] || []).map(locale => ({ value: locale, label: capitalize(intl.formatMessage({ id: `locales.${locale}` })) }))
                  }
                }]}
              />
            </Card>
          </div>
        )
      })}
      { (!editingUser.uniqLocale || !hasConcurrentLocales(selectedApplications)) && editingUser.networkManagerAccess && hasRole(group, ["user_network_manager"]) && (
        <div
          key="roles-form-network_manager"
          className={styles.checkboxesWrapper}
        >
          <span className={styles.appTitle}>
            <img
              width={30}
              alt={`logo-for-${logoForApp["network-manager"]['compact']}`}
              src={logoForApp["network-manager"]['compact']}
              style={{ marginRight: "5px", marginBottom: "5px" }}
            />
            Network Manager
          </span>
          <Card>
            <AntForm
              object={editingUser}
              onChange={(values) => onChange(values)}
              layout="vertical"
              schema={[{
                name: "locale",
                input: {
                  type: "select",
                  options: tenantLocales.map(locale => ({ value: locale, label: capitalize(intl.formatMessage({ id: `locales.${locale}` })) }))
                }
              }]}
            />
          </Card>
        </div>
      )}
    </div>
  );
};

export default LocalesForm;