export const getUserDomainType = (email: (string | null | undefined)) => {
  const parts = email?.split("@") || [];
  if (parts.length === 0) {
    return null;
  }

  const domainNames = process.env.INTERAL_USER_DOMAIN_NAMES?.split(';') || [];
  const isIncluded = domainNames.some(domainName => parts[parts.length - 1] === domainName);

  if (isIncluded) {
    return "internal";
  }

  return "external";
};

export default getUserDomainType;