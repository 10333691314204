/* eslint-disable no-return-assign */
import React from 'react';
import {
  PageHeader,
  Checkbox,
  Card,
  Row,
  Col
} from 'antd';
import { useIntl } from 'react-intl';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import { User, ApplicationType, ApplicationSettings } from "../../../../../types";
import EditNotificationTable from './EditNotificationTable';
import EditMailFrequency from './EditMailFrequency';
import styles from '../index.module.sass';
import { getApplication, logoForApp } from '../utils';

interface IProps {
  editingUser: User;
  onChange: (values: any) => void;
  applications: ApplicationType[];
  availableStreams: string[];
  hideTitle: boolean;
  locale: string;
}

export const canNotifyOnImport = (appSetting: ApplicationSettings) => {
  const allowedRoles = ["admin", "data_manager"];
  const appRoles = appSetting?.roles || [];
  const hasIntersection = (arr1, arr2) => arr1.some(role => arr2.includes(role));

  const result = appRoles.length > 0 && hasIntersection(appRoles, allowedRoles);

  return result;
};

const NotificationsForm: React.FC<IProps> = props => {
  const {
    editingUser,
    applications,
    onChange,
    availableStreams,
    hideTitle,
    locale,
  } = props;

  const intl = useIntl();
  const cxfirstAppSetting = (editingUser.applicationSettings || []).filter(appSetting => (!appSetting._destroy && appSetting.editable) && appSetting.type === "cxfirst");

  const handleChange = (values, appId) => {
    const newAppSettings = [...(editingUser.applicationSettings || [])];
    const index = newAppSettings.findIndex((appSetting => appSetting.applicationId === appId));

    const newAppSetting = { ...newAppSettings[index], ...values };
    newAppSettings[index] = newAppSetting;
    onChange({ applicationSettings: newAppSettings });
  };

  let hasNotifForStream = true;
  return (
    <>
      {!hideTitle && (
        <PageHeader
          key="notification-form-header"
          title={intl.formatMessage({ id: "user.wizard.step.notifications" })}
          style={{ paddingLeft: "0px" }}
        />
      )}
      <Row
        key="notification-forms"
        className={styles.applicationSettingsWrapper}
      >
        { cxfirstAppSetting.map((appSetting, i) => (
          <Col
            span={16}
            key={`app-setting-notification-from-${i}`}
            className={styles.notificationsWrapper}
            style={{ marginTop: i > 0 ? "10px" : "unset" }}
          >
            <span className={styles.appTitle}>
              <img
                width={30}
                alt={`logo-for-${logoForApp['cxfirst']['compact']}`}
                src={logoForApp["cxfirst"]['compact']}
                style={{ marginRight: "5px", marginBottom: "5px" }}
              />
              { getApplication(applications, appSetting.applicationId).name }
            </span>
            { hasNotifForStream = filter(filter(availableStreams[appSetting.applicationId || ""], stream => appSetting.streamIds?.includes(stream.id)), stream => stream.notificationsActive).length > 0}
            <Card>
              {hasNotifForStream && (
                <>
                  <EditNotificationTable
                    locale={locale}
                    editNotification={filter(appSetting.streamSubscriptions, notif => availableStreams[appSetting.applicationId || ""][findIndex(availableStreams[appSetting.applicationId || ""], stream => stream.id === notif.parentId)]?.notificationsActive) || []}
                    streams={availableStreams[appSetting.applicationId || ""]}
                    user={appSetting}
                    onChange={(streamSubscriptions => handleChange({ ...appSetting, streamSubscriptions }, appSetting.applicationId))}
                  />
                  <EditMailFrequency
                    mailFrequency={appSetting.mailFrequency || 'none'}
                    onChange={(values) => handleChange({ ...appSetting, mailFrequency: values }, appSetting.applicationId)}
                  />
                </>
              )}
              { canNotifyOnImport(appSetting) && (
                <Checkbox
                  style={{ paddingTop: hasNotifForStream ? "40px" : 0 }}
                  onChange={(e) => handleChange({ ...appSetting, notifyOnImportErrorActive: e.target.checked }, appSetting.applicationId)}
                  checked={appSetting.notifyOnImportErrorActive}
                > {intl.formatMessage({ id: "activerecord.attributes.user.application_setting.notifications.notify_on_import_error_active" })}
                </Checkbox>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default NotificationsForm;