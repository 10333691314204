import React, { useState } from "react";
import AntForm, { AntSchema } from "@9troisquarts/ant-form.ant-form";
import { useIntl } from "react-intl";
import { useDebounceFn } from '@umijs/hooks';
import { PageHeader, Alert } from "antd";
import client from '../../../../utils/client';
import { User } from "../../../../types";
import styles from "./index.module.sass";

interface IProps {
  editingUser: User;
  onChange: (value: {[key: string]: any;}) => void;
  setNextIsAvailable: (nextIsAvailable: boolean) => void;
  errors: { [key: string]: string[] };
  onEdit: (id: string) => void;
  currentUser: User;
  hasApiKey: boolean;
  authProviders: { name: string; provider: string }[];
}

const schema = (editingUser, intl, authProviders, currentUser, hasApiKey): AntSchema => {
  const formSchema: AntSchema = [
    [{
      name: "firstname",
      label: intl.formatMessage({ id: "activerecord.attributes.user.firstname" }),
      required: true,
      input: {
        type: "string"
      },
      colProps: {
        xs: 16,
        md: 8,
      },
    },
    {
      name: "lastname",
      required: true,
      label: intl.formatMessage({ id: "activerecord.attributes.user.lastname" }),
      input: {
        type: "string"
      },
      colProps: {
        xs: 16,
        md: 8,
      },
    }],
  ];

  if (currentUser.networkManagerAdmin) {
    formSchema.push({
      name: "networkManagerAdmin",
      input: {
        text: intl.formatMessage({ id: "activerecord.attributes.user.network_manager_admin" }),
        type: "checkbox",
      }
    });
  }

  if ((authProviders && authProviders.length > 0)) {
    formSchema.push({
      name: "provider",
      required: true,
      label: intl.formatMessage({ id: "activerecord.attributes.user.provider" }),
      input: {
        type: "select",
        options: [{ value: "email", label: "Email" }, ...authProviders.map(provider => ({ value: provider.provider, label: provider.name }))]
      }
    });
  }

  if (authProviders?.length > 0 || hasApiKey) {
    formSchema.push({
      name: "internalId",
      required: editingUser.provider && editingUser.provider === "saml",
      label: intl.formatMessage({ id: "activerecord.attributes.user.internal_id" }),
      input: {
        type: "string",
      }
    });
  }

  formSchema.push({
    name: "email",
    required: true,
    label: intl.formatMessage({ id: "activerecord.attributes.user.email" }),
    input: {
      type: "string"
    }
  });

  formSchema.push([
    {
      name: "avatar",
      label: intl.formatMessage({ id: "activerecord.attributes.user.avatar" }),
      input: {
        type: "upload",
        inputProps: {
          maxCount: 1,
        }
      }
    },
  ]);

  formSchema.push(
    //@ts-ignore
    <>
      {!editingUser.avatar && (
        <img src={editingUser.avatarUrl} style={{ width: "100px" }} />
      )}
    </>
  );

  return formSchema;
};

const AccountForm: React.FC<IProps> = props => {
  const {
    editingUser,
    onChange,
    errors,
    setNextIsAvailable,
    onEdit,
    authProviders,
    hasApiKey,
    currentUser,
  } = props;

  const intl = useIntl();
  const [exisitingUser, setExisitingUser] = useState<User>({});

  const checkEmail = (values) => {
    if (values.email) {
      client.get(`/users/check_email?email=${values.email}`).then((data) => {
        //@ts-ignore
        if (data?.user) {
          //@ts-ignore
          setExisitingUser(data?.user);
          setNextIsAvailable(false);
        } else {
          setNextIsAvailable(true);
          setExisitingUser({});
        }
      });
    }
  };

  const { run } = useDebounceFn((values) => {
    checkEmail(values);
  }, 500);

  const handleChange = (values) => {
    if (values.email) {
      run(values);
    }
    onChange(values);
  };

  return (
    <>
      <PageHeader
        title={intl.formatMessage({ id: "user.wizard.step.account" })}
        style={{ paddingLeft: "0px" }}
      />
      <div className={styles.accountWrapper}>
        <AntForm
          object={editingUser}
          className={styles.accountForm}
          layout="vertical"
          onChange={(values) => handleChange(values)}
          onSubmit={undefined}
          errors={errors}
          schema={schema(editingUser, intl, authProviders, currentUser, hasApiKey)}
        />
      </div>
      {exisitingUser?.id && exisitingUser.id !== editingUser?.id && (
        <Alert
          style={{ textAlign: "center" }}
          message={(
            <span
              className={styles.existingUserWrapper}
              //@ts-ignore
              onClick={() => onEdit(exisitingUser.id)}
            >
              { intl.formatMessage({ id: 'user.wizard.existing_user' })}
            </span>
          )}
        />
      )}
    </>
  );
};

export default AccountForm;