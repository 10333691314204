//@ts-nocheck
import React, { useState } from "react";
import {
  Row,
  Col,
  Select,
  Checkbox,
  Card,
  PageHeader,
  Space
} from "antd";
import without from 'lodash/without';
import { AntForm } from "@9troisquarts/ant-form.ant-form";
import { ApplicationType, HierarchyTree, User } from "../../../../types";
import client from "../../../../utils/client";
import styles from "./index.module.sass";
import { logoForApp } from "./utils";
import useI18n from "../../../../utils/useI18n";

const { Option } = Select;

interface IProps {
  editingUser: User;
  applications: ApplicationType[];
  currentUser: User;
  errors: any;
  onChange: (values: any) => void;
  hasIdenticalHierarchyFilters: boolean;
  availableTrees: HierarchyTree[];
  hideTitle: boolean;
  concurrentTrees: boolean;
}

const fetchOptions = (branchCode, hierarchyTreeId, appId) => (search) => {
  return client
    .get(`/hierarchy_trees/codes`, {
      params: {
        search,
        hierarchyTree: hierarchyTreeId,
        branch: branchCode,
        applicationId: appId,
        usedEntity: true,
      },
    })
    .then((data) => data);
};

const getAppSettingForApp = (user, app) => (user.applicationSettings || []).find(appSetting => appSetting.applicationId === app.id);

const getFilterForTreeAndApp = (user, tree, app, uniq) => {
  const appSetting = getAppSettingForApp(user, app)
  
  if (!appSetting) return {};

  const hierarchyFilters = uniq ? user.uniqHierarchyFilterHash : appSetting.hierarchyFilters;
  return (hierarchyFilters || []).find(hf => hf.hierarchyTreeId === tree.id);
};

const HierarchyFiltersForm: React.FC<IProps> = props => {
  const {
    editingUser,
    errors,
    applications,
    availableTrees,
    hideTitle,
    currentUser,
    onChange,
  } = props;

  const { t } = useI18n();

  const onBranchChange = (value, app, tree) => {
    const newAppsSettings = [...editingUser.applicationSettings || []];
    const appSettingIndex = newAppsSettings.findIndex(appSetting => appSetting.applicationId === app.id);
    const newAppSetting = { ...newAppsSettings[appSettingIndex] };
    const newHierarchyFilters = [...(newAppSetting.hierarchyFilters || []) ]
    const hierarchyFilterIndex = newHierarchyFilters.findIndex(hf => hf.hierarchyTreeId === tree.id);
    const nextValue = { ...(newHierarchyFilters[hierarchyFilterIndex] || { hierarchyTreeId: tree.id }) };

    nextValue['_destroy'] = false;
    if (value === "none") {
      nextValue.hierarchyBranchSlug = null;
    } else if (value === "all") {
      nextValue['_destroy'] = true;
      nextValue.hierarchyBranchSlug = null;
    } else {
      if (nextValue.hierarchyBranchSlug !== value) {
        nextValue.codes = null;
        nextValue.codesOptions = [];
      }
      nextValue.hierarchyBranchSlug = value;
    }

    if (hierarchyFilterIndex >= 0) {
      newHierarchyFilters[hierarchyFilterIndex] = nextValue;
    } else {
      newHierarchyFilters.push(nextValue);
    }

    newAppSetting.hierarchyFilters = newHierarchyFilters;
    newAppsSettings[appSettingIndex] = newAppSetting;
    return onChange({ applicationSettings: newAppsSettings});
  };

  const onCodesChange = (values, app, tree) => {
    const newAppsSettings = [...editingUser.applicationSettings || []];
    const appSettingIndex = newAppsSettings.findIndex(appSetting => appSetting.applicationId === app.id);
    const newAppSetting = { ...newAppsSettings[appSettingIndex] };
    const newHierarchyFilters = [...(newAppSetting.hierarchyFilters || [])];
    const hierarchyFilterIndex = newHierarchyFilters.findIndex(hf => hf.hierarchyTreeId === tree.id);
    const newCodes = values.codesOptions.map(options => options.value);
    const nextValue = { ...newHierarchyFilters[hierarchyFilterIndex], codes: newCodes, codesOptions: values.codesOptions};

    newHierarchyFilters[hierarchyFilterIndex] = nextValue;
    newAppSetting.hierarchyFilters = newHierarchyFilters;
    newAppsSettings[appSettingIndex] = newAppSetting;

    return onChange({ applicationSettings: newAppsSettings});
  };

  const onUniqBranchChange = (value, app, tree) => {
    const newHierarchyFilters = [...(editingUser.uniqHierarchyFilterHash || [])];
    const hierarchyFilterIndex = newHierarchyFilters.findIndex(hf => hf.hierarchyTreeId === tree.id);
    const nextValue = { ...(newHierarchyFilters[hierarchyFilterIndex] || { hierarchyTreeId: tree.id }) };

    nextValue['_destroy'] = false;
    if (value === "none") {
      nextValue.hierarchyBranchSlug = null;
    } else if (value === "all") {
      nextValue['_destroy'] = true;
      nextValue.hierarchyBranchSlug = null;
    } else {
      if (nextValue.hierarchyBranchSlug !== value) {
        nextValue.codes = null;
        nextValue.codesOptions = [];
      }
      nextValue.hierarchyBranchSlug = value;
    }

    if (hierarchyFilterIndex >= 0) {
      newHierarchyFilters[hierarchyFilterIndex] = nextValue;
    } else {
      newHierarchyFilters.push(nextValue);
    }

    onChange({ uniqHierarchyFilterHash: newHierarchyFilters });
  };

  const onUniqCodesChange = (values, app, tree) => {
    const newHierarchyFilters = [...(editingUser.uniqHierarchyFilterHash || [])];
    const hierarchyFilterIndex = newHierarchyFilters.findIndex(hf => hf.hierarchyTreeId === tree.id);
    const newCodes = values.codesOptions.map(options => options.value);
    const nextValue = { ...newHierarchyFilters[hierarchyFilterIndex], codes: newCodes, codesOptions: values.codesOptions };

    newHierarchyFilters[hierarchyFilterIndex] = nextValue;

    return onChange({ uniqHierarchyFilterHash: newHierarchyFilters});
  };

  const selectedApplications = applications.filter(app => editingUser.applicationSettings.filter(appSetting => appSetting.editable && !appSetting._destroy).findIndex(appSetting => appSetting.applicationId === app.id) >= 0)

  const hasConcurrentTrees = (selectedApplications) => {
    /* conccurent tree appears when there are different trees
     * usable by different apps (not related to the user but to
     * the application available hierarchy)
    */
    if (!selectedApplications || selectedApplications.length === 1) return false;

    let concurrentTree = true;
    let previousTreeIds = [];
    selectedApplications.forEach((app, index) => {
      if (concurrentTree) {
        if (index > 0) {
          concurrentTree = (previousTreeIds.length === app.hierarchyTreeIds.length);
        }
        if (without(previousTreeIds, ...app.hierarchyTreeIds).length !== 0) {
          concurrentTree = false;
        } else {
          previousTreeIds = app.hierarchyTreeIds;
        }
      }
    });

    return concurrentTree;
  };

  return (
    <div>
      {!hideTitle && (
        <PageHeader
          title={t("user.wizard.step.hierarchy_filters")}
          key={`hierarchy-filter-form-header`}
          style={{ paddingLeft: "0px" }}
        />
      )}
      {hasConcurrentTrees(selectedApplications) && (
        <Checkbox
          style={{ marginLeft: "5px" }}
          checked={editingUser.uniqHierarchyFilter}
          key={`check-use-same-filter`}
          onChange={e => {
            onChange({ uniqHierarchyFilter: e.target.checked });
          }}
        >
          { t("user.wizard.share_filter") }
        </Checkbox>
      )}
      <Space direction="vertical" style={{ width: '100%' }}>
        {(hasConcurrentTrees(selectedApplications) && editingUser.uniqHierarchyFilter) && (
          <ApplicationFilters
            key={`hierarchy-filter-form-unique`}
            app={selectedApplications[0]}
            errors={errors}
            availableTrees={availableTrees}
            editingUser={editingUser}
            onBranchChange={onUniqBranchChange}
            onCodesChange={onUniqCodesChange}
            title={selectedApplications.map(app => app.name).join(', ') }
            uniq
            currentUser={currentUser}
          />
        )}
        {(!hasConcurrentTrees(selectedApplications) || !editingUser.uniqHierarchyFilter) && selectedApplications.map((app, i) => (
          <ApplicationFilters
            errors={errors}
            key={`hierarchy-filter-form-${i}`}
            app={app}
            availableTrees={availableTrees}
            editingUser={editingUser}
            onBranchChange={onBranchChange}
            onCodesChange={onCodesChange}
            currentUser={currentUser}
          />
        ))}
      </Space>
    </div>
  );
};

type ApplicationFiltersProps = {
  currentUser: User;
  title: string;
  errors: {
    [key: string]: {
      [key: string]: {
        [key: string]: string[];
      };
    }
  };
  onBranchChange: (value: any, app: any, tree: any) => void;
  onCodesChange: (value: any, app: any, tree: any) => void;
}

const ApplicationFilters: React.FC<ApplicationFiltersProps> = props => {
  const {
    app,
    availableTrees,
    editingUser,
    errors,
    title,
    uniq,
    currentUser,
    onCodesChange,
    onBranchChange,
  } = props;
  const { t } = useI18n();
  
  const filteredAvailableTrees = availableTrees.filter(tree => app.hierarchyTreeIds.includes(tree.id));
  let errsByTreeId = Object.keys((errors || {})).reduce((acc, appId) => ({
    ...acc,
    ...errors[appId]
  }), {});
  const hasError = Object.keys(errsByTreeId).some(treeId => app.hierarchyTreeIds.includes(treeId));

  if (filteredAvailableTrees.length === 0) return null;
  return (
    <div className={styles.hierarchyFilterAppWrapper}>
      <span className={`${styles.appTitle} ${hasError ? 'error' : ''}`}>
        <img
          width={30}
          alt={`logo-for-${logoForApp[app.applicationType]['compact']}`}
          src={logoForApp[app.applicationType]['compact']}
          style={{ marginRight: "5px", marginBottom: "5px" }}
        />
        {title || app.name}
      </span>
      <Card>
        {filteredAvailableTrees.map((tree, i) => {
          const editingUserFilter = getFilterForTreeAndApp(editingUser, tree, app, uniq);
          const currentUserFilter = getFilterForTreeAndApp(currentUser, tree, app, uniq);
          return (
            <HierarchyFilterForm
              key={`hierarchy-filter-tree-form-${i}`}
              index={i}
              errors={errsByTreeId[tree.id]}
              hierarchyTree={tree}
              onBranchChange={(value) => onBranchChange(value, app, tree)}
              filter={editingUserFilter}
              onCodesChange={(value) => onCodesChange(value, app, tree)}
              currentUserFilter={currentUserFilter}
              siteBranch={{ slug: "site", id: "site", title: t("user.application_setting.hierarchy_filters.level.site") }}
              persisted={editingUser.persisted}
              appId={app.id}
            />
          )
        })}
      </Card>
    </div>
  );
};

const getBranchOptionForUserFilter = (currentUserFilter, hierarchyTree, t) => {
  let branchOptions = [];
  let filterBranchPosition = 0;
  if (currentUserFilter && Object.keys(currentUserFilter).length > 0) {
    if (!currentUserFilter.hierarchyBranchSlug) {
      return [{ value: "none", title: t("user.application_setting.hierarchy_filters.level.no_access") }];
    }
    if (currentUserFilter.hierarchyBranchSlug === "site") {
      filterBranchPosition = -1;
    } else {
      filterBranchPosition = hierarchyTree.hierarchyBranches[hierarchyTree.hierarchyBranches.findIndex(hb => hb.slug === currentUserFilter.hierarchyBranchSlug)].position;
    }
  }
  if (filterBranchPosition >= 0) {
    branchOptions = hierarchyTree.hierarchyBranches.filter(hb => hb.position >= filterBranchPosition).map(hb => ({ value: hb.slug, title: hb.title }))
  }
  if (filterBranchPosition === 0) {
    branchOptions.unshift({ value: "all", title: hierarchyTree.rootHierarchyTitle });
  }

  branchOptions.push({ value: "site", title: t("user.application_setting.hierarchy_filters.level.site") });
  branchOptions.push({ value: "none", title: t("user.application_setting.hierarchy_filters.level.no_access") });
  return branchOptions;
};

const disabledTree = (options, filter) => {
  if (!filter && options[0].value !== "all") {
    return true;
  }

  if (filter && filter.hierarchyTreeId && !filter.hierarchyBranchSlug && options.length > 1) {
    return false;
  }
  if (filter && options.findIndex(branch => branch.value === filter.hierarchyBranchSlug) === -1) {
    return true;
  }

  return false;
};

const HierarchyFilterForm = props => {
  const {
    hierarchyTree,
    onBranchChange,
    filter,
    onCodesChange,
    index,
    currentUserFilter,
    errors,
    persisted,
    appId,
  } = props;

  const { t } = useI18n();

  const options = getBranchOptionForUserFilter(currentUserFilter, hierarchyTree, t);
  const allOptions = getBranchOptionForUserFilter(null, hierarchyTree, t);
  let disabled = persisted ? disabledTree(options, filter) : (currentUserFilter && !currentUserFilter.hierarchyBranchSlug);

  let branchValue;
  if (filter && !filter['_destroy']) {
    branchValue = filter.hierarchyBranchSlug || "none";
  } else {
    branchValue = options[0].value === "all" ? "all" : undefined;
  }

  return (
    <>
      <Row gutter={16} style={{ marginTop: index === 0 ? "0" : "10px" }}>
        <Col
          lg={3}
          md={4}
        >
          {hierarchyTree.title}
        </Col>
        <Col lg={4} md={4} style={{ padding: '0 5px', alignItems: 'center' }}>
          <Select
            style={{ width: '100%' }}
            onChange={onBranchChange}
            value={branchValue}
            disabled={disabled}
          >
            {(disabled ? allOptions : options).map(branch => (
              <Option
                value={branch.value}
              >
                {branch.title}
              </Option>
            ))}
          </Select>
        </Col>
        <Col lg={8} md={8}>
          {filter && filter.hierarchyBranchSlug && filter.hierarchyBranchSlug !== "all" && (
          <AntForm 
            object={{ ...filter, codesOptions: filter.codesOptions}}
            onChange={onCodesChange}
            errors={errors}
            schema={[{
              name: "codesOptions",
              errorKey: 'codes',
              input: {
                type: 'reactSelect',
                loadOptions: fetchOptions(filter.hierarchyBranchSlug, hierarchyTree.id, appId),
                inputProps: {
                  defaultOptions: filter?.options,
                  isDisabled: disabled,
                  isMulti: true,
                },
                options: {
                  optionLabel: 'label',
                  optionValue: 'value',
                }
              }
            }]}
          />
          )}
        </Col>
      </Row>
    </>
  );
};

export default HierarchyFiltersForm;