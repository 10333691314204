import "./ConfirmationModal.module.sass";
import React, { useState } from "react";
import {
  Button,
  Modal,
  Input
} from "antd";
import { useIntl } from 'react-intl';

interface IProps {
  open: boolean;
  onOk: () => void;
  keyPhrase: string;
  content: any;
  title: string;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<IProps> = props => {
  const {
    open, onOk, keyPhrase, content, title, onCancel,
  } = props;
  const [value, setValue] = useState<string>("");

  const intl = useIntl();
  const handleChange = (value: string) => {
    setValue(value);
  };

  const handleClose = () => {
    setValue("");
    onCancel();
  };

  const handleDelete = () => {
    onOk();
    onCancel();
  };

  return (
    <Modal
      visible={open}
      title={title}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          {intl.formatMessage({ id: "words.cancel" })}
        </Button>,
        <Button
          key="submit"
          danger
          onClick={handleDelete}
          disabled={!(value && value.toLowerCase() === keyPhrase.toLowerCase())}
        >
          {intl.formatMessage({ id: "words.delete" })}
        </Button>,
      ]}
    >
      <div>
        {content && <p>{content}</p>}
        <p>
          {intl.formatMessage({ id: "confirmation_modal.text_to_type"}, { key_phrase: keyPhrase })}
        </p>
        <Input
          placeholder={keyPhrase.toLowerCase()}
          value={value}
          onChange={({ target }) => handleChange(target.value)}
        />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
