// @ts-nocheck
import createImmerReducer from "../lib/createReducer";
import SiteService from "../services/site";
import { Site, Pagination } from "../types";
import { all, call, select, put, takeLatest, takeLeading } from "@redux-saga/core/effects";

type SiteStateType = {
  list: Site[];
  loading: boolean;
  current: Site | {};
  open: boolean;
  errors: any;
  pagination: Pagination; 
}

const initialState: SiteStateType = {
  current: {},
  list: [],
  loading: false,
  loadingList: false,
  open: false,
  importerOpen: false,
  pagination: {},
  errors: {},
};

const siteReducer = createImmerReducer<SiteStateType>(initialState, {
  "site/setList": (state, { payload }) => {
    state.list = payload.sites;
    state.pagination = payload.pagination
    return state;
  },
  "site/setCurrent": (state, { payload }) => {
    state.current = payload;
    return state;
  },
  "site/setOpen": (state, { payload }) => {
    state.open = payload;
    return state;
  },
  "site/setErrors": (state, { payload }) => {
    state.errors = payload;
    return state;
  },
  "site/setImporterOpen": (state, { payload }) => {
    state.importerOpen = payload;
    return state;
  },
  "site/setLoading": (state, { payload }) => {
    state.loading = payload
    return state;
  },
  "site/setLoadingList": (state, { payload }) => {
    state.loading = payload
    return state;
  },
})

function* onImporterOpen({ payload }: any) {
  yield put({ type: 'site/setImporterOpen', payload: payload })
}

export function* setOpen(open) {
  yield put({ type: 'site/setOpen', payload: open })
};

function* fetchSitesIfNeeded({payload}: any) {
  const list = yield select(state => state.sites.list);
  if (!list?.length) {
    const {
      page,
      pageSize,
      params
    } = payload;
  
    yield put({ type: 'site/setLoadingList', payload: true})
  
    try {
      const data = yield call(SiteService.fetch, page, pageSize, params);
      yield put({ type: 'site/setList', payload: data})
    } catch(e) {
      console.error(e)
    }
    yield put ({ type: 'site/setLoadingList' , payload: false })
  } 

}

function* setEdit({ payload }: any) {
  yield put({ type: 'site/setLoading', payload: true})
  if (payload) {
    try {
      const data = yield call(SiteService.fetchOne, payload);
      yield put({ type: 'site/setCurrent', payload: data.site })
      yield put({ type: 'site/setOpen', payload: true })

    } catch(e) {
      console.error(e)
    }
  } else {
    yield put({ type: 'site/setCurrent', payload: { active: true } })
    yield put({ type: 'site/setOpen', payload: true })
  }
  yield put({ type: 'site/setLoading', payload: false})
}

function* fetchSites({ payload }: any) {
  const {
    page,
    pageSize,
    params
  } = payload;

  yield put({ type: 'site/setLoading', payload: true});

  try {
    const data = yield call(SiteService.fetch, page, pageSize, params);
    yield put({ type: 'site/setList', payload: data });
  } catch(e) {
    console.error(e);
  }

  yield put({ type: 'site/setLoading' , payload: false })
}

function* saveSite({ payload }: any) {
  yield put({ type: 'site/setLoading', payload: true})

  try {
    const data = yield call(SiteService.save, { site: payload });
    yield put({ type: 'site/setCurrent', payload: data.site });
    yield put({ type: 'site/setErrors', payload: data.errors });

    const list = yield select(state => state.sites.list);
    const newList = [...list];
    const index = newList.findIndex(site => site.id === data.site.id);
    const pagination = yield select(state => state.sites.pagination);
    if (index >= 0) {
      newList[index] = data.site;
    } else {
      newList.push(data.site);
    }

    yield put({ type: 'site/setList', payload: { sites: newList, pagination } });

    if (!data.errors) {
      yield put({ type: 'site/setOpen', payload: false });
    }
  } catch(e) {
    console.error(e)
  }

  yield put ({ type: 'site/setLoading' , payload: false })
}

function* deleteSite({ payload }: any) {
  const {
    id
  } = payload;

  yield put({ type: 'site/setLoading', payload: true})

  try {
    const data = yield call(SiteService.destroy, id);
    yield put({ type: 'site/setCurrent', payload: {}})

    const list = yield select(state => state.sites.list);
    const newList = [...list]
    const index = newList.findIndex(site => site.id == id)
    const pagination = yield select(state => state.sites.pagination);
    if (index >= 0) {
      newList.splice(index, 1);
    }
    yield put({ type: 'site/setList', payload: { sites: newList, pagination} })
    
    if (!data.errors) {
      yield put ({ type: 'site/setOpen', payload: false })
    } else {
      yield put ({ type: 'site/setOpen', payload: true })
    }
  } catch(e) {
    console.error(e)
  }

  yield put ({ type: 'site/setLoading' , payload: false })
}

function* importSites({ payload }: any) {
  yield put ({ type: 'site/setLoading', payload: true })

  try {
    const data = yield call(SiteService.importFile, payload);
    yield put({ type: 'site/fetch', payload: { page: 1, pageSize: 30 }})
  } catch(e) {
    console.error(e);
  }

  yield put({ type: 'site/setLoading', payload: false });
  yield put({ type: 'site/setImporterOpen', payload: false});
}

function* deleteAllSites() {
  yield put({ type: 'site/setLoading', payload: true});

  try {
    const data = yield call(SiteService.deleteAll);
    yield put({ type: 'site/setList', payload: { sites: [], pagination: {} } });
  } catch(e) {
    console.error(e);
  }

  yield put({ type: "site/setLoading", payload: false })
}

function* globalSync() {
  yield put({ type: 'site/setLoading', payload: true})

  try {
    const data = yield call(SiteService.globalSync);
  } catch (e) {
    console.error(e);
  }

  yield put({ type: "site/setLoading", payload: false });
}

export function* siteSaga() {
  yield all([
    takeLeading('site/fetch', fetchSites),
    takeLeading('site/setEdit', setEdit),
    takeLeading('site/saveSite', saveSite),
    takeLeading('site/deleteSite', deleteSite),
    takeLeading('site/fetchSitesIfNeeded', fetchSitesIfNeeded),
    takeLeading('site/importSites', importSites),
    takeLeading('site/deleteAll', deleteAllSites),
    takeLeading('site/globalSynchro', globalSync)
  ])
}

export default siteReducer