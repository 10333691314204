import createImmerReducer from "../lib/createReducer";
import ApplicationService from "../services/application";
import { ApplicationType, Pagination } from "../types";
import { all, call, put, takeLatest, select } from "@redux-saga/core/effects";

type ApplicationStateType = {
  current?: ApplicationType;
  list: ApplicationType[];
  loading: boolean;
  open: boolean;
  pagination: Pagination;
  errors: { [k: string]: string };
  availableAddons?: { mongodbAddons?: [{ name: string, id: string, slug: string }], esAddons?: [{ name: string, id: string, slug: string }] }
};

const initialState: ApplicationStateType = {
  current: undefined,
  loading: false,
  list: [],
  open: false,
  pagination: {},
  errors: {},
  availableAddons: { },
};

const applicationReducer = createImmerReducer<ApplicationStateType>(initialState, {
  "application/setCurrent": (state, { payload }) => {
    state.current = payload;
    return state;
  },
  "application/setList": (state, { payload }) => {
    state.list = payload;
    return state;
  },
  "application/setOpen": (state, { payload }) => {
    state.open = payload;
    return state;
  },
  "application/setPagination": (state, { payload }) => {
    state.pagination = payload;
    return state;
  },
  "application/setErrors": (state, { payload }) => {
    state.errors = payload;
    return state;
  },
  "application/setAvailableAddons": (state, { payload }) => {
    state.availableAddons = payload;
    return state;
  },
  "application/setLoading": (state, { payload }) => {
    state.loading = payload;
    return state;
  }
});

function* fetchApplication(id) {
  yield put({ type: 'application/setLoading', payload: true });

  try {
    if (id) {
      const data = yield call(ApplicationService.fetchOne, { id });
      yield put({ type: 'application/setCurrent', payload: data.application });
      yield put({ type: 'application/setAvailableAddons', payload: data.addons });
    } else {
      yield put({ type: 'application/setCurrent', payload: {} });
    }
  } catch(e) {
    console.error(e);
  }
  yield put({ type: 'application/setLoading', payload: false })
}

function* fetchApplications() {
yield put({ type: 'application/setLoading', payload: true })

  try {
    const data = yield call(ApplicationService.fetch);
    yield put({ type: 'application/setList', payload: data.applications });
  } catch(e) {
    console.error(e);
  }
  yield put({ type: 'application/setLoading', payload: false })
}

function* setEdit({ payload }: any) {
  yield put({ type: 'applications/setLoading', payload: true });
  const data = yield call(ApplicationService.fetchOne, payload);
  if (payload) {
    try {
      yield put({ type: 'application/setCurrent', payload: data.application });
      yield put({ type: 'application/setOpen', payload: true });
    } catch (e) {
      console.error(e);
    }
  } else {
    yield put({ type: 'application/setCurrent', payload: { active: true } });
    yield put({ type: 'application/setAvailableAddons', payload: data.availableAddons })
    yield put({ type: 'application/setOpen', payload: true });
  }
  yield put({ type: 'application/setLoading', payload: false });
}

function* saveApplication({ payload }: any) {
  yield put({ type: "application/setLoading", payload: true });

  try {
    const data = yield call(ApplicationService.save, { application: payload });
    yield put({ type: 'application/setCurrent', payload: data.application });
    yield put({ type: 'site/setErrors', payload: data.errors });

    const list = yield select(state => state.applications.list);
    const newList = [...list];
    const index = newList.findIndex(application => application.id === data.application.id);
    const pagination = yield select(state => state.applications.pagination);
    if (index >= 0) {
      newList[index] = data.application;
    } else {
      newList.push(data.application);
    }

    yield put({ type: 'application/setList', payload: newList });
    yield put({ type: 'application/setPagination', payload: pagination })
    if (!data.errors) {
      yield put({ type: 'application/setOpen', payload: false });
    }
  } catch (e) {
    yield put({ type: 'application/setErrors', payload: e.errors });
    console.error(e);
  }

  yield put({ type: "application/setLoading", payload: false });
};

export function* applicationSaga() {
  yield all([
    takeLatest('application/fetchOne', fetchApplication),
    takeLatest('application/fetch', fetchApplications),
    takeLatest('application/setEdit', setEdit),
    takeLatest('application/saveApplication', saveApplication),
  ]);
}

export default applicationReducer;