export const isDevelopment = process.env.NODE_ENV === "development";

export const getEnvFromUrl = (url:(string | null) = null) => {
  if (!url) {
    url = window.location.href;
  }
  const { hostname } = new URL(url);

  if (!hostname) {
    return process.env.NODE_ENV;
  }

  const parts = hostname.split('.');
  if (parts.length < 3) {
    return process.env.NODE_ENV;
  }

  if (parts[parts.length - 3] === "staging") {
    return "staging";
  }

  return process.env.NODE_ENV;
};