import { call, put, takeLatest, all } from "@redux-saga/core/effects";
import createImmerReducer from "../lib/createReducer";
import { User, Pagination } from '../types';
import UserService from "../services/user";

type UserStateType = {
  part: string;
  list: User[];
  current: User;
  open: boolean;
  passwordVisible: boolean;
  errors: any;
  saveLoading: boolean;
  loading: boolean;
  pagination: Pagination;
  wizardSteps: string[];
  editParams: any;
  importerOpen: boolean;
  importErrors: any[];
};

const initialState: UserStateType = {
  part: "",
  current: {},
  list: [],
  loading: false,
  saveLoading: false,
  passwordVisible: false,
  open: false,
  errors: {},
  wizardSteps: [],
  pagination: {},
  editParams: {},
  importerOpen: false,
  importErrors: [],
};

function* sync() {
  yield put({ type: "user/setLoading", payload: true });
  try {
    yield call(UserService.sync);
  } catch (e) {
    console.error(e);
  }
  yield put({ type: 'user/setLoading', payload: false });
}

function* show({ payload }: any) {
  const { id } = payload;
  yield put({ type: "user/setLoading", payload: true });
  yield put({ type: 'user/setVisible', payload: true });
  try {
    let data;
    if (id) {
      data = yield call(UserService.show, id);
    } else {
      data = yield call(UserService.newUser);
    }
    yield put({ type: 'user/setCurrent', payload: data.userData });
    yield put({ type: 'user/setWizardSteps', payload: data.wizardSteps });
    yield put({ type: 'user/setEditParams', payload: data.params });
  } catch (e) {
    console.error(e);
  }

  yield put({ type: 'user/setLoading', payload: false });
}

function* editPassword({ payload }: any) {
  const { id } = payload;
  yield put({ type: "user/setLoading", payload: true });
  yield put({ type: "user/setPasswordVisible", payload: true });
  yield put({ type: 'user/setCurrent', payload: id });
  yield put({ type: "user/setLoading", payload: false });
}

function* updatePassword({ payload }: any) {
  yield ({ type: "user/setSaveLoading", payload: true });

  try {
    const data = yield call(UserService.updatePassword, payload);
    if (Object.keys(data.errors || {}).length > 0) {
      yield put({ type: 'user/setErrors', payload: data.errors });
    } else {
      yield put({ type: "user/setPasswordVisible", payload: false })
      yield put({ type: "user/setCurrent", payload: {} })
    }
    yield put({ type: "user/setSaveLoading", payload: undefined })
  } catch (e) {
    console.error(e);
    if (e.errors) yield put({ type: 'user/setErrors', payload: e.errors });
  }
}

function* save({ payload, options }: any) {
  yield put({ type: "user/setSaveLoading", payload: true });
  try {
    const data = yield call(UserService.save, payload);
    yield put({ type: 'user/setErrors', payload: data?.errors });
    if (!data.errors) {
      const avatarPayload = {
        id: data.user.id,
        persisted: data.user.persisted,
        avatar: payload.avatar,
        email: payload.email,
      };
      const avatarData = yield call(UserService.saveAvatar, avatarPayload);
      if (avatarData?.errors && Object.keys(avatarData.errors).length > 0) {
        yield put({ type: 'user/setErrors', payload: avatarData.errors });
      } else {
        if (options?.onCompleted) options.onCompleted(data);
        yield put({ type: "user/cancelEdit" });
      }
    }
  } catch (e) {
    yield put({ type: 'user/setErrors', payload: e?.errors });
    console.error(e);
  }
  yield put({ type: 'user/setSaveLoading', payload: false });
}

function* deleteUser({ payload }: any) {
  yield put({ type: "user/setLoading", payload: true });
  try {
    const data = yield call(UserService.deleteUser, payload);
    if (Object.keys(data?.errors).length > 0) {
      yield put({ type: 'user/setErrors', payload: data.errors });
    } else {
      yield put({ type: "user/cancelEdit" });
    }
  } catch (e) {
    console.error(e);
  }
  yield put({ type: "user/setLoading" });
}

function* importUsers({ payload }: any) {
  yield put({ type: 'user/setLoading', payload: true });
  try {
    const data = yield call(UserService.importFile, payload);
    yield put({ type: 'user/setList', payload: { users: data.users, pagination: data.pagination } });
    if (Object.keys(data.errors).length > 0) {
      yield put({ type: 'user/setImporterErrors', payload: data.errors });
    } else {
      yield put({ type: 'user/setImporterOpen', payload: false });
    }
  } catch (e) {
    console.error(e);
  }
  yield put({ type: 'user/setLoading', payload: false });
}

function* resendInvitations({ payload }: any) {
  yield put({ type: "user/setLoading", payload: true });
  try {
    const data = yield call(UserService.resendInvitations, payload);
    if (Object.keys(data?.errors || {}).length > 0) {
      yield put({ type: 'user/setErrors', payload: data.errors });
    }
  } catch (e) {
    console.error(e);
  }
  yield put({ type: "user/setLoading", payload: false });
}

const userReducer = createImmerReducer<UserStateType>(initialState, {
  "user/setLoading": (state, { payload }) => {
    state.loading = payload;
    return state;
  },
  "user/setSaveLoading": (state, { payload }) => {
    state.saveLoading = payload;
    return state;
  },
  "user/setList": (state, { payload }) => {
    state.list = payload.users;
    state.pagination = payload.pagination;
    return state;
  },
  "user/setCurrent": (state, { payload }) => {
    state.current = payload;
    return state;
  },
  "user/setVisible": (state, { payload }) => {
    state.open = payload;
    return state;
  },
  "user/setPasswordVisible": (state, { payload }) => {
    state.passwordVisible = payload;
    return state;
  },
  "user/setWizardSteps": (state, { payload }) => {
    state.wizardSteps = payload;
    return state;
  },
  "user/cancelEdit": (state) => {
    state.open = false;
    state.passwordVisible = false;
    state.current = {};
    state.errors = {};
    return state;
  },
  "user/setEditParams": (state, { payload }) => {
    state.editParams = payload;
    return state;
  },
  "user/setErrors": (state, { payload }) => {
    state.errors = payload;
    return state;
  },
  "user/setImporterOpen": (state, { payload }) => {
    state.importerOpen = payload;
    return state;
  },
  "user/setImporterErrors": (state, { payload }) => {
    state.importErrors = payload;
    return state;
  },
  "user/setPart": (state, { payload }) => {
    state.part = payload;
    return state;
  }
});

export function* userSaga() {
  yield all([
    takeLatest('user/sync', sync),
    takeLatest('user/show', show),
    takeLatest('user/save', save),
    takeLatest('user/updatePassword', updatePassword),
    takeLatest('user/delete', deleteUser),
    takeLatest('user/resendInvitations', resendInvitations),
    takeLatest('user/importUsers', importUsers),
    takeLatest('user/editPassword', editPassword),
  ]);
}

export default userReducer;