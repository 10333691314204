import createImmerReducer from "../lib/createReducer";

type LocaleStateType = {
  currentLocale: string;
  availableLocales: string[];
}

const initialState: LocaleStateType = {
  currentLocale: 'fr',
  // vide ou avec les possibles de CX ? 
  availableLocales: ['fr', 'en', 'es']
}

const localeReducer = createImmerReducer<LocaleStateType>(initialState, {
  "locales/setCurrent": (state, { payload }) => {
    state.currentLocale = payload;
    return state;
  },
  "locales/setAvailableLocales": (state, { payload }) => {
    state.availableLocales = payload;
    return state;
  }
})

export default localeReducer;