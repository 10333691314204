import React, { useState, useEffect } from "react";
import AntForm, { AntSchema } from '@9troisquarts/ant-form.ant-form';
import { useIntl } from "react-intl";
import styles from "./index.module.sass";

interface ImporterUI {
  separator: string;
  encoding: string;
  file?: any;
  modified?: boolean;
  submitted?: boolean;
}

const useImporterUI = (props) => {
  const [ui, setUi] = useState<ImporterUI>({
    separator: ";",
    encoding: "UTF-8",
    modified: false,
    submitted: false,
  });

  useEffect(() => {
    setUi({
      separator: props.separator,
      encoding: props.encoding,
    });
  }, []);

  return { ui, setUi };
};

const importerSchema = (options: { encoding?: boolean, separator?: boolean }, intl: any, children ): AntSchema => {
  const schema: AntSchema = [
    {
      name: "file",
      input: {
        type: "upload",
        //@ts-ignore
        title: "Choisir un fichier",
      }
    }
  ]

  if(options.encoding) {
    schema.unshift({
      name: "encoding",
      label: intl.formatMessage({ id: "encoding" }),
      input: {
        type: "select",
        options: [{
          value: "UTF-8",
          label: "UTF-8",
        }, {
          value: "WINDOWS-1252",
          label: "WINDOWS-1252",
        }],
      }
    });
  }

  if (options.separator) {
    schema.unshift({
      name: "separator",
      label: intl.formatMessage({ id: "separator" }),
      input: {
        type: "string",
      }
    });
  }

  schema.push(children);

  return schema;
};

interface IProps {
  loading: boolean;
  showSeparator: boolean;
  onSubmit: (file: any) => void;
  options?: { encoding?: boolean, separator?: boolean };
  children: any;
}

const Importer: React.FC<IProps> = props => {
  const {
    onSubmit,
    loading,
    options = {},
    children,
  } = props;

  const {
    ui,
    setUi,
  } = useImporterUI(props);

  const intl = useIntl();
  const onChangeUI = (values) => setUi({ ...ui, ...values})

  const handleSubmit = () => {
    onChangeUI({ submitted: true })
    onSubmit({ file: ui.file, separator: ui.separator});
  }

  return (
    <div>
      <AntForm
        schema={importerSchema(options, intl, children)}
        object={ui}
        onSubmit={handleSubmit}
        submitText="Importer"
        onChange={onChangeUI}
        loading={loading}
        layout="vertical"
        actionsWrapperProps={{
          className: styles.importerActionWrapper
        }}
      />
    </div>
  )
}

export default Importer