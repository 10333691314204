import { Modal, Spin } from "antd";
import React from "react";
import { Group } from "../../../../types";
import useI18n from "../../../../utils/useI18n";
import { useHierarchyTitle } from "../../useHierarchyTitle";
import HierarchyTitleForm from "./HierarchyTitleForm";

interface HierarchyTitleEditProps {
  hierarchyTitleId: string;
  visible: boolean;
  branches: {withoutBranch?: boolean; slug: string; title: string }[];
  onCancel: () => void;
  group: Group;
}

const HierarchyTitleEdit: React.FC<HierarchyTitleEditProps> = props => {
  const {
    hierarchyTitleId,
    visible,
    branches,
    onCancel,
    group,
  } = props;

  const { t } = useI18n();

  const {
    hierarchyTitle,
    isLoading
  } = useHierarchyTitle(hierarchyTitleId);

  if (!visible) return null;
  if (hierarchyTitleId === undefined) return null;

  return (
    <Modal
      visible={visible}
      title={hierarchyTitle?.title || t("hierarchy_title.new_hierarchy_title")}
      onCancel={onCancel}
      footer={false}
    >
      {isLoading && (
        <Spin />
      )}
      {!isLoading && (
        <HierarchyTitleForm
          hierarchyTitle={hierarchyTitle || {}}
          branches={branches}
          onCancel={onCancel}
          group={group}
        />
      )}
    </Modal>
  );
};

export default HierarchyTitleEdit;