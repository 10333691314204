import React from 'react';
import {
  Col,
  Row,
  Space,
} from "antd";

import sortBy from "lodash/sortBy";
import { ApplicationType, Group, Tenant } from '../../../../types';
import AppCard from '../AppCard';

interface IProps {
  appList: ApplicationType[];
  userGroup?: Group | undefined;
  onEdit: (application: ApplicationType) => void;
  /** bind `applicationEdit` modal to the edit action on the card (ie: pass application data to the function used to open a modal 
   * in order to display and edit application data of that card) */
  readOnly?: boolean | undefined;
  /** if `true` disable all the possible actions on the cards */
  tenant: Tenant;
}

/**
 * List compenent of all user aplications as card item
 */
const AppCardList: React.FC<IProps> = props => {

  const {
    appList,
    userGroup,
    onEdit,
    readOnly,
    tenant
  } = props;

  return (
    <Row gutter={16}>
      <Space wrap>
        {
          sortBy(appList, app => app.applicationType).map(record => {

            return (
              <AppCard
                key={`app-card-${record.id}`}
                application={record}
                onEdit={onEdit}
                userGroup={userGroup}
                readOnly={readOnly}
                tenant={tenant}
              />
            );
          })
        }
      </Space>
    </Row>
  );
}

AppCardList.defaultProps = {
  readOnly: undefined,
  userGroup: undefined,
};

export default AppCardList;