import client from "../utils/client";

const fetch = async () => {
  const response = await client.get('/export_files.json');
  return response;
};

const download = fileId => window.open(`/export_files/download/${fileId}`);

const ExportFiles = {
  fetch,
  download
};

export default ExportFiles;