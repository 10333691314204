// @ts-nocheck
import { Alert, Button, Card, Collapse, Descriptions, PageHeader, Tag } from 'antd';
import React from 'react';
import { omit } from 'lodash';
import client from '../../utils/client';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useI18n from '../../utils/useI18n';
import { AntForm } from '@9troisquarts/ant-form.ant-form';
import { camelize, decamelize } from 'humps';

const getAuthProviders = async () => {
  const response: any = await client.get('/auth_providers');
  return response?.samlProviders;
}

const createSamlProvider = async () => {
  const response = await client.post('/auth_providers');
  return response.data;
}

const updateSamlProvider = async ({ id, values }) => {
  // Supprimer les clé idp_cert et idp_cert_multi si elles sont vides
  if (!values.idp_cert || values.idp_cert.length === 0) delete values.idp_cert;
  if (!values.idp_cert_fingerprint || idp_cert_fingerprint.length === 0) delete values.idp_cert_fingerprint;
  const response = await client.put(`/auth_providers/${id}`, {
    ...(values.xml_metadata ? { xml_metadata: values.xml_metadata } : {}),
    auth_provider: values.xml_metadata ? { active: values.active } : values
  });
  return response.data;
}
const samlAttributes = [
  "issuer",
  "assertionConsumerUrl",
  "idpSsoTargetUrl",
  "idpSsoTargetUrlRuntimeParams",
  "idpCert",
  "idpCertFingerprint",
  "nameIdentifierFormat"
]

const readOnlyAttributes = [
  "issuer",
  "assertionConsumerUrl",
]

const optionalAttributes = [
  "nameIdentifierFormat",
  "idpSsoTargetUrlRuntimeParams"
]

const helps = {
  assertionConsumerUrl: "URL de redirection après connexion , la connexion SAML doit être configurée avec cette URL de redirection",
  issuer: "Nom de l'application, la connexion SAML doit être configurée avec ce nom",
  idpCert: "Laissez vide si aucun changement, l'ancienne valeur sera conservée",
  idpCertFingerprint: 'Laissez vide si aucun changement, l\'ancienne valeur sera conservée',
}

const specificInputTypes = {
  idpCert: 'password',
  idpCertFingerprint: 'password'
}

const AuthProviders = () => {
  const I18n = useI18n();
  const queryClient = useQueryClient();
  const [readOnly, setReadOnly] = React.useState(true);
  const {
    data,
    isLoading,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryFn: getAuthProviders,
    queryKey: 'getAuthProviders',
  });

  const createMutation = useMutation({ mutationFn: createSamlProvider, onSuccess: () => queryClient.invalidateQueries('getAuthProviders') });
  const updateMutation = useMutation({ mutationFn: updateSamlProvider, onSuccess: () => {
    queryClient.invalidateQueries('getAuthProviders');
    setReadOnly(true);
  } });

  const samlProvider = (data || [])?.find(provider => provider.provider === 'saml')

  return (
    <>
      <PageHeader
        title="Connexion SSO"
      />
      <Card loading={isLoading}>
        <Alert
          style={{ marginBottom: 16 }}
          type="error"
          showIcon
          message="Attention !"
          description="Toute modification non controlée des connexions SSO peut entrainer des problèmes de connexion."
        />
        {
          !samlProvider && (
            <div>
              Aucune connexion SAML n'est configurée.&nbsp;
              <Button type="link" onClick={() => createMutation.mutate()} loading={createMutation.isLoading}>
                Créer une connexion SAML
              </Button>
            </div>
          )
        }
        {
          samlProvider && (
            <>
              {readOnly && (
                <Descriptions title={(<div>
                  <span>Connexion SAML</span>
                  {samlProvider.active ? <Tag color="blue" style={{ marginLeft: 8 }}>Actif</Tag> : <Tag color="red" style={{ marginLeft: 8 }}>Inactif</Tag>}
                  <Button type="link" onClick={() => setReadOnly(false)}>Modifier</Button>
                </div>)} 
                column={1}>
                  {samlAttributes.filter((attr) => samlProvider[attr] || !optionalAttributes.includes(attr)).map(attr => (
                    <Descriptions.Item label={I18n.t(`saml_attributes.${decamelize(attr)}`)} key={attr}>
                      {samlProvider[attr] || '-'}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              )}
              {!readOnly && (
                <div>
                  <div>
                    <span style={{ fontWeight: 700, fontSize: 16 }}>Connexion SAML</span>
                    {samlProvider.active ? <Tag color="blue" style={{ marginLeft: 8 }}>Actif</Tag> : <Tag color="red" style={{ marginLeft: 8 }}>Inactif</Tag>}
                    <Button type="link" onClick={() => setReadOnly(true)}>Annuler l'édition</Button>
                    <AntForm
                      object={omit(samlProvider, ['idpCert', 'idpCertFingerprint'])}
                      onSubmit={(values) => updateMutation.mutate({ id: samlProvider.id, values })}
                      layout="vertical"
                      loading={updateMutation.isLoading}
                      submitText='Enregistrer'
                      schema={[
                        {
                          name: 'active',
                          label: 'Actif',
                          input: {
                            type: 'switch',
                          }
                        },
                        {
                          name: 'xml_metadata',
                          label: 'Depuis un xml',
                          help: "Si un fichier XML est fourni, en mettant le contenu de ce dernier ici, les autres champs seront automatiquement remplit à l'enregistrement.",
                          input: {
                            type: 'text'
                          }
                        }
                      ].concat(samlAttributes.filter(attr => !readOnlyAttributes.includes(attr)).map(attr => ({
                        name: attr,
                        label: I18n.t(`saml_attributes.${decamelize(attr)}`),
                        help: helps[attr],
                        required: !optionalAttributes.includes(attr),
                        input: {
                          type: specificInputTypes[attr] || 'string',
                        }
                      })))}
                    />
                  </div>
                </div>
              )}
            </>
          )
        }
      </Card>
    </>
  );
}

export default AuthProviders;