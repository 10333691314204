import React, { useEffect, useState } from 'react';
import { flatten, uniqBy } from "lodash";
import AntForm from '@9troisquarts/ant-form.ant-form';
import AntFormReactSelect from '@9troisquarts/ant-form.ant-form-react-select';
import {
  Card,
  PageHeader,
  Button,
  Popconfirm,
  Space,
} from 'antd';
import {
  PlusOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { useIntl } from "react-intl";
import SiteList from "./components/SiteList";
import client from '../../utils/client';
import SiteShow from "./components/SiteShow";
import {
  ExportFile,
  Group,
  HierarchyTree,
  Pagination,
  Site as SiteType
} from '../../types';
import SiteImporter from "./components/SiteImporter";
import styles from "./index.module.sass";
import memoOnlyForKeys from '../../utils/memoOnlyForKeys';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import DangerZone from '../../components/DangerZone';
import { hasRole } from '../../utils/Authorization';

//@ts-ignore
AntForm.addField('reactSelect', {
  component: AntFormReactSelect,
  loadingMessage: () => 'Loading...',
  noOptionsMessage: () => 'No options found...',
});
interface IProps {
  sites: SiteType[];
  currentSite: SiteType | {};
  loading: boolean;
  loadingList: boolean;
  open: boolean;
  fetchData: (page: number, pageSize: number, params: { [k: string]: string}) => void;
  fetchDataIfNeeded: (page: number, pageSize: number, params: { [k: string]: string}) => void;
  setEdit: (open?: string) => void;
  onCancel: () => void;
  fetchHierarchiesIfNeeded: () => void;
  pagination: Pagination;
  hierarchyTrees: HierarchyTree[];
  errors: any;
  onSave: (params) => void;
  onDelete: (id: string) => void;
  onImporterOpen: (open: boolean) => void;
  importerOpen: boolean;
  deleteAll: () => void;
  onImport: (file: any) => void;
  onGlobalSync: () => void;
  addExportFile: (exportFile: ExportFile) => void;
  group: Group;
}

const onExport = (addExportFile) => {
  const endpoint = `/sites/export.xlsx`;
  //@ts-ignore
  client.get(endpoint).then(({ exportFile }) => addExportFile(exportFile));
};

const Site: React.FC<IProps> = props => {
  const {
    sites,
    currentSite,
    loading,
    loadingList,
    fetchData,
    setEdit,
    fetchHierarchiesIfNeeded,
    hierarchyTrees,
    pagination,
    open,
    onCancel,
    errors,
    onSave,
    onDelete,
    fetchDataIfNeeded,
    onImporterOpen,
    importerOpen,
    onImport,
    deleteAll,
    onGlobalSync,
    addExportFile,
    group,
  } = props;

  const branches = uniqBy(
    flatten(
      hierarchyTrees?.map(ht => ht?.hierarchyBranches?.map(({ slug, title }) => ({ slug, title })))
    ),
    'slug',
  );

  const intl = useIntl();

  

  useEffect(() => {
    fetchDataIfNeeded(1, 30, {});
    if (branches.length == 0) {
      fetchHierarchiesIfNeeded();
    }
  }, []);

  const onEdit = (id) => setEdit(id);
  return (
    <>
      <div className={styles.siteWrapper}>
        <PageHeader className={styles.pageHeader} title={intl.formatMessage({ id: "sites.header" })} />
        <div className={styles.actionsWrapper} key="actions-wrapper">
          <Space>
            { hasRole(group, ["site_create"]) && (
              <Button
                key="add-site-button"
                onClick={() => setEdit(undefined)}
                icon={<PlusOutlined />}
              >
                {intl.formatMessage({ id: "activerecord.attributes.hierarchies.add_site" })}
              </Button>
            )}
            { hasRole(group, ["site_import"]) && (
              <Button
                onClick={() => onImporterOpen(true)}
                type="primary"
                icon={<DownloadOutlined />}
              >
                {intl.formatMessage({ id: "import" })}
              </Button>
            )}
            <Button
              onClick={() => onExport(addExportFile)}
              icon={<FileExcelOutlined />}
            >
              {intl.formatMessage({ id: "words.export" })}
            </Button>
            {hasRole(group, ["site_create", "site_import"]) && (
              <Popconfirm
                title={intl.formatMessage({ id: "synchronization.global_confirm" })}
                onConfirm={() => onGlobalSync()}
                okText={intl.formatMessage({ id: "words.ok" })}
                cancelText={intl.formatMessage({ id: "words.cancel" })}
                placement="bottomRight"
              >
                <Button
                  icon={<SyncOutlined />}
                >
                  {intl.formatMessage({ id: "synchronization.global_sites" })}
                </Button>
              </Popconfirm>
            )}
          </Space>
        </div>
      </div>
      <Card style={{ position: "relative" }} className={styles.siteListWrapper}>
        <SiteList
          sites={sites}
          onEdit={onEdit}
          onDelete={onDelete}
          pagination={pagination}
          fetchData={(page, pageSize, params) => fetchData(page, pageSize, params)}
          loading={loadingList}
          branches={branches}
          group={group}
        />
        {open && (
          <SiteShow
            site={currentSite}
            open={open}
            onClose={onCancel}
            branches={branches}
            errors={errors}
            onSubmit={onSave}
            group={group}
          />
        )}
        {hasRole(group, ["site_import"]) && importerOpen && (
          <SiteImporter
            importerOpen={importerOpen}
            loading={loading}
            onImporterOpen={onImporterOpen}
            onImport={onImport}
          />
        )}
        {hasRole(group, ["site_delete_all"]) && (
          <DangerZone
            items={[
              {
                active: true,
                title: intl.formatMessage({ id: "sites.delete_all" }),
                description: intl.formatMessage({ id: "sites.delete_all_description" }),
                action: (<DeleteData
                  title={intl.formatMessage({ id: "words.delete" })}
                  onDelete={deleteAll}
                  warningMessage={intl.formatMessage({ id: "sites.delete_all_description" })}
                />),
              },
            ]}
          />
        )}
      </Card>
    </>
  );
};

const DeleteData = props => {
  const { onDelete, warningMessage, title } = props;
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  return (
    <>
      <Button danger onClick={() => setOpen(!open)}>
        {` ${intl.formatMessage({ id: "words.delete" })} `}
      </Button>
      <ConfirmationModal
        title={title}
        open={open}
        onOk={onDelete}
        onCancel={() => setOpen(false)}
        content={warningMessage}
        keyPhrase="supprimer"
      />
    </>
  );
};

const mapStateToProps = state => ({
  sites: state.sites.list,
  currentSite: state.sites.current,
  loading: state.sites.loading,
  loadingList: state.sites.loadingList,
  open: state.sites.open,
  errors: state.sites.errors,
  hierarchyTrees: state.hierarchyTrees.list,
  pagination: state.sites.pagination,
  importerOpen: state.sites.importerOpen,
  group: state.group.current,
});

const mapDispatchToProps = dispatch => ({
  fetchData: (page, pageSize, params) => dispatch({ type: 'site/fetch', payload: { page, pageSize, params } }),
  fetchHierarchiesIfNeeded: () => dispatch({ type: 'hierarchyTree/fetchHierarchiesIfNeeded' }),
  setEdit: (id) => dispatch({ type: 'site/setEdit', payload: id }),
  onCancel: () => {
    dispatch({ type: 'site/setOpen', payload: false });
    dispatch({ type: 'site/setCurrent', payload: {} });
    dispatch({ type: 'site/setErrors', payload: {} });
  },
  onSave: (params) => dispatch({ type: 'site/saveSite', payload: params }),
  onDelete: (id) => dispatch({ type: 'site/deleteSite', payload: id }),
  fetchDataIfNeeded: (page, pageSize, params) => {
    dispatch({ type: 'site/fetchSitesIfNeeded', payload: { page, pageSize, params } });
  },
  onImporterOpen: (open) => dispatch({ type: 'site/setImporterOpen', payload: open }),
  onImport: (file) => dispatch({ type: 'site/importSites', payload: file }),
  deleteAll: () => dispatch({ type: 'site/deleteAll' }),
  onGlobalSync: () => dispatch({ type: 'site/globalSynchro' }),
  addExportFile: (file) => {
    dispatch({ type: 'exportFiles/addToList', payload: file });
  }
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Site), memoOnlyForKeys(["sites", "open", "hierarchyTrees"]));