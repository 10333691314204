import { useEffect, useState } from 'react';

interface IProps {
  url?: string;
  async?: boolean;
  content?: string;
  scriptId?: string;
  attributes?: { [name: string]: string };
}

const useScript = (props: IProps) => {
  const {
    url,
    async = true,
    content,
    scriptId,
    attributes,
  } = props;

  // IMPORTANT: when no params are specified the isLoaded should always be false (other parts of the app depends on it)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!url && !content) {
      return;
    }
    setLoading(true);

    const script = document.createElement('script');
    document.body.appendChild(script);

    script.type = 'text/javascript';
    script.async = async;

    if (attributes) {
      Object.entries(attributes).forEach(([name, value]) => {
        script.setAttribute(name, value);
      });
    }

    if (scriptId) {
      script.id = scriptId;
    }

    script.addEventListener("load", (event) => {
      setLoading(false);
    });

    if (url) {
      if (content) {
        console.error("Cannot load dynamic script with src and content (content will be ignored if src is specified");
      }
      script.src = url;
    } else if (content) {
      script.innerHTML = content;
      setLoading(false);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      document.body.removeChild(script);
    };
  }, [url, content, scriptId]);

  return {
    loading,
  };
};

export default useScript;

/*
Usage:

const MyComponent = props => {
  useScript({url: 'https://use.typekit.net/foobar.js'});

}

*/