import React, { useEffect, useReducer } from "react";
import {
  PlusOutlined,
  SaveOutlined,
  WarningOutlined,
  SyncOutlined
} from '@ant-design/icons';
import {
  Button,
  Tabs,
  Card,
  PageHeader,
  Popconfirm,
} from "antd";
import findIndex from "lodash/findIndex";
import isEqual from "lodash/isEqual"
import { useIntl } from 'react-intl';
import AntForm from "@9troisquarts/ant-form.ant-form";
import { connect } from "react-redux";
import styles from "./components/HierarchySetting/index.module.sass";
import HierarchyTreeTabContent from "./components/HierarchySetting/HierarchyTreeTab";
import HierarchyBranchForm from "./components/HierarchySetting/HierarchyBranchForm";
import {
  HierarchyTree,
  HierarchyBranch,
  ApplicationType,
  Group,
} from "../../types";
import reducer, { initialState, ActionType } from "./components/HierarchySetting/useHierarchyUI";
import LocalesSwitcher from "../../components/LocaleSwitcher";
import { hasRole } from '../../utils/Authorization';

const { TabPane } = Tabs;

interface Props {
  hierarchyTrees: HierarchyTree[];
  siteHierarchyTitleTranslations: any;
  errors: any[];
  onSave: (hierarchyTrees: HierarchyTree[], siteConfiguration: any) => void;
  locale?: string;
  persisted: boolean;
  fetchData: () => void;
  onGlobalSynchronization: () => void;
  applications: ApplicationType[];
  group: Group;
}

const EditHierarchyTrees: React.FC<Props> = props => {
  const {
    hierarchyTrees,
    errors,
    locale = "fr",
    onSave,
    persisted,
    onGlobalSynchronization,
    siteHierarchyTitleTranslations,
    applications,
    fetchData,
    group,
  } = props;

  const intl = useIntl();

  useEffect(() => fetchData(), []);

  const [state, dispatch] = useReducer(reducer, initialState(props));
  const {
    nextHierarchyTrees,
    activeKey,
    changed,
    currentBranch,
    editBranchVisible,
    parameters,
  } = state;

  useEffect(() => {
    dispatch({
      type: ActionType.setNextHierarchyTrees, payload: hierarchyTrees
    });
    dispatch({
      type: ActionType.setParameters, payload: { siteHierarchyTitleTranslations }
    });
    dispatch({
      type: ActionType.setChanged, payload: false
    });
  }, [hierarchyTrees, persisted, siteHierarchyTitleTranslations]);

  const onEditBranch = (currentBranch: HierarchyBranch) => {
    dispatch({
      type: ActionType.setCurrentBranch, payload: currentBranch
    });
    dispatch({
      type: ActionType.setEditBranchVisible, payload: true
    });
  };

  const onChange = (value: any, index: number) => {
    const newHierarchyTree = { ...nextHierarchyTrees[index], ...value };
    const newHierarchyTrees = [...nextHierarchyTrees];
    newHierarchyTrees[index] = newHierarchyTree;
    if (value._destroy && !newHierarchyTree.id) {
      dispatch({ type: ActionType.setActiveKey, payload: index === 0 ? index.toString() : (index - 1).toString() });
      newHierarchyTrees.splice(index, 1);
    }

    dispatch({
      type: ActionType.setNextHierarchyTrees, payload: newHierarchyTrees
    });
    dispatch({
      type: ActionType.setChanged, payload: !isEqual(hierarchyTrees, { ...newHierarchyTrees })
    });
  };

  const onParameterChange = (value: any) => {
    dispatch({ type: ActionType.setParameters, payload: value });
    dispatch({
      type: ActionType.setChanged, payload: !isEqual(siteHierarchyTitleTranslations, parameters.siteHierarchyTitleTranslations)
    });
  };

  const onTabChange = (key: string) => {
    if (key === "newTab") {
      dispatch({ type: ActionType.setNextHierarchyTrees, payload: [...nextHierarchyTrees, { title: intl.formatMessage({ id: "active.record.attributes.hierarchies.new_hierarchy" }) }] });
      dispatch({ type: ActionType.setChanged, payload: true });
      dispatch({ type: ActionType.setActiveKey, payload: (nextHierarchyTrees.length).toString() });
    } else {
      dispatch({ type: ActionType.setActiveKey, payload: key });
    }
  };

  const tabErrors = (hierarchyTree: HierarchyTree, i: number) => errors[findIndex(errors, e => e.id === hierarchyTree?.id || i === e.index)];

  const tabTitle = (hierarchyTree: HierarchyTree, i: number) => {
    let hierarchyTreeError = {};

    let textColor = "inherit";
    let title = intl.formatMessage({ id: "activerecord.attributes.hierarchies.new_hierarchy" });
    if (hierarchyTree.titleTranslations && hierarchyTree.titleTranslations[locale]) {
      title = hierarchyTree.titleTranslations[locale];
    }

    const hasChanged = changed && !isEqual(hierarchyTrees[i], { ...nextHierarchyTrees[i] });
    if (hasChanged) title += " *";

    if (errors) hierarchyTreeError = tabErrors(hierarchyTree, i);

    if (hierarchyTreeError) textColor = "#fa8c16";
    if (hierarchyTree._destroy) textColor = "#ec4b5f";

    return (
      <div style={{ color: textColor, fontStyle: hasChanged ? 'italic' : 'inherit' }}>
        {title}
        {hierarchyTreeError && (
          <WarningOutlined style={{ color: "#fa8c16", marginLeft: "5px" }} />
        )}
      </div>
    );
  };

  const onCancel = () => {
    dispatch({ type: ActionType.setNextHierarchyTrees, payload: hierarchyTrees });
    dispatch({ type: ActionType.setChanged, payload: false });
    dispatch({ type: ActionType.setActiveKey, payload: "0" });
    dispatch({ type: ActionType.setParameters, payload: { siteHierarchyTitleTranslations } });
  };

  const parsedActiveKey = parseInt(activeKey, 10);

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={intl.formatMessage({ id: "hierarchy_tree.header" })}
        subTitle={<LocalesSwitcher inline />}
        extra={hasRole(group, ["synchronize"]) && (
          <div className={styles.synchronizeWrapper}>
            <Popconfirm
              title={intl.formatMessage({ id: "synchronization.global_confirm" })}
              onConfirm={() => onGlobalSynchronization()}
              okText={intl.formatMessage({ id: "words.ok" })}
              cancelText={intl.formatMessage({ id: "words.cancel" })}
              placement="bottomRight"
            >
              <Button
                icon={<SyncOutlined />}
              >
                {intl.formatMessage({ id: "synchronization.global" })}
              </Button>
            </Popconfirm>
          </div>
        )}
      />
      <Card style={{ position: "relative" }} className={styles.hierarchyFormWrapper}>
        {nextHierarchyTrees && nextHierarchyTrees.length > 0 && (
          <HierarchyBranchForm
            hierarchyBranch={currentBranch}
            hierarchyTree={nextHierarchyTrees[parsedActiveKey]}
            open={editBranchVisible}
            errors={tabErrors(nextHierarchyTrees[parsedActiveKey], parsedActiveKey)}
            onTreeChange={(values) => onChange(values, parsedActiveKey)}
            group={group}
            onClose={() => {
              dispatch({ type: ActionType.setEditBranchVisible, payload: false });
              dispatch({ type: ActionType.setCurrentBranch, payload: {} });
            }}
            locale={locale}
          />
        )}
        <Tabs
          className={styles.tabsWrapper}
          animated={false}
          defaultActiveKey="0"
          activeKey={activeKey}
          onChange={onTabChange}
          type="card"
        >
          {nextHierarchyTrees && nextHierarchyTrees.length > 0 && nextHierarchyTrees.map((ht, i) => (
            <TabPane
              tab={tabTitle(ht, i)}
              className={styles.siteLabel}
              key={i.toString()}
            >
              <HierarchyTreeTabContent
                hierarchyTree={ht}
                onChange={onChange}
                treeErrors={tabErrors(ht, i)}
                index={i}
                key={`hierarchy_tree_${i}`}
                onEditBranch={(currentBranch) => onEditBranch(currentBranch)}
                locale={locale}
                applications={applications}
                group={group}
              />
            </TabPane>
          ))}
          { hasRole(group, ["hierarchy_create"]) && (
            <TabPane
              tab={(
                <div>
                  <PlusOutlined />
                  {intl.formatMessage({ id: "activerecord.attributes.hierarchies.add_hierarchy" })}
                </div>
              )}
              key="newTab"
            />
          )}
        </Tabs>
        <div className={styles.siteLabel}>
          <AntForm
            style={{ width: "400px" }}
            onChange={(value) => onParameterChange(value)}
            object={parameters}
            layout="vertical"
            locale={locale}
            schema={[{
              name: "siteHierarchyTitleTranslations",
              label: intl.formatMessage({ id: "activerecord.attributes.tenant.site_hierarchy_title" }),
              input: {
                type: "string",
                localize: true,
                inputProps: {
                  disabled: !hasRole(group, ["hierarchy_update", "hierarchy_create"]),
                }
              }
            }]}
          />
        </div>
        <div className={styles.saveWrapper}>
          {changed && (
            <Button
              style={{ marginRight: "10px" }}
              onClick={() => onCancel()}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
          )}
          <Button
            type="primary"
            disabled={!changed}
            onClick={() => onSave(nextHierarchyTrees, parameters)}
          >
            <SaveOutlined />
            { intl.formatMessage({ id: "hierarchy_tree.save" })}
          </Button>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  hierarchyTrees: state.hierarchyTrees.list,
  group: state.group.current,
  errors: state.hierarchyTrees.errors,
  locale: state.locales.currentLocale,
  siteHierarchyTitleTranslations: state.hierarchyTrees.siteHierarchyTitleTranslations,
  applications: state.applications.list,
});

const mapDispatchToProps = dispatch => ({
  fetchData: () => dispatch({ type: 'hierarchyTree/fetch' }),
  onSave: (nextHierarchyTrees, parameters) => dispatch({ type: 'hierarchyTree/save', payload: { hierarchyTrees: nextHierarchyTrees, parameters } }),
  onGlobalSynchronization: () => dispatch({ type: "hierarchyTree/sync" })
});

export default connect(mapStateToProps, mapDispatchToProps)(EditHierarchyTrees);