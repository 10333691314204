import React, { FormEventHandler } from "react";
import {
  PageHeader,
  Card,
  Checkbox,
  Space,
  Alert
} from "antd";
import some from "lodash/some";
import { useIntl } from "react-intl";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import AntForm from "@9troisquarts/ant-form.ant-form";
import {
  User,
  ApplicationType,
  ApplicationSettings
} from "../../../../types";
import { getApplication, logoForApp } from "./utils";
import styles from './index.module.sass';

const canGoNext = (cxfirstAppSettings) => {
  return !some(cxfirstAppSettings, appSetting => !appSetting.streamIds || appSetting.streamIds.length === 0);
};

type Error = {
  applicationId: string;
  errors: string[];
}
type ApplicationStreamsListProps = {
  availableStreams: any;
  appSetting: ApplicationSettings;
  application: ApplicationType;
  locale: string;
  errors?: Error;
  streams: any[];
  onChange: ((values: any, allValues: any) => void) & FormEventHandler<HTMLFormElement>;
  onCheckAll: (e: CheckboxChangeEvent) => void;
}

const ApplicationStreamsList: React.FC<ApplicationStreamsListProps> = (props) => {
  const {
    availableStreams,
    application,
    appSetting,
    locale,
    errors = undefined,
    streams,
    onCheckAll,
    onChange,
  } = props;
  const intl = useIntl();

  return (
    <div
      style={{ paddingLeft: "10px" }}
    >
      <span className={`${styles.appTitle} ${errors ? 'error' : ''}`}>
        <img
          width={30}
          alt={`logo-for-${logoForApp['cxfirst']['compact']}`}
          src={logoForApp["cxfirst"]['compact']}
          style={{ marginRight: "5px", marginBottom: "5px" }}
        />
        { application.name }
      </span>
      <Card>
        {errors?.errors && (
          <Alert
            type="error"
            style={{ 
              marginBottom: 16
            }}
            description={(
              <>
                {errors?.errors.map((error, i) => (
                  <div key={`error-${i}`}>{error}</div>
                ))}
              </>
            )}
          />
        )}
        { availableStreams[appSetting.applicationId || ""].length > 0 && (
          <Checkbox
            style={{ paddingBottom: "20px" }}
            checked={appSetting.streamIds?.length === streams.length}
            onChange={onCheckAll}
          >
            { intl.formatMessage({ id: "user.wizard.select_all_stream" }) }
          </Checkbox>
        )}
        <AntForm
          object={appSetting}
          onChange={onChange}
          onSubmit={undefined}
          layout="vertical"
          schema={[{
            name: "streamIds",
            input: {
              type: "checkboxes",
              options: streams.map(stream => ({ value: stream.id, label: stream.titleTranslations[locale], colProps: { span: 16 } }))
            }
          }]}
        />
      </Card>
    </div>
  )
}

interface IProps {
  errors: any;
  editingUser: User;
  availableStreams: any;
  onChange: (values) => void;
  applications: ApplicationType[];
  hideTitle: boolean;
  locale: string;
  setNextIsAvailable: (nextIsAvailable: boolean) => any;
}

const StreamListForm: React.FC<IProps> = props => {
  const {
    editingUser,
    errors = [],
    availableStreams,
    onChange,
    applications,
    hideTitle,
    locale,
    setNextIsAvailable,
  } = props;
  const intl = useIntl();

  const cxfirstAppSetting = (editingUser.applicationSettings || []).filter(appSetting => appSetting.type === "cxfirst");

  if (canGoNext(cxfirstAppSetting)) {
    setNextIsAvailable(true);
  } else {
    setNextIsAvailable(false);
  }

  const handleChange = (values, appId) => {
    const newAppSettings = [...(editingUser.applicationSettings || [])];
    const index = newAppSettings.findIndex((appSetting => appSetting.applicationId === appId));
    const newAppSetting = { ...newAppSettings[index], ...values };

    newAppSettings[index] = newAppSetting;

    onChange({ applicationSettings: newAppSettings });
  };

  const onCheckAll = (checked, appId) => {
    const newAppSettings = [...(editingUser.applicationSettings || [])];
    const index = newAppSettings.findIndex((appSetting => appSetting.applicationId === appId));
    let newStreamIds = [];
    if (checked) {
      newStreamIds = availableStreams[appId || ""].map(stream => stream.id);
    }
    const newAppSetting = { ...newAppSettings[index], streamIds: newStreamIds};
    newAppSettings[index] = newAppSetting;

    onChange({ applicationSettings: newAppSettings });
  };

  return (
    <div>
      {!hideTitle && (
        <PageHeader
          title={intl.formatMessage({ id: "user.wizard.step.streams" })}
          key="stream-list-form"
          style={{ paddingLeft: "0px" }}
        />
      )}
      { cxfirstAppSetting.filter(appSetting => (!appSetting._destroy && appSetting.editable)).map((appSetting, i) => (
        <ApplicationStreamsList
          key={`stream-list-form${i}`}
          appSetting={appSetting}
          errors={(errors || []).find(error => error.applicationId === appSetting.applicationId)}
          availableStreams={availableStreams}
          streams={availableStreams[appSetting.applicationId || ""] || []}
          application={getApplication(applications, appSetting.applicationId)}
          locale={locale}
          onChange={(values) => handleChange(values, appSetting.applicationId)}
          onCheckAll={(e) => onCheckAll(e.target.checked, appSetting.applicationId)}
        />
      ))}
    </div>
  );
};

export default StreamListForm;