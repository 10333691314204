import { useEffect, useState, useCallback } from "react";

import {
  isEmpty,
} from "lodash";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Tracker from '@openreplay/tracker';

import { isDevelopment } from "./config";
import { getUserDomainType } from '../react/utils/userHelper';

const identifyUserOpenReplay = (sessionLogger, user) => {
  sessionLogger.setUserID(user?.current.email);
};

const initOpenReplay = () => {
  const tracker = new Tracker({
    // @ts-ignore
    projectKey: process.env.OPENREPLAY_PROJECT_KEY,
    // @ts-ignore
    ingestPoint: process.env.OPENREPLAY_INGEST_POINT,
    __DISABLE_SECURE_MODE: isDevelopment,
  });
  // tracker.start();
  // tracker.setMetadata('environment', process.env.NODE_ENV || 'unset');
  // tracker.setUserID(props?.user?.current.email);

  tracker.start();
  tracker.setMetadata('environment', process.env.NODE_ENV || 'unset');

  return {
    rawSessionLoggerObj: tracker,
    identifyUser: (user) => identifyUserOpenReplay(tracker, user),
    useSessionUrl: () => null,
    setSessionUrlAt: (url:string, at = null) => null,
  };
};

const identifyUserLogRocket = (sessionLogger, user) => {
  sessionLogger?.identify(user?.email, {
    name: `${user?.firstname} ${user?.lastname}`,
    email: user?.email,
  });
};

// Call init as early on the page as possible. Some recording data may be lost if it is called too late
const initLogRocket = ({ currentUser, currentTenant }) => {
  const allowedUsers = process.env.SESSION_LOGGER_ALLOWED_USER_DOMAIN_TYPES?.split(";") || [];
  const userDomainType = getUserDomainType(currentUser?.email);
  const performAction = (userDomainType && allowedUsers.includes(userDomainType));

  if (!isDevelopment) {
    if (performAction) {
      // @ts-ignore
      LogRocket.init(process.env.LOGROCKET_APP_NAME, {
        rootHostname: `${process.env.APP_DOMAIN_NAME}`,
      });
      setupLogRocketReact(LogRocket);
    }
  }

  return {
    sessionLogger: LogRocket,
  };
};

const useSessionUrl = (sessionLogger) => {
  const [currentSessionUrl, setCurrentSessionUrl] = useState<string | null>(null);

  sessionLogger?.getSessionURL(sessionURL => {
    const parts = sessionURL.split('?'); // remove all parameters like timestamp
    let url = parts[0];

    const previousSessionUrl = window.localStorage.getItem("sessionLoggerUrl");
    if (currentSessionUrl && (previousSessionUrl === url)) {
      return;
    }

    if (!url || url?.length === 0) {
      url = null;
    }

    window.localStorage.setItem("sessionLoggerUrl", url);
    setCurrentSessionUrl(sessionURL);
  });

  return {
    sessionUrl: currentSessionUrl,
  };
};

// Call init as early on the page as possible. Some recording data may be lost if it is called too late
const useLogRocket = ({ sessionLogger, currentUser, currentTenant }) => ({
  sessionLogger,
  useSessionUrl: () => useSessionUrl(sessionLogger),
  identifyUser: (user) => identifyUserLogRocket(sessionLogger, user),
});

export const initSessionLogger = initLogRocket;
export default useLogRocket;