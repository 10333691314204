import { camelize, decamelizeKeys, camelizeKeys } from "humps";
import isEmpty from "lodash/isEmpty";
import castAttributesFromModel from "./castAttributesFromDefinition";
import client from "./client";

export const extractResource = responseData => {
  const { data } = responseData;
  return camelizeKeys(data);
};

export const throwResource = ({ response }) => {
  throw camelizeKeys(response.data.data);
};

export const extractResourceFromResourceKey = (
  resourceName,
  options = {},
) => responseData => {
  const { data } = responseData.data;
  const { defaultData } = options;
  const camelizedData = camelizeKeys(data);
  if (camelizedData && camelizedData[camelize(resourceName)]) return camelizeKeys(camelizedData[camelize(resourceName)]);
  return defaultData || {};
};

export const search = (url, resourceName) => async function (params = {}) {
  return client
    .get(url, {
      params,
    })
    .then(extractResourceFromResourceKey(resourceName, { defaultData: [] }))
    .catch(throwResource);
};

const all = url => async function (params = {}) {
  return client
    .get(url, {
      params: decamelizeKeys(params),
    })
    .then(extractResource)
    .catch(throwResource);
};

const find = (url, resourceName) => async function (id, params = null) {
  return client
    .get(`${url}/${id}`, {
      params: decamelizeKeys(params),
    })
    .then(extractResourceFromResourceKey(resourceName))
    .catch(throwResource);
};

const save = (url, resourceName, definition) => async function ({ id, attributes, params = {} }) {
  const castedAttributes = definition
    ? castAttributesFromModel(definition, attributes)
    : attributes;
  let promise = null;
  if (id) {
    promise = client.put(`${url}/${id}`, {
      [resourceName]: decamelizeKeys(castedAttributes),
      ...params,
    });
  } else {
    promise = client.post(`${url}`, {
      [resourceName]: decamelizeKeys(castedAttributes),
      ...params,
    });
  }
  return promise.then(extractResource).catch(throwResource);
};

const destroy = url => async function ({ id }) {
  return client.delete(`${url}/${id}`).then(extractResource);
};

const RestApi = options => {
  const {
    resourceName, pluralName, prefix = "/", definition = null,
  } = options;

  // if (isEmpty(definition)) console.warn(`definition is not provided for ${resourceName}`);

  const plural = pluralName || `${resourceName}s`;
  const url = `${prefix}${plural}`;
  return {
    all: all(url, plural),
    search: search(url, plural),
    find: find(url, resourceName),
    save: save(url, resourceName, definition),
    destroy: destroy(url, resourceName),
  };
};

export default RestApi;
