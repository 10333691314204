import { AntForm, AntSchema } from "@9troisquarts/ant-form.ant-form";
import { Button } from "antd";
import React, { useState } from "react";
import { Group, HierarchyTitleType } from "../../../../types";
import { hasRole } from "../../../../utils/Authorization";
import useI18n from "../../../../utils/useI18n";
import { useHierarchyTitleMutation } from "../../useHierarchyTitle";
import styles from "./index.module.sass";

interface HierarchyTitleFormProps {
  hierarchyTitle: HierarchyTitleType;
  branches: {withoutBranch?: boolean; slug: string; title: string }[];
  onCancel: () => void;
  group: Group;
}

const schema = (entity, group, branches, t) => {
  const defaultSchema: AntSchema = [{
    name: "branchSlug",
    label: t("activerecord.attributes.hierarchy_title.branch_slug"),
    input: {
      inputProps: {
        disabled: entity.persisted,
      },
      type: "select",
      options: branches.map(b => ({ value: b.slug, label: b.title }))
    }
  }];

  if ((hasRole(group, ["entity_code_update"]) || (!entity.persisted && hasRole(group, ["entity_create"])))) {
    defaultSchema.push({
      name: "code",
      label: t("activerecord.attributes.hierarchy_title.code"),
      input: {
        type: "string"
      }
    });
  }

  if (hasRole(group, ["entity_title_update"]) || (!entity.persisted && hasRole(group, ["entity_create"]))) {
    defaultSchema.push({
      name: "title",
      label: t("activerecord.attributes.hierarchy_title.title"),
      input: {
        type: "string"
      }
    });
  }

  return defaultSchema;
};

const HierarchyTitleForm: React.FC<HierarchyTitleFormProps> = props => {
  const {
    hierarchyTitle,
    branches,
    onCancel,
    group,
  } = props;
  const { t } = useI18n();
  const [editingHierarchyTitle, setEditingHierarchyTitle] = useState<HierarchyTitleType>(hierarchyTitle);

  const {
    hierarchyTitleMutation,
    errors,
  } = useHierarchyTitleMutation(onCancel);

  const onSubmit = (hierarchyTitle) => {
    hierarchyTitleMutation.mutate(hierarchyTitle);
  };

  return (
    <>
      <AntForm
        object={editingHierarchyTitle}
        onChange={(values) => setEditingHierarchyTitle({ ...editingHierarchyTitle, ...values })}
        schema={schema(editingHierarchyTitle, group, branches, t)}
        layout="vertical"
        errors={errors}
        onSubmit={undefined}
      />
      <div className={styles.footerWrapper}>
        <div>
          <Button onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            type="primary"
            onClick={() => onSubmit(editingHierarchyTitle)}
            loading={hierarchyTitleMutation.isLoading}
          >
            {t("save")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default HierarchyTitleForm;