import React from "react";
import {
  Dropdown,
  Menu
} from "antd";
import { useIntl } from "react-intl";
import { CaretDownOutlined } from "@ant-design/icons";
import { Tenant } from "../types";
import styles from "./index.module.sass";
import RunningExport from "../components/RunningExport";

interface IProps {
  tenant: Tenant;
  user: any;
}

const actionUserMenu = (intl) => (
  <Menu>
    <Menu.Item>
      <a
        className="item"
        href="/profile/edit"
        target="_blank"
        role="option"
      >
        {intl.formatMessage({ id: "profile.edit" })}
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        className="item"
        href="/users/sign_out"
        data-method="delete"
        role="option"
      >
        {intl.formatMessage({ id: "session.log_out" })}
      </a>
    </Menu.Item>
  </Menu>
);

const Header: React.FC<IProps> = props => {
  const {
    tenant,
    user,
  } = props;

  const intl = useIntl();

  return (
    <div className={styles.header}>
      <div
        className={styles.tenant}
        onClick={() => { window.location.href = '/'; }}
      >
        <img
          style={{ width: "150px" }}
          src={tenant.logo}
          alt="tenant-logo"
        />
        &nbsp;
        <h3
          style={{ fontSize: "16px", marginBottom: 0 }}
        >
          {tenant.title}
        </h3>
      </div>
      <div className={styles.userAction}>
        <RunningExport />
        <Dropdown
          trigger={['click']}
          overlay={actionUserMenu(intl)}
          //@ts-ignore
          getPopupContainer={() => document.getElementById("layout-network-manager")}
        >
          <div className={styles.userDropdown}>
            <img
              alt="avatar"
              src={user.avatar ? user.avatar : "/default_avatar.png"}
              className={styles.userAvatar}
            />
            <span
              className={styles.userName}
            >
              {`${user.firstname} ${user.lastname}`}
            </span>
            <CaretDownOutlined style={{ marginLeft: "5px" }} />
          </div>
        </Dropdown>
      </div>
    </div>
  )
};

export default Header;