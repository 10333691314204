import React from "react";
import { Radio } from "antd";
import { useIntl } from "react-intl";
import style from "../index.module.sass";

interface IProps {
  onChange: (value) => void;
  mailFrequency: string;
}

const EditMailFrequency: React.FC<IProps> = props => {
  const {
    onChange,
    mailFrequency,
  } = props;

	const intl = useIntl();

  return (
    <div style={{ paddingTop: "60px" }}>
      <p>{ intl.formatMessage({ id: 'activerecord.attributes.user.application_setting.notifications.choose_mail_frequency' }) }</p>
      <Radio.Group
        onChange={(e) => onChange(e.target.value)}
        name="radiogroup"
        style={{ lineHeight: "30px" }}
        value={mailFrequency}
        defaultValue="none"
        className={style.radioWrapper}
      >
        <Radio value="none" key="none">
          <span style={{ fontWeight: 600 }}>
            { intl.formatMessage({ id: 'activerecord.attributes.user.application_setting.notifications.mail_frequency.never' }) }
          </span>
          &nbsp;
          -
          &nbsp;
          <span style={{ fontStyle: "italic", fontSize: "12px" }}>
            { intl.formatMessage({ id: 'activerecord.attributes.user.application_setting.notifications.mail_frequency.never_description' }) }
          </span>
        </Radio>
        <Radio value="weekly" key="weekly">
          <span style={{ fontWeight: 600 }}>
            { intl.formatMessage({ id: 'activerecord.attributes.user.application_setting.notifications.mail_frequency.weekly'}) }
          </span>
          &nbsp;
          -
          &nbsp;
          <span style={{ fontStyle: "italic", fontSize: "12px" }}>
            { intl.formatMessage({ id: 'activerecord.attributes.user.application_setting.notifications.mail_frequency.weekly_description'}) }
          </span>
        </Radio>
        <Radio value="daily" key="daily">
          <span style={{ fontWeight: 600 }}>
            { intl.formatMessage({ id: 'activerecord.attributes.user.application_setting.notifications.mail_frequency.daily'}) }
          </span>
          &nbsp;
          -
          &nbsp;
          <span style={{ fontStyle: "italic", fontSize: "12px" }}>
            { intl.formatMessage({ id: 'activerecord.attributes.user.application_setting.notifications.mail_frequency.daily_description'}) }
          </span>
        </Radio>
        <Radio value="instantly" key="instantly">
          <span style={{ fontWeight: 600 }}>
            { intl.formatMessage({ id: 'activerecord.attributes.user.application_setting.notifications.mail_frequency.instantly'}) }
          </span>
          &nbsp;
          -
          &nbsp;
          <span style={{ fontStyle: "italic", fontSize: "12px" }}>
            { intl.formatMessage({ id: 'activerecord.attributes.user.application_setting.notifications.mail_frequency.instantly_description'}) }
          </span>
        </Radio>
      </Radio.Group>
    </div>
  );
};

export default EditMailFrequency;