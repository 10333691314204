import React from "react";
import { connect } from "react-redux";
import { useCurrentUser } from "./utils";
import UserEdit from ".";

const UserEditContainer = props => {
  const {
    group,
    tenant,
    visible,
    editingUser,
    errors,
    wizardSteps,
    applications,
    editParams,
    editLoading,
    saveLoading,
    onEdit,
    onEditCancel,
    onSubmit,
    hierarchyTrees,
  } = props;
  const currentUser = useCurrentUser();
  if (!currentUser) return null;
  if (!visible) return null;

  return (
    <UserEdit
      key="user-editing-modal"
      hierarchyTrees={hierarchyTrees}
      editLoading={editLoading}
      visible={visible}
      onCancel={onEditCancel}
      user={editingUser}
      group={group}
      wizardSteps={wizardSteps}
      applications={applications}
      editParams={editParams}
      onSubmit={onSubmit}
      errors={errors}
      onEdit={onEdit}
      currentUser={currentUser}
      onDelete={() => undefined}
      saveLoading={saveLoading}
      tenantLocales={tenant.availableLocales}
      hasApiKey={tenant.hasApiKey}
      authProviders={tenant.authProviders}
    />
  );
};

const mapStateToProps = state => ({
  group: state.group.current,
  tenant: state.tenants.current,
  visible: state.users.open,
  editingUser: state.users.current,
  errors: state.users.errors,
  wizardSteps: state.users.wizardSteps,
  applications: state.applications.list,
  editParams: state.users.editParams,
  editLoading: state.users.loading,
  hierarchyTrees: state.hierarchyTrees.list,
  saveLoading: state.users.saveLoading,
});

const mapDispatchToProps = dispatch => ({
  onEditCancel: () => dispatch({ type: "user/cancelEdit" }),
  onEdit: (id?: string) => dispatch({ type: "user/show", payload: { id } }),
  onSubmit: (editingUser) => {
    dispatch({ type: "user/save", payload: editingUser });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(UserEditContainer);