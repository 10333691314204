import { isArray, isObject } from "lodash";

export default function toNestedParameters(object, options = {}) {
  options = { excluded: [], included: [], ...options };
  const result = {};
  let key;
  for (key in object) {
    if (
      (isArray(object[key]) && !options.excluded.includes(key))
      || options.included.includes(key)
    ) {
      const rootkey = key;
      if (isArray(object[key])) {
        result[`${rootkey}_attributes`] = object[key].map(item => toNestedParameters(item, options));
      } else if (isObject(object[key])) {
        result[`${rootkey}_attributes`] = toNestedParameters(
          object[key],
          options,
        );
      }
    } else {
      result[key] = object[key];
    }
  }
  return result;
}
