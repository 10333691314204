import React from "react";
import { useIntl } from "react-intl";
import style from "./index.module.sass";
import accessdenied from "../../../../public/images/accessdenied.png";
import TranslationProvider from '../utils/TranslationProvider';

interface IProps {
  keyMessage: string;
}

const AccessDenied: React.FC<IProps> = (props) => {
  const { keyMessage } = props;
  const intl = useIntl();

  return (
    <div className={style.errorMessageWrapper}>
      <img src={accessdenied} alt="logo" className={style.imageAccessDenied} />
      <span className={style.catchPhrase}>{intl.formatMessage({ id: "errors.access_denied.catch_phrase" })}</span>
      <span className={style.subCatchPhrase}>{intl.formatMessage({ id: keyMessage ? `errors.access_denied.${keyMessage}` : "errors.access_denied.sub_catch_phrase" })}</span>
    </div>
  );
};

export default TranslationProvider(AccessDenied);