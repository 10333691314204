import React, { useState } from "react";
import {
  AntForm,
  AntSchema
} from "@9troisquarts/ant-form.ant-form";
import {
  Table,
  Button,
  Popconfirm
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Group } from "../../../../types";
import useI18n from "../../../../utils/useI18n";
import styles from "./index.module.sass";
import { hasRole } from "../../../../utils/Authorization";
import { useHierarchyTitles, useHierarchyTitleDeleteAll, useHierarchyTitleDelete } from "../../useHierarchyTitle";
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import DangerZone from "../../../../components/DangerZone";

interface HierarchyTitleListProps {
  branch: { slug: string; title: string; withoutBranch: boolean };
  onEdit: (id?: string) => void;
  group: Group;
}

const schema = (t): AntSchema => [[{
  name: "code",
  label: t("activerecord.attributes.hierarchy_title.code"),
  input: {
    type: "string",
    inputProps: {
      style: { width: "400px" }
    }
  }
}, {
  name: "title",
  label: t("activerecord.attributes.hierarchy_title.title"),
  colProps: {
    span: 4
  },
  input: {
    type: "string",
  }
}, {
  name: "withoutSite",
  input: {
    text: t("hierarchy_title.search.without_site"),
    type: "checkbox",
  },
  colProps: {
    style: { paddingTop: 30 },
    span: 4
  },
}]];

export const HierarchyTitleListTab: React.FC<HierarchyTitleListProps> = props => {
  const {
    branch,
    onEdit,
    group,
  } = props;

  const { t } = useI18n();

  const hierarchyTitleDeleteAll = useHierarchyTitleDeleteAll();

  const hierarchyTitleDelete = useHierarchyTitleDelete();

  const onDelete = (id) => {
    hierarchyTitleDelete.mutate(id);
  };

  const [search, setSearch] = useState<any>({ branchSlug: branch.slug });
  const { hierarchyTitles, pagination, isLoading } = useHierarchyTitles(search);

  let currentPagination = {};
  if (pagination?.total && pagination?.pageSize) {
    currentPagination = {

      pageSize: pagination.pageSize,
      onChange: (page: number, pageSize: number) => setSearch({ ...search, page, pageSize }),
      total: pagination.total * pagination.pageSize,
      pageSizeOptions: ["15", "30", "50", "100"],
      showSizeChanger: true,
      onShowSizeChange: (page, pageSize) => setSearch({ ...search, page, pageSize }),
    };
  }

  return (
    <div key={`branch-tab-${branch.slug}`}>
      <div className={styles.searchForm} key="search-form">
        <AntForm
          object={search}
          onChange={(values) => setSearch({ ...search, ...values })}
          onSubmit={undefined}
          schema={schema(t)}
          layout="vertical"
        />
      </div>
      <Table
        dataSource={hierarchyTitles}
        columns={[{
          dataIndex: "code",
          title: t("activerecord.attributes.hierarchy_title.code")
        }, {
          dataIndex: "title",
          title: t("activerecord.attributes.hierarchy_title.title")
        }, {
          dataIndex: "siteCount",
          title: "Nombre de site",
          align: "right",
        }, {
          align: "right",
          render: (text, record) => (
            <>
              {hasRole(group, ["entity_title_update", "entity_code_update"]) && (
                <EditOutlined onClick={() => onEdit(record.id)} />
              )}
              {hasRole(group, ["entity_delete"]) && (
                <Popconfirm
                  placement="topRight"
                  onConfirm={() => onDelete(record.id)}
                  title={t("hierarchy_title.delete_confirm")}
                >
                  <DeleteOutlined style={{ color: "red", marginLeft: 5 }} />
                </Popconfirm>
              )}
            </>
          )
        }]}
        pagination={currentPagination}
        loading={isLoading}
      />
      {hasRole(group, ["entity_delete_all"]) && ((search.withoutSite && hierarchyTitles && hierarchyTitles.length > 0) || branch.withoutBranch) && (
        <DangerZone
          items={[
            {
              active: true,
              title: t("hierarchy_title.delete_all"),
              description: t("hierarchy_title.delete_all_description"),
              action: (<DeleteData
                title={t("words.delete")}
                onDelete={() => hierarchyTitleDeleteAll.mutate({ branchSlug: branch.slug, withoutBranch: branch.withoutBranch })}
                warningMessage={t("hierarchy_title.delete_all_description")}
              />),
            },
          ]}
        />
      )}
    </div>
  );
};

const DeleteData = props => {
  const { onDelete, warningMessage, title } = props;

  const [open, setOpen] = useState(false);
  const { t } = useI18n();

  return (
    <>
      <Button danger onClick={() => setOpen(!open)}>
        {` ${t("words.delete")} `}
      </Button>
      <ConfirmationModal
        title={title}
        open={open}
        onOk={onDelete}
        onCancel={() => setOpen(false)}
        content={warningMessage}
        keyPhrase="supprimer"
      />
    </>
  );
};

export default HierarchyTitleListTab;