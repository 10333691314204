//@ts-nocheck
import { useEffect, useRef } from "react";
//import { isDevelopment } from "../../packs/config";

function useInterval(callback, delay) {
  const savedCallback = useRef();
  //if (isDevelopment) return undefined;

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      if (document.hasFocus()) savedCallback.current();
    }
    savedCallback.current();
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;
