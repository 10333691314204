//@ts-nocheck
import createImmerReducer from "../lib/createReducer";
import ExportFileService from "../services/exportFile";
import { ExportFile } from "../types";
import clone from "lodash/clone"
import { all, call, select, put, takeLatest, takeLeading } from "@redux-saga/core/effects";

type ExportFileStateType = {
  list: ExportFile[];
  loading: boolean;
  open: boolean;
};

const initialState: ExportFileStateType = {
  list: [],
  open: false,
  loading: false,
};

const exportFileReducer = createImmerReducer<ExportFileStateType>(initialState, {
  "exportFiles/setList": (state, { payload }) => {
    state.list = payload;
    return state;
  },
  "exportFiles/setOpen": (state, { payload }) => {
    state.open = payload;
    return state;
  },
  "exportFiles/setLoading": (state, { payload }) => {
    state.loading = payload;
    return state;
  },
});

function* fetchExportFiles() {
  yield put({ type: 'exportFiles/setLoading', payload: true });
  try {
    const data = yield call(ExportFileService.fetch);
    yield put({ type: "exportFiles/setList", payload: data.exportFiles });
  } catch(e) {
    console.error(e);
  }

  yield put({ type: 'exportFiles/setLoading', payload: false });
}

function* setDownloadedFile({ payload }: any) {
  const downloadedFile = clone(payload);

  const list = yield select(state => state.exportFiles.list);
  const newList = [...list];
  const index = newList.findIndex(exportFile => exportFile.id === downloadedFile.id);
  downloadedFile.downloadedAt = Math.floor(Date.now());

  newList[index] = downloadedFile;

  yield put({ type: "exportFiles/setList", payload: newList });
  ExportFileService.download(downloadedFile.id);
}

function* addExportFile({ payload }: any) {
  const list = yield select(state => state.exportFiles.list);
  const newList = [payload, ...list];
  yield put({ type: "exportFiles/setList", payload: newList });
  yield put({ type: "exportFiles/setOpen", payload: true });
}

export function* exportFileSaga() {
  yield all([
    takeLeading('exportFiles/fetch', fetchExportFiles),
    takeLeading('exportFiles/setDownloadedFile', setDownloadedFile),
    takeLeading('exportFiles/addToList', addExportFile),
  ]);
}

export default exportFileReducer;