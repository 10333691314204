import React, { useState } from 'react';
import {
  Button,
  Table,
} from 'antd';
import AntForm, { AntSchema } from "@9troisquarts/ant-form.ant-form";
import { useIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';
import { columns } from './columns';
import styles from './index.module.sass';
import memoOnlyForKeys from "../../../utils/memoOnlyForKeys";
import client from '../../../utils/client';
import { Group, Site } from '../../../types';
import useDocumentKeypress from '../../../utils/useDocumentKeypress';
import { useMutation } from 'react-query';
import { synchronizeSite } from '../../../services/site';

const searchSchema = (branches): AntSchema => {
  const schema: AntSchema = [
    [{
      name: "codeTitle",
      label: "Code ou Titre",
      input: {
        type: "string"
      },
      colProps: {
        xs: 16,
        sm: 4
      }
    },
    {
      name: 'syncedEq',
      label: 'Etat de synchronisation',
      input: {
        type: 'select',
        inputProps: {
          allowClear: true,
        },
        options: [
          {
            value: null,
            label: 'Tous'
          },
          {
            value: true,
            label: 'Synchronisé'
          },
          {
            value: false,
            label: 'Non synchronisé'
          }
        ]
      },
      colProps: {
        xs: 16,
        sm: 4
      }
    }]
  ];
  const branchFilter: AntSchema = []

  branches.forEach(k => {
    branchFilter.push({
      label: k.title,
      name: k.slug,
      colProps: {
        span: 4
      },
      input: {
        //@ts-ignore
        type: 'reactSelect',
        loadOptions: fetchOptions(k.slug),
        inputProps: {
          //@ts-ignore
          menuPortalTarget: document.body,
          menuPlacement: "bottom",
          styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
          isMulti: true,
        },
        options: {
          //@ts-ignore
          optionLabel: 'label',
          optionValue: 'value',
        }
      }
    });
  });

  schema.push(branchFilter);
  return schema;
};

const fetchOptions = (branchCode) => (search) => {
  return client
    .get(`/hierarchy_trees/codes`, {
      params: {
        search,
        branch: branchCode,
      },
    })
    .then((response) => response);
};
interface Props {
  sites: Site[];
  loading: boolean;
  pagination: any;
  fetchData: (page: number, pageSize: number, search: any) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  branches: {slug: string; title: string}[];
  group: Group;
}

const SiteList: React.FC<Props> = props => {
  const {
    sites,
    loading,
    pagination,
    fetchData,
    onEdit,
    onDelete,
    branches,
    group,
  } = props;
  const intl = useIntl();
  const { total, pageSize } = pagination;

  const [search, setSearch] = useState({});

  const onSearch = () => {
    const newSearch = { ...search };
    branches.forEach(branch => {
      if (search[branch.slug]) {
        newSearch[branch.slug] = search[branch.slug].map(option => option.value);
      }
    });
    fetchData(1, 1, newSearch);
  };

  const synchronizeMutation = useMutation({ mutationFn: synchronizeSite })

  useDocumentKeypress({
    callback: onSearch,
    key: "Enter",
    ctrlKey: false,
    enabled: true,
    deps: [search],
  });


  const listColumns = columns(branches, group, intl, { onEdit, onSynchronize: synchronizeMutation.mutate, onDelete });
  let listWidth = 0;
  listColumns.forEach(col => {
    //@ts-ignore
    listWidth += col.width;
  });

  return (
    <>
      <div className={styles.searchForm} key="search-form">
        <AntForm
          key="ant-form-search"
          object={search}
          layout="vertical"
          onChange={(value) => setSearch({ ...search, ...value })}
          schema={searchSchema(branches)}
        />
        <div
          className={styles.searchButton}
          key="search-button"
        >
          <Button
            key="search-button"
            type="primary"
            onClick={onSearch}
            icon={<SearchOutlined />}
          >
            {intl.formatMessage({ id: "words.search" })}
          </Button>
        </div>
      </div>
      <Table
        key="site-table"
        dataSource={sites || []}
        loading={loading}
        size="small"
        columns={listColumns}
        sticky={{ offsetHeader: 80 }}
        rowKey={(record: any, index: number) => `site-${index}`}
        scroll={{ x: listWidth }}
        pagination={{
          onChange: (page: number, pageSize: number) => fetchData(page, pageSize, search),
          total: total * pageSize,
          pageSizeOptions: ["15", "30", "50", "100"],
          showSizeChanger: true,
          pageSize,
          onShowSizeChange: (page, pageSize) => fetchData(page, pageSize, search),
        }}
      />
    </>
  );
};

export default React.memo(SiteList, memoOnlyForKeys(['sites', 'loading', 'ongoingIndexation', 'branches']));
