import { ApplicationType as ApplicationType } from "../types";
import client from "../utils/client";

const fetch = async () => {
  const response: { applications: ApplicationType[] } = await client.get('/applications.json');
  return response;
};

const fetchOne = async (id) => {
  let response;
  if (id) {
    response = await client.get(`/applications/${id}.json`);
  } else {
    response = await client.get(`/applications/new.json`);
  }
  return response;
};

const save = async (params) => {
  let response;
  if (params.application.id) {
    response = await client.put(`/applications/${params.application.id}`, { application: params.application });
  } else {
    response = await client.post(`/applications`, { application: params.application });
  }
  return response;
};

const Application = {
  fetch,
  fetchOne,
  save,
};

export default Application;