import React from 'react'
import { useIntl } from 'react-intl'

const PasswordRules = () => {
	const intl = useIntl();
	return (
		<div>
			{intl.formatMessage({ id: "password.rules.message"})}
			<ul>
				<li>
					{intl.formatMessage({ id: "password.rules.lowercase"})}
				</li>
				<li>
					{intl.formatMessage({ id: "password.rules.uppercase"})}
				</li>
				<li>
					{intl.formatMessage({ id: "password.rules.number"})}
				</li>
				<li>
					{intl.formatMessage({ id: "password.rules.special_char"})}
				</li>
				<li>
					{intl.formatMessage({ id: "password.rules.length"})}
				</li>
			</ul>
		</div>
	)
}

export default PasswordRules