import React from "react";
import { Button, Popconfirm, Space, Tag, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  SyncOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { camelize } from "humps";
import { Group, Site } from "../../../types";
import { hasRole } from "../../../utils/Authorization";
import moment from "moment";

export const columns = (
  extraKeys: any,
  group: Group,
  intl: any,
  handlers: {
    onEdit: (id: string) => void,
    onSynchronize: (id: string) => void,
    onDelete: (id: any) => void
  }
): ColumnsType => {
  const {
    onEdit,
    onSynchronize,
    onDelete,
  } = handlers;
  const defaultColumns = [
    {
      title: intl.formatMessage({ id: "activerecord.attributes.site.code" }),
      dataIndex: "code",
      width: 100
    },
    {
      title: intl.formatMessage({ id: "activerecord.attributes.site.title" }),
      dataIndex: "title",
      width: 300
    },
    {
      title: intl.formatMessage({ id: "activerecord.attributes.site.active" }),
      dataIndex: "active",
      align: 'center' as 'center',
      width: 75,
      render: (text) => {
        if (text) {
          return <CheckCircleOutlined style={{ color: "green" }} />;
        }
        return <CloseCircleOutlined style={{ color: "red" }} />;
      }
    },
    {
      title: 'Synchro',
      dataIndex: 'synced',
      align: 'center' as 'center',
      width: 75,
      render: (synced, { syncAt, syncCheckedAt }) => {
        return (
          <Tooltip
            title={(
              synced ? (
                <span>
                  Synchronisé le: {moment(syncAt).format("DD/MM/YYYY HH:mm")}
                </span>
              ) : (
                <span>
                  Synchronisation vérifiée le: {moment(syncCheckedAt).format("DD/MM/YYYY HH:mm")}
                </span>
              )
            )}
          >
            {synced ? (
              <CheckCircleOutlined style={{ color: "green" }} />
            ) : (
              <>
                {synced === undefined || synced === null ? (
                  <QuestionCircleOutlined style={{ color: "orange" }} />
                ) : (
                  <CloseCircleOutlined style={{ color: "red" }} />
                )}
              </>
            )}
          </Tooltip>
        )
      }
    }
  ];

  const columns: ColumnsType<Site> = defaultColumns;
  extraKeys.forEach(k => {
    columns.push({
      title: k.title,
      align: 'center',
      dataIndex: camelize(k.slug),
      width: 200,
      render: (text) => (text || []).map((t, i) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tag
            key={`${t}-${i}`}
            style={{
              width: "fit-content",
              display: "block",
              whiteSpace: "normal",
              wordBreak: "break-all"
            }}
          >
            {t}
          </Tag>
        </div>
      )),
    });
  });

  if (hasRole(group, ["site_code_update", "site_title_update", "site_deactivate", "site_branch_update", "site_delete"])) {
    columns.push({
      title: "",
      align: "right",
      fixed: 'right',
      width: 125,
      render: (text, { id }) => ((
        <Space size={8}>
          {hasRole(group, ["site_code_update", "site_title_update", "site_deactivate", "site_branch_update"]) && (
            <Tooltip title={'Synchroniser'}>
              <Button type="text" size="small" icon={<SyncOutlined />} onClick={() => onSynchronize(id)} />
            </Tooltip>
          )}
          {hasRole(group, ["site_code_update", "site_title_update", "site_deactivate", "site_branch_update"]) && (
            <Tooltip title={'Editer'}>
              <Button type="text" size="small" icon={<EditOutlined />} onClick={() => onEdit(id)} /> 
            </Tooltip>
          )}
          {hasRole(group, ["site_delete"]) && (
            <Popconfirm
              placement="topRight"
              onConfirm={() => onDelete({ id })}
              title={intl.formatMessage({ id: "sites.delete_confirm" })}
            >
              <Button type="text" size="small" icon={<DeleteOutlined />} danger />
            </Popconfirm>
          )}
        </Space>
      ))
    });
  }

  return columns;
};

export default columns;