import { useIntl } from "react-intl";

const useI18n = () => {
  const intl = useIntl();
  const t = (key, params = {}) => intl.formatMessage({ id: key }, params);
  return {
    t,
    translate: t,
  };
};

export default useI18n;