import { useEffect, useState } from "react";

import {
  isEmpty,
} from "lodash";

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Tracker from '@openreplay/tracker';

import { isDevelopment, getEnvFromUrl } from "./config";

import useScript from "../react/utils/useScript";
import { getUserDomainType } from "../react/utils/userHelper";

const useIntercomLiveChat = ({ currentUser, currentTenant, hiddenOnLoad = false }) => {
  const allowedUsers = process.env.SUPPORT_CHAT_ALLOWED_USER_DOMAIN_TYPES?.split(";") || [];
  const userDomainType = getUserDomainType(currentUser?.email);
  const performAction = (userDomainType && allowedUsers.includes(userDomainType));

  // user must be identified by id not email to find session logger based on the id (which is different across all subdomains)
  // @ts-ignore
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: process.env.INTERCOM_APP_ID,
    user_id: currentUser?.email,
    email: currentUser?.email,
    name: `${currentUser?.firstname} ${currentUser?.lastname}`,
    user_hash: currentUser?.supportChatToken,
    hide_default_launcher: hiddenOnLoad,
  };

  let scriptToLoad = {};
  if (!isDevelopment) {
    if (performAction) {
      scriptToLoad = {
        content: `
          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `,
      };
    }
  }

  const {
    loading,
  } = useScript(scriptToLoad);

  return {
    loading,
    // @ts-ignore
    supportChat: window.Intercom,
    hide: () => {
      if (loading) {
        return;
      }
      // @ts-ignore
      Intercom('hide');
    },
    show: () => {
      if (loading) {
        return;
      }
      // @ts-ignore
      Intercom('show');
    },
    track: (event, params) => {
      if (loading) {
        return;
      }
      // @ts-ignore
      Intercom('trackEvent', event, params);
    },
  };
};

export default useIntercomLiveChat;