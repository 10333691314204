import { useEffect, useState } from 'react';
import { camelizeKeys } from "humps";

import useSessionLogger, { initSessionLogger } from "./useSessionLogger";
import useSupportChat from "./useSupportChat";

// Call init as early on the page as possible. Some recording data may be lost if it is called too late
export const initThirdParty = ({ currentUser, currentTenant }) => {
  const camelizedcurrentUser = camelizeKeys(currentUser);
  const camelizedcurrentTenant = camelizeKeys(currentTenant);

  const sessionLoggerInit = initSessionLogger({ currentUser: camelizedcurrentUser, currentTenant: camelizedcurrentTenant });

  return {
    ...sessionLoggerInit,
  };
};

const useThirdParty = ({ thirdParty, currentUser, currentTenant, trackSessionLoggerUrl=false }) => {
  const camelizedcurrentUser = camelizeKeys(currentUser);
  const camelizedcurrentTenant = camelizeKeys(currentTenant);

  const {
    sessionLogger,
    identifyUser: sessionLoggerIdentifyUser,
    useSessionUrl: useSessionLoggerUrl,
  } = useSessionLogger({ sessionLogger: thirdParty.sessionLogger, currentUser: camelizedcurrentUser, currentTenant: camelizedcurrentTenant });
  sessionLoggerIdentifyUser(camelizedcurrentUser);

  const {
    supportChat,
    loading: supportChatLoading,
    show: supportChatShow,
    hide: supportChatHide,
    track: supportChatTrack,
  } = useSupportChat({ currentUser: camelizedcurrentUser, currentTenant: camelizedcurrentTenant, hiddenOnLoad: false });

  const { sessionUrl: sessionLoggerUrl } = useSessionLoggerUrl();

  useEffect(() => {
    if (supportChatLoading) {
      return;
    }

    const msg = `Support chat token: ${camelizedcurrentUser?.supportChatToken}`;

    // eslint-disable-next-line no-console
    console.info(msg);
    sessionLogger.log(msg);

    if (trackSessionLoggerUrl && sessionLoggerUrl) {
      supportChatTrack('SessionLoggerUrl', { sessionLoggerUrl });
    }
  }, [supportChatLoading]);

  useEffect(() => {
    if (!trackSessionLoggerUrl || supportChatLoading) {
      return;
    }

    supportChatTrack('SessionLoggerUrl', { sessionLoggerUrl });
  }, [sessionLoggerUrl]);

  return {
    sessionLogger,
    supportChat,
  };
};

export default useThirdParty;
