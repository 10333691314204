import ReactOnRails from 'react-on-rails';
import 'antd/dist/antd.less';
import Rails from "@rails/ujs"
import App from '../react/App';
import Login from '../react/Login';
import ForgottenPassword from '../react/ForgottenPassword';
import Banner from '../react/Banner';
import Profile from '../react/Profile';
import AccessDenied from '../react/AccessDenied';
import { isDevelopment } from './config';

const images = require.context('../images', true);

// This is how react_on_rails can see the HelloWorld in the browser.
Rails.start();
ReactOnRails.register({
  App,
  Login,
  ForgottenPassword,
  Banner,
  Profile,
  AccessDenied,
});
