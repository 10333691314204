import React from "react";
import {
  Modal, Table, Button, Alert
} from "antd";
import { useIntl } from "react-intl";
import Importer from "../../../components/Importer";
import styles from "../index.module.sass";

interface IProps {
  importerOpen: boolean;
  loading: boolean;
  onImport: (file: any) => void;
  onImporterOpen: (open: boolean) => void;
  importErrors: { email: string; messages: string; application: string }[];
}

const UserImporter: React.FC<IProps> = props => {
  const {
    importerOpen,
    loading,
    onImporterOpen,
    onImport,
    importErrors,
  } = props;
  const intl = useIntl();

  return (
    <Modal
      key="users-import-modal"
      visible={importerOpen}
      onCancel={() => onImporterOpen(false)}
      title={intl.formatMessage({ id: "user.importer.title" })}
      footer={null}
      width={importErrors.length > 0 ? "60%" : undefined}
    >
      {importErrors.length > 0 && (
        <>
          <div style={{ paddingBottom: "10px" }}> {intl.formatMessage({ id: "user.importer.errors.recap" })}</div>
          <Table
            dataSource={importErrors}
            columns={[{
              title: intl.formatMessage({ id: "user.importer.columns.email" }),
              dataIndex: "email",
            }, {
              title: intl.formatMessage({ id: "user.importer.columns.errors" }),
              dataIndex: "messages",
              render: (text, { messages }) => (
                <div className={styles.errorsMessageWrapper}>
                  {messages.split('//').map(message => (
                    <div>
                      {message}
                    </div>
                  ))}
                </div>
              )
            }, {
              title: intl.formatMessage({ id: "user.importer.columns.application" }),
              dataIndex: "application"
            }, {
              title: intl.formatMessage({ id: "user.importer.columns.line" }),
              dataIndex: "line"
            }]}
          />
          <Alert message={intl.formatMessage({ id: "user.importer.refresh" })} />
          <div className={styles.importerFooter}>
            <Button onClick={() => onImporterOpen(false)}>{intl.formatMessage({ id: "words.close" }) }</Button>
          </div>
        </>
      )}
      {importErrors.length === 0 && (
        <Importer
          showSeparator={false}
          onSubmit={onImport}
          loading={loading}
        >
          <div style={{ marginTop: "15px" }}>
            <strong>Le fichier importé doit être au format XLSX</strong>
          </div>
        </Importer>
      )}
    </Modal>
  );
};

export default UserImporter;