import createImmerReducer from "../lib/createReducer";
import HierarchyTrees from "../services/hierarchyTree";
import Hierarchies from "../services/hierarchies";
//import { saveToList } from "../utils/modelsUtils";
import toNestedParameters from "../lib/NestedHelpers";
import { HierarchyTree } from "../types";
import { all, call, select, put, takeLatest } from "@redux-saga/core/effects";
// export const SET_LIST = "hierarchyTrees/setList";
// export const SET_IN_LIST = "hierarchyTrees/setInList";
// export const SET_LIST_ERRORS = "hierarchyTrees/setListErrors";
// export const SET_PERSISTED = "hierarchyTrees/setPersisted";
// };

type HierarchyTreeStateType = {
  list: HierarchyTree[];
  persisted: boolean;
  loading: boolean;
  errors: any[];
  siteHierarchyTitleTranslations?: { [key: string]: string };
}

const initialState: HierarchyTreeStateType = {
  list: [],
  persisted: false,
  loading: false,
  errors: [],
  siteHierarchyTitleTranslations: {}
};

// const setList = (state: any, { payload }: any) => state.set("list", fromJS(payload));
// const setPersisted = (state: any, { payload }: any) => state.set("persisted", fromJS(payload));

// const setInList = (state: any, { payload }: any) => {
//   if (payload.id) return saveToList(["list"])(state, { payload });
//   return state;
// };
// const setListErrors = (state: any, { payload }: any) => state.set("listErrors", fromJS(payload));

const hierarchiesReducer = createImmerReducer<HierarchyTreeStateType>(initialState, {
  // [SET_LIST]: setList,
  // [SET_IN_LIST]: setInList,
  // [SET_LIST_ERRORS]: setListErrors,
  // [SET_PERSISTED]: setPersisted,
  "hierarchyTree/setList": (state, { payload }) => {
    state.list = payload;
    return state;
  },
  "hierarchyTree/setSiteHierarchyTitle": (state, { payload }) => {
    state.siteHierarchyTitleTranslations = payload;
    return state;
  },
  "hierarchyTree/setErrors": (state, { payload }) => {
    state.errors = payload;
    return state;
  }
});

function* saveHierarchies({ payload }: any) {
  const {
    hierarchyTrees,
    parameters,
  } = payload;
  yield put({ type: 'hierarchyTree/setLoading', payload: true });
  try {
    const data = yield call(HierarchyTrees.save, hierarchyTrees.map(ht => toNestedParameters(ht, { excluded: ["applicationIds"] })), parameters);
    yield put({ type: 'hierarchyTree/setList', payload: data.hierarchyTrees });
    yield put({ type: 'hierarchyTree/setSiteHierarchyTitle', payload: data.siteHierarchyTitleTranslations });
  } catch (data) {
    console.error(data);
    yield put({ type: 'hierarchyTree/setErrors', payload: data.errors });
  }
  yield put({ type: 'hierarchyTree/setLoading', payload: false });

  return
}
function* fetchHierarchiesIfNeeded() {
  const state = yield select();

  if (state.hierarchyTrees.list.length > 0) return;

  yield put({ type: 'hierarchyTree/setLoading', payload: true })
  try {
    const data = yield call(HierarchyTrees.fetch, {});
    yield put({ type: 'hierarchyTree/setList', payload: data.hierarchyTrees });

  } catch(e) {
    console.error(e);
  }
  yield put({ type: 'hierarchyTree/setLoading', payload: false })
}

function* fetchHierarchies() {
  yield put({ type: 'hierarchyTree/setLoading', payload: true })
  try {
    const data = yield call(HierarchyTrees.fetch, {});
    yield put({ type: 'hierarchyTree/setList', payload: data.hierarchyTrees });
    yield put({ type: 'hierarchyTree/setSiteHierarchyTitle', payload: data.siteHierarchyTitleTranslations })

  } catch(e) {
    console.error(e);
  }
  yield put({ type: 'hierarchyTree/setLoading', payload: false })
}

function* sync() {
  yield put({ type: "hierarchyTree/setLoading", payload: true })
  try {
    yield call(Hierarchies.sync)
  } catch(e) {
    console.error(e);
  }
  yield put({ type: 'hierarchyTree/setLoading', payload: false })
}

export function* hierarchyTreeSaga() {
  yield all([
    takeLatest('hierarchyTree/fetch', fetchHierarchies),
    takeLatest('hierarchyTree/save', saveHierarchies),
    takeLatest('hierarchyTree/fetchHierarchiesIfNeeded', fetchHierarchiesIfNeeded),
    takeLatest('hierarchyTree/sync', sync),
  ])
}

export default hierarchiesReducer;
