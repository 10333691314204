// @ts-nocheck
import React from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools';
import AntForm from "@9troisquarts/ant-form.ant-form";
import Hierarchies from "./pages/HierarchySetting";
import Site from './pages/Site';
import User from './pages/User';
import Layout from './layouts';
import store, { history} from './store';
import Setting from "./pages/Setting";
import Groups from "./pages/Group";
import Home from "./pages/Home";
import HierarchyTitle from "./pages/Entity";
import PrivateComponent from "./utils/Authorization";
import AccessDenied from "./AccessDenied";
import '../assets/stylesheets/global.sass';
import { initThirdParty } from '../packs/useThirdParty';
import { ThirdPartyProvider } from "./utils/ContextProvider/ThirdPartyContext";
import AuthProviders from "./pages/AuthProviders";
import NormalUser from "./pages/User/NormalUser";
import QualimetrieUser from "./pages/User/QualimetrieUser";

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
AntForm.configure({
  formProps: {
    layout: "vertical",
  }
});

interface IProps {
  user: any;
  keyMessage: string;
  siteCount: number;
  userCount: number;
  currentTenant: any;
}

const App: React.FC<IProps> = props => {
  const queryClient = new QueryClient();
  const {
    user,
    keyMessage,
    siteCount,
    userCount,
    currentTenant,
    unsyncSiteCount = 0,
  } = props;

  // Call init as early on the page as possible. Some recording data may be lost if it is called too late
  const thirdParty = initThirdParty({ currentUser: user, currentTenant });

  return (
    <Provider store={store(props)}>
      <ThirdPartyProvider thirdParty={thirdParty} currentUser={user} currentTenant={currentTenant}>
        <ConnectedRouter history={history}>
          <QueryClientProvider client={queryClient}>
            <Layout user={user}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={props => <Home {...props} siteCount={siteCount} unsyncSiteCount={unsyncSiteCount} userCount={userCount} />}
                />
                <Route
                  exact
                  path="/401"
                  render={props => (
                    <AccessDenied {...props} keyMessage={keyMessage} />
                  )}
                />

                <Route
                  exact
                  path="/hierarchy_trees"
                  render={props => (
                    <PrivateComponent allowedRoles={["hierarchy_read"]}>
                      <Hierarchies {...props} />
                    </PrivateComponent>
                  )}
                />
                <Route
                  exact
                  path="/sites"
                  render={props => (
                    <PrivateComponent allowedRoles={["site_read"]}>
                      <Site {...props} />
                    </PrivateComponent>
                  )}
                />
                <Route
                  exact
                  path="/hierarchy_titles"
                  render={props => (
                    <PrivateComponent allowedRoles={["site_read"]}>
                      <HierarchyTitle {...props} />
                    </PrivateComponent>
                  )}
                />
                <Route
                  exact
                  path="/users/normal"
                  render={props => (
                    <PrivateComponent allowedRoles={["user_read"]}>
                      <NormalUser part="normal" {...props} />
                    </PrivateComponent>
                  )}
                />
                <Route
                  exact
                  path="/users/qualimetrie"
                  render={props => (
                    <PrivateComponent allowedRoles={["user_read_qualimetrie"]}>
                      <QualimetrieUser part="qualimetrie" {...props} />
                    </PrivateComponent>
                  )}
                />
                <Route
                  exact
                  path="/settings"
                  render={props => (
                    <PrivateComponent allowedRoles={["group_setting_edit"]}>
                      <Setting {...props} />
                    </PrivateComponent>
                  )}
                />
                <Route
                  exact
                  path="/auth_providers"
                  render={props => (
                    <PrivateComponent onlyInternal allowedRoles={["group_manage_sso"]}>
                      <AuthProviders {...props} />
                    </PrivateComponent>
                  )}
                />
                <Route
                  exact
                  path="/groups"
                  render={props => (
                    <PrivateComponent allowedRoles={["group_permission_read"]}>
                      <Groups {...props} />
                    </PrivateComponent>
                  )}
                />
              </Switch>
            </Layout>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ConnectedRouter>
      </ThirdPartyProvider>
    </Provider>
  );
};

export default App;
