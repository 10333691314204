import React from 'react';
import { Col } from "antd";
import { AntSchema } from '@9troisquarts/ant-form.ant-form';

const appSchema = (application, editingApplication, availableAddons, intl) => {
  const schema: AntSchema = [
    {
      label: "Nom",
      name: "name",
      required: true,
      input: {
        type: "string",
      }
    }, {
      label: "Nom de domaine",
      name: "subdomain",
      required: true,
      errorKey: "url",
      input: {
        type: "string",
        inputProps: { disabled: application.persisted }
      }
    }, {
      label: "Type d'application",
      name: "applicationType",
      required: true,
      input: {
        inputProps: { disabled: application.persisted },
        type: "select",
        options: [{
          value: "cxfirst",
          label: "CX First",
        }, {
          value: "micro-training",
          label: "CX Training"
        }]
      }
    },
  ];

  if (!editingApplication.id && editingApplication.applicationType === "cxfirst" && availableAddons.webAddons && availableAddons.webAddons.length > 0) {
    schema.push({
      label: "Addon Web",
      name: "webAddonId",
      input: {
        type: "select",
        options: availableAddons.webAddons.map(addon => ({ label: addon.name, value: addon.id })),
      }
    });
  }

  if (!editingApplication.id && editingApplication.applicationType === "cxfirst" && availableAddons.addonPerWeb && availableAddons.addonPerWeb[editingApplication.webAddonId] && availableAddons.addonPerWeb[editingApplication.webAddonId]?.mongodbAddons.length > 0) {
    schema.push({
      label: "Addon MongoDB",
      name: "mongodbAddonId",
      input: {
        type: "select",
        options: availableAddons.addonPerWeb[editingApplication.webAddonId].mongodbAddons.map(addon => ({ label: addon.name, value: addon.id })),
      }
    });
  }

  if (!editingApplication.id && editingApplication.applicationType === "cxfirst" && availableAddons.addonPerWeb && availableAddons.addonPerWeb[editingApplication.webAddonId] && availableAddons.addonPerWeb[editingApplication.webAddonId]?.esAddons.length > 0) {
    schema.push({
      label: "Addon ElascticSearch",
      name: "elasticsearchAddonId",
      input: {
        type: "select",
        options: availableAddons.addonPerWeb[editingApplication.webAddonId].esAddons.map(addon => ({ label: addon.name, value: addon.id })),
      }
    });
  }

  //@ts-ignore
  schema.push([
    {
      name: "templateImage",
      label: intl.formatMessage({ id: "activerecord.attributes.applications.template_image" }),
      input: {
        type: "upload",
        inputProps: {
          maxCount: 1,
        }
      },
      colProps: {
        lg: 6
      }
    },
    //@ts-ignore
    <Col span={10}>
      {!editingApplication.templateImage && editingApplication.templateImageUrl && (
        <img src={editingApplication.templateImageUrl} style={{width: "100%"}} />
      )}
    </Col>
  ]);

  return schema;
};

export default appSchema;