import React from "react";
import { useIntl } from "react-intl";
import { PageHeader, Row, Col } from "antd";
import classNames from "classnames/bind";
import omit from 'lodash/omit';
import { ApplicationType, ApplicationSettings, Group, User } from "../../../../types";
import { availableApps } from "./utils";
import styles from "./index.module.sass";
import CxLogo from "../../../../../../../public/cx_first.svg";
import NwLogo from "../../../../../../../public/nw_manager.svg";
import MtLogo from "../../../../../../../public/cx_training.svg";
import { hasRole } from "../../../../utils/Authorization";
const cx = classNames.bind(styles);

interface IProps {
  editingUser: User;
  onChange: (values) => void;
  currentUser: User;
  applications: ApplicationType[];
  wizardSteps: string[];
  setNextIsAvailable: (nextIsAvailable: boolean) => void;
  group: Group;
}

const logoForType = {
  cxfirst: CxLogo,
  "network-manager": NwLogo,
  "micro-training": MtLogo,
};

const canGoNext = (user, currentUser) => user.applicationSettings.filter(appSetting => !appSetting._destroy && appSetting.editable).length > 0 || (currentUser.networkManagerAccess && user.networkManagerAccess);

type ApplicationItemProps = {
  application: ApplicationType;
  checked: boolean;
  type: string;
  onChange: () => void;
}

const ApplicationItem: React.FC<ApplicationItemProps> = (props) => {
  const {
    application,
    checked,
    type,
    onChange
  } = props;
  const screenWidth = window.innerWidth;
  return (
    <Col
      xxl={4}
      xl={3}
      lg={4}
      md={4}
      xs={8}
    >
      <div
        className={cx('portalWrapper', { checked })}
        onClick={onChange}
      >
        <img
          style={{ height: screenWidth > 1500 ? '50px' : '40px' }}
          src={logoForType[type]}
          alt={`portal-form-${application.name}-logo`}
        />
        <div
          style={{ fontSize: screenWidth > 1500 ? 14 : 12 }}
        >
          {application.name}
        </div>
      </div>
    </Col>
  )
}

const PortalList: React.FC<IProps> = props => {
  const {
    editingUser,
    currentUser,
    applications,
    wizardSteps,
    group,
    onChange,
    setNextIsAvailable,
  } = props;

  const intl = useIntl();

  if (canGoNext(editingUser, currentUser)) {
    setNextIsAvailable(true);
  } else {
    setNextIsAvailable(false);
  }

  const handleChange = (app, checked) => {
    const newAppSettings = [...(editingUser.applicationSettings || [])];
    const appIndex = newAppSettings.findIndex(appSetting => appSetting.applicationId === app.applicationId);
    if (appIndex >= 0) {
      if (newAppSettings[appIndex] && !newAppSettings[appIndex].createdAt) {
        newAppSettings.splice(appIndex, 1);
      } else if (checked) {
        newAppSettings[appIndex] = { ...newAppSettings[appIndex], _destroy: false, active: checked};
      } else {
        newAppSettings[appIndex] = { ...newAppSettings[appIndex], _destroy: true, active: checked };
      }
    } else {
      const newAppSetting: ApplicationSettings = {
        applicationId: app.applicationId,
        roles: ['normal'],
        type: app.type,
        editable: true,
      };
      const currentUserFilters = currentUser.applicationSettings?.find(appSetting => appSetting.applicationId === app.applicationId);
      if(currentUserFilters) newAppSetting.hierarchyFilters = currentUserFilters?.hierarchyFilters.map(h => omit(h, ['applicationSettingId']));
      if (!wizardSteps.includes("locale")) newAppSetting.locale = editingUser.locale;
      newAppSettings.push(newAppSetting);
    }
    onChange({ applicationSettings: newAppSettings });
  };

  const portalChecked = (apps, refApp) => {
    const appIndex = apps.findIndex(appSetting => appSetting.applicationId === refApp.applicationId);
    return appIndex >= 0 && !apps[appIndex]._destroy;
  };

  const appForId = (id) => applications.find(app => app.id === id);

  const screenWidth = window.innerWidth;
  return (
    <div>
      <PageHeader
        title={intl.formatMessage({ id: "user.wizard.step.portals" })}
        style={{ paddingLeft: "0px" }}
        key="portal-list-form-header"
      />
      <Row className={styles.portalsWrapper}>
        <Col xs={16}>
          <Row gutter={16} wrap>
            {availableApps(applications, editingUser, group, currentUser).filter(app => appForId(app.applicationId)).map((app, i) => (
              <ApplicationItem
                key={app.applicationId}
                application={appForId(app.applicationId)!}
                type={app.type}
                onChange={() => handleChange(app, !portalChecked(editingUser.applicationSettings, app))}
                checked={portalChecked(editingUser.applicationSettings, app)}
              />
            ))}
            {hasRole(group, ["user_network_manager"]) && (
              <Col
                xxl={4}
                xl={3}
                lg={4}
                md={4}
                xs={8}
              >
                <div
                  className={cx('portalWrapper', { checked: !!editingUser.networkManagerAccess })}
                  key="portal-form-nm"
                  onClick={() => onChange({ networkManagerAccess: !editingUser.networkManagerAccess, groupId: "" })}
                >
                  <img
                    style={{ height: screenWidth > 1500 ? '50px' : '40px' }}
                    src={logoForType["network-manager"]}
                    alt="nw-logo"
                    key="portal-form-nm-image"
                  />
                  <div
                    style={{ fontSize: screenWidth > 1500 ? 14 : 12, textAlign: "center" }}
                  >
                    Network Manager
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PortalList;