import React from "react";
import {
  Modal
} from "antd";
import Importer from "../../../components/Importer";
import { useHierarchyTitleImport } from "../useHierarchyTitle";
import useI18n from "../../../utils/useI18n";

interface IProps {
  importerOpen: boolean;
  onImporterOpen: (open: boolean) => void;
}

const HierarchyTitleImporter: React.FC<IProps> = props => {
  const {
    importerOpen,
    onImporterOpen,
  } = props;
  const { t } = useI18n();

  const hierarchyImportMutation = useHierarchyTitleImport();
  const onImport = (params) => {
    hierarchyImportMutation.mutate(params);
  };

  return (
    <Modal
      key="sites-import-modal"
      visible={importerOpen}
      onCancel={() => onImporterOpen(false)}
      title={t("hierarchy_title.importer")}
      footer={null}
    >
      <Importer
        showSeparator={false}
        onSubmit={onImport}
        loading={hierarchyImportMutation.isLoading}
      >
        <div style={{ marginTop: "15px" }}>
          <strong>{t('importer.file_format.xlsx')}</strong>
        </div>
      </Importer>
    </Modal>
  );
};

export default HierarchyTitleImporter;