import React from 'react';
import { Table, Checkbox } from "antd";
import some from "lodash/some";
import uniq from "lodash/uniq";
import { camelize } from "humps";
import findIndex from 'lodash/findIndex';
import { useIntl } from 'react-intl';
import filter from 'lodash/filter';
import styles from "./index.module.sass";

interface IPropsTable {
  streams: any;
  editNotification: any;
  onChange: (values) => void;
  user: any;
  locale: string;
}

const EditNotificationTable: React.FC<IPropsTable> = props => {
  const {
    streams,
    editNotification,
    onChange,
    user,
    locale,
  } = props;
	const intl = useIntl();
  const existingNotif: string[] = [];

  const filteredStreams = filter(streams, stream => (user?.streamIds?.includes(stream.id) && stream.notificationsActive && some(stream.notifications, notification => notification.active))).map(stream => {
    const notifHash = { parentId: stream.id, streamTitle: stream.titleTranslations[locale] };
    stream.notifications.forEach(notif => {
      if (notif.notificationType === "issue" && !stream.issuesActive) {
        return;
      }
      notifHash[notif.notificationType] = notif.active;
      existingNotif.push(notif.notificationType);
    });

    return notifHash;
  });

  const onCheckClick = (checked, notification, parentId) => {
    const newEditNotification = [...editNotification];
    const index = findIndex(newEditNotification, notif => notif.parentId === parentId);
    if (index >= 0) {
      const editNotif = { ...newEditNotification[index] };
      editNotif[`${camelize(notification)}Active`] = checked;
      newEditNotification[index] = editNotif;
    } else {
      const newNotif = {
        type: "none",
        parentId,
        userId: user.id,
      };
      newNotif[`${camelize(notification)}Active`] = checked;
      newEditNotification.push(newNotif);
    }

    onChange(newEditNotification);
  };

  const isNotifChecked = (notification, parentId) => {
    const userNotif = editNotification[findIndex(editNotification, notif => notif.parentId === parentId)];
    if (!userNotif) return false;
    return userNotif[`${camelize(notification)}Active`];
  };

  const availableNotifications = {};

  streams?.filter(stream => stream.notificationsActive).forEach(stream => stream.notifications ? stream.notifications.filter(notif => notif.active && notif.notificationType !== "issue_comment").forEach(notif => availableNotifications[notif.notificationType] = notif.active ) : null)

  const columns: any[] = [];

  uniq(existingNotif).forEach(notif => {
    if (availableNotifications[notif]) {
      columns.push({
        dataIndex: notif,
        title: intl.formatMessage({ id: `activerecord.attributes.user.application_setting.notifications.${notif}`}),
        onHeaderCell: () => ({
          className: "headerTableCell",
          width: 80,
        }),
        onCell: (record) => ({
          className: record[notif] ? (isNotifChecked(notif, record.parentId) ? "selectedTableCell" : "notificationTableCell") : "inactiveNotificationTableCell",
          width: 80,
        }),
        render: (title, record) => <Checkbox disabled={!record[notif]} onChange={(e) => onCheckClick(e.target.checked, notif, record.parentId)} checked={isNotifChecked(notif, record.parentId)} />
      });
    }
  });

  const notificationWidth = columns.length * 80;

  columns.unshift({
    dataIndex: "stream",
    fixed: "left",
    onHeaderCell: () => ({
      className: "firstHeaderTableCell",
      width: 150,
    }),
    onCell: () => ({
      className: "firstTableCell",
      width: 150,
    }),
    render: (title, record) => {
      return record.streamTitle
    }
  });

  return (
    <>
      <Table
        className={styles.notificationTable}
        dataSource={filteredStreams}
        bordered={false}
        rowKey={(record) => `stream-${record.parentId}}`}
        scroll={{ x: (notificationWidth + 150) }}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default EditNotificationTable;