import chroma from 'chroma-js';
import { css } from '@emotion/css';

export const getTenantColorClassName = (
  navigationColor = '#4BABE9',
  primaryColor = '#4BABE9',
  secondaryColor = '#ffffff',
) => {
  return css`
    .ant-pro-sider-light {
      background-color: ${navigationColor};
    }

    .ant-layout-sider.ant-layout-sider-dark .ant-menu-item a {
      color: ${secondaryColor};
    }

    .ant-btn.ant-btn-primary:hover,
    .ant-btn.ant-btn-primary:focus {
      color: ${secondaryColor};
      border-color: ${chroma(primaryColor).darken(0.5).hex()};
      background-color: ${chroma(primaryColor).darken(0.5).hex()};
    }

    .ant-btn-primary {
      background-color: ${primaryColor};
      border-color: ${primaryColor};
      color: ${secondaryColor};
    }

    .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
      border-color: #d9d9d9;
      background: #f5f5f5;
      color: #00000040;
    }

    .ant-switch-checked {
      background-color: ${primaryColor} !important;
    }

    .ant-modal-content.ant-switch-checked {
      background-color: ${primaryColor} !important;
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,

    .ant-menu-inline .ant-menu-vertical .ant-menu-item::after {
      border-right: 3px solid ${chroma(navigationColor).brighten().hex()} !important;
    }

    .ant-menu {
      background: ${chroma(navigationColor).darken().hex()};
      color: ${chroma(secondaryColor).brighten().hex()};
      border-right: 0px solid ${chroma(navigationColor).darken().hex()} !important;
    }

    .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
      border-right-color: ${chroma(secondaryColor).brighten().hex()};
    }

    .ant-layout-sider .ant-menu-vertical .ant-menu-submenu-selected {
      color: ${secondaryColor};
    }

    .ant-layout-sider.ant-layout-sider-dark .ant-menu-sub.ant-menu-inline {
      background-color: ${chroma(navigationColor).darken().hex()} !important;
    }

    .ant-menu-submenu.ant-menu-submenu-popup > .ant-menu {
      background-color: ${chroma(navigationColor).darken().hex()} !important;
    }

    .ant-menu-submenu {
      background-color: ${chroma(navigationColor).darken().hex()} !important;
      color: ${chroma(secondaryColor).brighten().hex()};
    }

    .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
      color: ${chroma(secondaryColor).brighten().hex()}; !important
    }

    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      color: ${chroma(secondaryColor).brighten().hex()}; !important
    }

    .ant-menu-item-selected {
      background: ${chroma(navigationColor).brighten().hex()};
      color: ${chroma(secondaryColor).brighten().hex()};
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: ${chroma(navigationColor).darken().hex()};
    }

    .user-menu span {
      color: ${primaryColor} !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: ${primaryColor};
      border-color: ${primaryColor};
    }

    .ant-radio-button-wrapper:hover {
      color: ${primaryColor};
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: ${primaryColor};
    }

    .ant-radio-inner::after {
      background-color: ${primaryColor};
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${primaryColor};
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${primaryColor};
      border-color: ${primaryColor};
    }

    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-right-color: ${primaryColor};
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: ${primaryColor};
    }

    .surveys-list
      .ant-radio-group-outline
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: ${secondaryColor};
      background: ${primaryColor};
    }

    .ant-input:focus,
    .ant-input:hover,
    .ant-input-number:hover,
    .ant-input-affix-wrapper:hover,
    .ant-input-pasword,
    .ant-input-focused {
      border-color: ${primaryColor};
    }

    .ant-pagination-item-active {
      border-color: ${primaryColor};
    }

    .ant-pagination-item:focus-visible a,
    .ant-pagination-item:hover a {
      color: ${primaryColor};
    }

    .ant-pagination-item:focus-visible,
    .ant-pagination-item:hover {
      border-color: ${primaryColor};
    }

    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      color: ${primaryColor};
      border-color: ${primaryColor};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${primaryColor};
    }

    .ant-tabs-ink-bar {
      background-color: ${primaryColor};
    }

    .ant-tabs-tab:hover {
      color: ${primaryColor};
    }

    .ant-spin {
      color: ${primaryColor};
    }

    .category-card {
      color: ${primaryColor};
      background-color: #ffffff;
      border: 3px solid ${primaryColor};
      border-radius: 5px;
      padding: 15px 30px 15px 30px;
      cursor: pointer;
      font-weight: 600;
      margin: 5px;
      max-width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;
    }

    .category-card.active {
      background-color: ${primaryColor};
      color: #ffffff;
    }
  `;
};
