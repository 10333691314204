import React from "react";
import { findIndex, filter } from "lodash";
import { EditOutlined, WarningOutlined } from "@ant-design/icons";
import { hasRole } from "../../../../utils/Authorization";

export const columns = (levelOption, onEdit, treeErrors, locale, group) => {
  const cols = [
    {
      title: "#",
      dataIndex: "position",
      width: 30
    },
    {
      title: "Nom",
      dataIndex: "titleTranslations",
      render: (text) => text ? text[locale] : ""
    },
    {
      title: "Code Header",
      dataIndex: "codeHeader",
      render: (text) => <span style={{ color: text ? "inherit" : "#db2828" }}>{text ? text : "Code Header manquant"}</span>
    },
    {
      title: "Niveau comparé le plus haut",
      dataIndex: "highestComparedLevel",
      render: (text) => levelOption[findIndex(levelOption, o => o.value === text)].label
    },
    {
      render: (text, record, index) => (
        <div>
          {treeErrors && filter(Object.keys(treeErrors.errors), key => key.includes(`hierarchyBranches[${index}]`)).length > 0 && (
            <WarningOutlined style={{ color: "#fa8c16" }} />
          )}
        </div>
      ),
      width: 50,
      align: 'center',
    },
  ];

  if (hasRole(group, ["hierarchy_update"])) {
    cols.push({
      render: (text, branch) => (<a onClick={() => onEdit(branch)}><EditOutlined /></a>),
      width: 50,
      align: 'center',
    });
  }

  return cols;
};

export default columns;