import React, { useState } from "react";
import { AntForm } from '@9troisquarts/ant-form.ant-form';
import {
  Modal,
  Button,
  Col,
  Row
} from "antd";
import { User } from "../../../../types";
import useI18n from "../../../../utils/useI18n";
import styles from "../UserEdit/index.module.sass";

interface IProps {
  passwordVisible: boolean;
  editLoading: boolean;
  user: User;
  saveLoading: boolean;
  onPasswordSave: (payload: User) => void;
  errors: any;
  onCancel: () => void;
}

const PasswordEdit: React.FC<IProps> = (props) => {
  const {
    passwordVisible,
    editLoading,
    user,
    saveLoading,
    onPasswordSave,
    errors,
    onCancel,
  } = props;
  const { t } = useI18n();
  const [editingUser, setEditingUser] = useState<User>(user);

  return (
    <Modal
      title={t("user.password_edit")}
      visible={passwordVisible}
      width={1000}
      onCancel={onCancel}
      footer={!editLoading ? (
        <div className={styles.actionWrapper}>
          <div className={styles.submitWrapper}>
            <Button
              type="text"
              onClick={onCancel}
            >
              { t('words.cancel')}
            </Button>
            <Button
              type="primary"
              loading={saveLoading}
              onClick={() => onPasswordSave(editingUser)}
            >
              { t('words.save')}
            </Button>
          </div>
        </div>
      ) : null}
    >
      <Row gutter={16}>

        <Col xs={16} sm={8} lg={8} xl={8}>
          <AntForm
            layout="vertical"
            object={editingUser}
            errors={errors}
            onChange={(values) => setEditingUser({ ...editingUser, ...values })}
            schema={[{
              name: "password",
              label: t("activerecord.attributes.user.password"),
              input: {
                type: "password",
              }
            }, {
              name: "confirmation_password",
              label: t("activerecord.attributes.user.password_confirmation"),
              input: {
                type: "password",
              }
            }]}
          />
        </Col>
        <Col xs={16} sm={8} lg={8} xl={8}>
          {t("password.rules.message")}
          <ul>
            {['lowercase', 'uppercase', 'number', 'special_char', 'length'].map(errorType => (
              <li key={errorType}>
                {t(`password.rules.${errorType}`)}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Modal>
  );
};

export default PasswordEdit;