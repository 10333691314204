import styles from "./index.module.sass";

import React, { useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import classnames from 'classnames/bind';
import { useIntl } from "react-intl";

const cx = classnames.bind(styles)

const transformLocaleIsoToCountryIso = locale => {
  switch (locale) {
    case "en":
      return "gb";
    default:
      return locale;
  }
};

const onDropDownChange = (selectedValue, onChange, setDropdownValue) => {
  setDropdownValue(selectedValue.text);
  onChange(null, { value: selectedValue });
};

const menu = (values, onChange, setDropdownValue) => (
  <Menu
    onClick={({ key }) => {
      onDropDownChange(key, onChange, setDropdownValue);
    }}
  >
    {values.map(value => (
      <Menu.Item className="text-capitalized" key={value.key}>
        {value.text}
      </Menu.Item>
    ))}
  </Menu>
);


type SwitcherProps = {
  inline?: boolean;
  withText?: boolean;
  style: React.CSSProperties;
  value: string;
  availableLocales: string[];
  onChange: () => void;
}

const Switcher: React.FC<SwitcherProps> = (props) => {
  const {
    value, availableLocales, onChange, style = {}, inline = false, withText = true,
  } = props;
  const intl = useIntl();
  const options = availableLocales.map(l => ({
    key: l,
    value: l,
    flag: transformLocaleIsoToCountryIso(l),
    text: intl.formatMessage({ id: `locales.${l}` }),
  }))
  const [dropdownValue, setDropdownValue] = useState(null);
  if(!options || options.length < 2) return null;
  return (
    <div
      className={cx('container', { inline })}
      style={style}
    >
      {withText ? intl.formatMessage({ id: "editing_language" }) : "" }
      &nbsp;
      <Dropdown overlay={menu(options, onChange, setDropdownValue)}>
        <strong className="text-capitalized">
          {dropdownValue
            || options.map(local => (local.key === value ? local.text : ""))}
          <DownOutlined style={{ marginLeft: "5px" }} />
        </strong>
      </Dropdown>
    </div>
  );
};

Switcher.defaultProps = {
  style: {},
  withText: true,
};



const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  availableLocales: state.locales.availableLocales,
  value: state.locales.currentLocale,
});

const mapDispatchToProps = dispatch => ({
  onChange: (e, { value }) => {
    dispatch({ type: "locales/setCurrent", payload: value })
  },
});

export const LocalesSwitcher = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Switcher);

export default LocalesSwitcher;
