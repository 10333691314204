import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import createImmerReducer from "../lib/createReducer";
import TenantService from "../services/tenant";
import { Tenant } from "../types";

type TenantStateType = {
  current?: Tenant;
  loading: boolean;
  errors: any;
};

const initialState: TenantStateType = {
  current: undefined,
  loading: false,
  errors: {},
};

const tenantReducer = createImmerReducer<TenantStateType>(initialState, {
  "tenant/setCurrent": (state, { payload }) => {
    state.current = payload;
    return state;
  },
  "tenant/setLoading": (state, { payload }) => {
    state.loading = payload;
    return state;
  }
});

function* saveTenant({ payload }: any) {
  yield put({ type: "tenant/setLoading", payload: true });

  try {
    const data = yield call(TenantService.save, payload);
    if (Object.keys(data.errors).length > 0) {
      yield put({ type: 'tenant/setErrors', payload: data.errors });
    } else {
      yield put({ type: 'tenant/setCurrent', payload: data.tenant });
    }
  } catch (e) {
    console.error(e);
  }

  yield put({ type: "tenant/setLoading", payload: false });
};

function* fetchTenant() {
  yield put({ type: 'tenant/setLoading', payload: true });

  try {
    const data = yield call(TenantService.fetch, {});
    yield put({ type: 'tenant/setCurrent', payload: data.tenant });
  } catch(e) {
    console.error(e);
  }
  yield put({ type: 'tenant/setLoading', payload: false });
}

export function* tenantSaga() {
  yield all([
    takeLatest('tenant/fetch', fetchTenant),
    takeLatest('tenant/save', saveTenant)
  ]);
}

export default tenantReducer;