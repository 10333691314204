import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { decamelizeKeys } from "humps";
import {
  Pagination,
  HierarchyTitleType,
  HierarchyTree
} from "../../types";
import castAttributesFromModel from "../../utils/castAttributesFromDefinition";
import client from "../../utils/client";

const getHierarchyTitles = async ({ queryKey: [key, params] }) => {
  const castedParams = castAttributesFromModel({ withoutSite: "Boolean", title: "String", code: "String", branchSlug: "String", page: "Integer", pageSize: "Integer" }, params);
  const search = decamelizeKeys(castedParams);
  const promise: { hierarchyTitles: HierarchyTitleType[]; pagination: Pagination } = await client.get('/hierarchy_titles', {
    params: { ...search },
  });

  return promise;
};

const getHierarchyTitle = async ({ queryKey: [id] }) => {
  let promise: { hierarchyTitle: HierarchyTitleType } | null = null;
  if (id) {
    promise = await client.get(`/hierarchy_titles/${id}`);
  } else {
    promise = await client.get(`/hierarchy_titles/new`);
  }

  return promise;
};

const getHierarchyTrees = async () => {
  const promise: { hierarchyTrees: HierarchyTree[] } = await client.get('/hierarchy_trees', {});

  return promise;
};

const saveHierarchyTitle = async (hierarchyTitle) => {
  let promise: { hierarchyTitle: HierarchyTitleType; errors: { [k: string]: string } };
  if (hierarchyTitle.persisted) {
    promise = await client.put(`/hierarchy_titles/${hierarchyTitle.id}`, { hierarchyTitle });
  } else {
    promise = await client.post('/hierarchy_titles', { hierarchyTitle });
  }

  return promise;
};

const getHierarchyBranches = async () => {
  const promise: { branches: { slug: string; title: string; withoutBranch?: boolean }[]} = await client.get('/hierarchy_titles/branches.json');

  return promise;
};

const deleteHierarchyTitle = async (id) => {
  const promise = await client.delete(`/hierarchy_titles/${id}`);

  return promise;
};

const deleteAllHierarchyTitle = async (branch_slug, withoutBranch) => {
  const promise = await client.delete(`/hierarchy_titles/delete?branch_slug=${branch_slug}${withoutBranch ? `&without_branch=${withoutBranch}` : ''}`);

  return promise;
};

const importFile = async ({ separator, file }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("separator", separator);
  const response: any = await client.post(`/hierarchy_titles/import.json`, formData);

  return response;
};

export const useHierarchyTitleDeleteAll = () => {
  const queryClient = useQueryClient();
  const hierarchyTitleDeleteAll = useMutation(({ branchSlug, withoutBranch }: any) => deleteAllHierarchyTitle(branchSlug, withoutBranch), {
    onSuccess: () => {
      queryClient.invalidateQueries(['hierarchy_titles']);
    }
  });

  return hierarchyTitleDeleteAll;
};

export const useHierarchyTitleDelete = () => {
  const queryClient = useQueryClient();
  const hierarchyTitleDelete = useMutation((id: string) => deleteHierarchyTitle(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['hierarchy_titles']);
    }
  });

  return hierarchyTitleDelete;
};

export const useHierarchyTitleImport = () => {
  const queryClient = useQueryClient();
  const hierarchyTitleImport = useMutation((params: {file: any; separator: string }) => importFile(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['hierarchy_titles']);
    }
  });

  return hierarchyTitleImport;
};

export const useHierarchyBranches = () => {
  const { data } = useQuery([], getHierarchyBranches);

  return data?.branches;
};

export const useHierarchyTitleMutation = (onCancel) => {
  const [errors, setErrors] = useState(null);
  const queryClient = useQueryClient();
  const hierarchyTitleMutation = useMutation((hierarchyTitle) => saveHierarchyTitle(hierarchyTitle), {
    onError: ({ errors }) => {
      setErrors(errors);
    },
    onSuccess: () => {
      setErrors(null);
      onCancel();
      queryClient.invalidateQueries(['hierarchy_titles']);
    }
  });

  return {
    hierarchyTitleMutation,
    errors,
  };
};

export const useHierarchyTitles = (params) => {
  const { data, isLoading } = useQuery(["hierarchy_titles", params], getHierarchyTitles, {
    refetchInterval: false,
  });

  const hierarchyTitles = data?.hierarchyTitles;
  const pagination = data?.pagination;

  return {
    hierarchyTitles,
    pagination,
    isLoading,
  };
};

export const useHierarchyTrees = () => {
  const { data } = useQuery(['hierarchy_trees'], getHierarchyTrees, {
    refetchInterval: false,
  });

  const hierarchyTitles = data?.hierarchyTrees;

  return hierarchyTitles;
};

export const useHierarchyTitle = (id) => {
  const { data, isLoading } = useQuery([id], getHierarchyTitle, {
    refetchInterval: false,
  });

  const hierarchyTitle = data?.hierarchyTitle;

  return {
    hierarchyTitle,
    isLoading,
  };
};