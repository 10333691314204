import React from "react";
import {
  Card,
  Divider,
  PageHeader,
  Space
} from "antd";
import AntForm from "@9troisquarts/ant-form.ant-form";
import some from "lodash/some";
import { useIntl } from "react-intl";
import { ApplicationType, User, Group } from "../../../../types";
import { getApplication, logoForApp } from "./utils";
import styles from "./index.module.sass";
import { hasRole } from "../../../../utils/Authorization";

interface IProps {
  editingUser: User;
  currentUser: User;
  onChange: (values: any) => void;
  applications: ApplicationType[];
  availableRolesForApp: { [key: string]: string[] };
  hideTitle: boolean;
  errors: any;
  group: Group;
  nmGroupOptions: {value: string; label: string}[];
  setNextIsAvailable: (nextIsAvailable: boolean) => void;
}
const canGoNext = (user) => {
  if (user.networkManagerAccess && (!user.groupId)) {
    return false;
  }
  if (user.applicationSettings?.length > 0) {
    if (some(user.applicationSettings, appSetting => (!appSetting.roles || appSetting.roles.length === 0))) {
      return false;
    }
  }
  return true;
};

const RolesForm: React.FC<IProps> = props => {
  const {
    editingUser,
    availableRolesForApp,
    applications,
    nmGroupOptions,
    group,
    hideTitle,
    errors,
    onChange,
    setNextIsAvailable,
  } = props;

  if (canGoNext(editingUser)) {
    setNextIsAvailable(true);
  } else {
    setNextIsAvailable(false);
  }

  const intl = useIntl();
  const handleChange = (values, applicationId) => {
    const index = (editingUser.applicationSettings || []).findIndex(appSetting => appSetting.applicationId === applicationId);
    const newAppSettings = [...(editingUser.applicationSettings || [])];
    const newAppSetting = { ...newAppSettings[index], ...values };

    newAppSettings[index] = newAppSetting;
    onChange({ applicationSettings: newAppSettings });
  };

  return (
    <div>
      {!hideTitle && (
        <PageHeader
          key="roles-form-header"
          title={intl.formatMessage({ id: "user.wizard.step.roles" })}
          style={{ paddingLeft: "0px" }}
        />
      )}
      <Space
        direction="vertical"
        style={{ width: "100%" }}
        key="roles-form"
        className={styles.applicationRolesWrapper}
      >
        { (editingUser.applicationSettings || []).filter((appSetting: any) => (!appSetting._destroy && appSetting.editable)).map((appSetting: any, i) => {
          const application = getApplication(applications, appSetting.applicationId);
          const error = (errors || []).find(error => error.applicationId === appSetting.applicationId);
          return (
            (
              <React.Fragment
                key={`roles-form-${appSetting.type}-${i}`}
              >
                <div
                  className={styles.checkboxesWrapper}
                >
                  <span className={`${styles.appTitle} ${error ? 'error' : ''}`}>
                    <img
                      width={30}
                      alt={`logo-for-${logoForApp[appSetting.type || '']['compact']}`}
                      src={logoForApp[appSetting.type || '']['compact']}
                      style={{ marginRight: "5px", marginBottom: "5px" }}
                    />
                    { application.name }
                  </span>
                  <Card>
                    <AntForm
                      object={appSetting}
                      onChange={(values) => handleChange(values, appSetting.applicationId)}
                      onSubmit={undefined}
                      layout="vertical"
                      errors={{ roles: error?.errors }}
                      schema={[{
                        name: "roles",
                        input: {
                          type: "checkboxes",
                          options: availableRolesForApp[appSetting.applicationId].map(role => ({ value: role, label: intl.formatMessage({ id: `activerecord.attributes.user.application_setting.roles.${role}` }) }))
                        }
                      }]}
                    />
                  </Card>
                </div>
              </React.Fragment>
            )
          )
        })}
        {hasRole(group, ["user_network_manager"]) && editingUser.networkManagerAccess && (
          <div
            key="roles-form-nwmanager"
            className={styles.checkboxesWrapper}
          >
            <span className={styles.appTitle}>
              <img
                width={30}
                alt={`logo-for-${logoForApp['network-manager']['compact']}`}
                src={logoForApp["network-manager"]['compact']}
                style={{ marginRight: "5px", marginBottom: "5px" }}
              />
              Network Manager
            </span>
            <Card>
              <AntForm
                object={editingUser}
                onChange={(values) => onChange(values)}
                onSubmit={undefined}
                layout="vertical"
                schema={[{
                  name: "groupId",
                  input: {
                    type: "radio",
                    layout: "vertical",
                    options: nmGroupOptions
                  }
                }]}
              />
            </Card>
          </div>
        )}
      </Space>
    </div>
  );
};

export default RolesForm;