import React from 'react';
import {
  Col,
  Row,
} from "antd";
import {
  TeamOutlined,
  CommentOutlined,
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useIntl } from "react-intl";

import HighlightButton from "../../../../components/HighlightButton";
import { hasRole } from "../../../../utils/Authorization";
import { Group, ApplicationType, Tenant } from '../../../../types';
import styles from './index.module.sass';
import { logoForApp } from "../../../User/components/UserEdit/utils";
import DefaultAppPicto from "../../../../../../../public/default_app_picto.svg";
import { isDevelopment } from '../../../../../packs/config';

interface IProps {
  application: ApplicationType;
  userGroup?: Group | undefined;
  /** if `true` disable all the possible actions on the cards */
  readOnly?: boolean;
  onEdit: (application: ApplicationType) => void;
  /** bind `applicationEdit` modal to the edit action on the card (ie: pass application data to the function used to open a modal 
   * in order to display and edit application data of that card) */
  tenant: Tenant;
}

const appUrl = (application) => {
  if (!application) {
    return "";
  }

  let url = `https://${application.url.replace('.api', '')}`;
  if (isDevelopment) {
    url = url.replace("https", "http").replace(".com", ".test").replace(".staging", "");
  }

  return url;
};

const AppCard: React.FC<IProps> = props => {
  const {
    application,
    userGroup,
    readOnly,
    onEdit,
    tenant,
  } = props;

  const intl = useIntl();

  const onEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onEdit(application);
  }

  return (
    <Col
      className={`${styles.cardBlock} ${application.active ? styles.active : styles.inactive}`}
      style={{ position: 'relative'}}
      onClick={() => { window.open(appUrl(application)); }}
    >
      <div className={`${styles.cardBody} shadow`} style={{ backgroundColor: application.active ? "" : "#f1e0e0" }}>

        <div className={styles.cardBlockImageWrapper} style={{ marginBottom: "20px" }}>
          <img
            className={styles.cardImage}
            alt={`logo-for-${application.applicationType}-${application.id}`}
            src={application.templateImageUrl || DefaultAppPicto}
          />
        </div>

        <div>
          <div className={styles.logoWrapper}>
            <div className={styles.logoContainer}>
              <img
                width={30}
                alt={`logo-for-${logoForApp[`${application.applicationType}`]['compact']}`}
                src={logoForApp[`${application.applicationType}`]['compact']}
                style={{ marginRight: "1px", marginBottom: "2px" }}
              />
            </div>
          </div>
        </div>

        <div className={styles.blockTitle} style={{ paddingLeft: "1rem", color: "#343434" }}>
          {application.name}
        </div>
        <div className={styles.blockFooter}>
          {
            application.active ? (
              <div>
                <CheckOutlined className={styles.iconLabel} />
                <span className={styles.blockInfo}>
                  ({ intl.formatMessage({ id: "appCard.active" }) })
                </span>

              </div>
            ) : (
              <div>
                <CloseOutlined className={styles.iconLabel} />

                <span className={styles.blockInfo}>
                  ({ intl.formatMessage({ id: "appCard.inactive" }) })
                </span>

              </div>
            )
          }
          <div>
            <TeamOutlined className={styles.iconLabel} />
            <span className={styles.blockLabel}> {application["userCount"]} </span>
          </div>
        </div>

      </div>
      { !readOnly && hasRole(userGroup, ["portal_create", "portal_edit"]) && (
        <div
          onClick={onEditClick}
          className={styles.editButtonWrapper}
        >
          <EditOutlined
          />
        </div>
      )}

    </Col>
  );
};

AppCard.defaultProps = {
  readOnly: true,
  userGroup: undefined,
};

export default AppCard;