import React from "react";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

const columns = (onEdit: (id: string) => void, intl: any):ColumnsType => {
  const defaultColumns = [
    {
      title: intl.formatMessage({ id: "activerecord.attributes.applications.name" }),
      dataIndex: "name",
    },
    {
      title: intl.formatMessage({ id: "activerecord.attributes.applications.url" }),
      dataIndex: "url",
    },
    {
      title: intl.formatMessage({ id: "activerecord.attributes.applications.active" }),
      dataIndex: "active",
      align: 'center' as 'center',
      width: 100,
      render: (text) => {
        if (text) {
          return <CheckOutlined />;
        }
        return <CloseOutlined color="red" />;
      },
    },
    {
      align: 'right' as 'right',
      render: (({ id }) => <EditOutlined onClick={() => onEdit(id)} />)
    }
  ];

  return defaultColumns;
};


export default columns;