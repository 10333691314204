import React from 'react';
import {
  PageHeader,
  Card,
  Row,
  Col,
  Alert
} from 'antd';
import find from 'lodash/find';
import { useIntl } from 'react-intl';
import AntForm, { AntSchema } from "@9troisquarts/ant-form.ant-form";
import { User, ApplicationType } from '../../../../types';
import get from 'lodash/get';
import { getApplication, logoForApp } from "./utils";
import styles from './index.module.sass';

interface IProps {
  editingUser: User;
  errors: any[];
  onChange: (values: any) => void;
  applications: ApplicationType[];
  availableStreams: string[];
  hideTitle: boolean;
  locale: string;
}

const PublicationLevelForm = props => {
  const {
    publicationLevel, errors, onChange
  } = props;

  const schema: AntSchema = [
    {
      name: "level",
      input: {
        type: "number",
        inputProps: {
          min: 90,
        }
      }
    },
  ];

  const object = {
    level: "99",
    ...publicationLevel
  };

  return (
    <AntForm
      key="ant-form"
      errors={errors || {}}
      object={object}
      onChange={onChange}
      schema={schema}
      layout="inline"
    />
  );
};

const PublicationLevelsForm: React.FC<IProps> = props => {
  const {
    editingUser,
    errors,
    onChange,
    applications,
    availableStreams,
    hideTitle,
    locale
  } = props;

  const intl = useIntl();
  const cxfirstAppSetting = (editingUser.applicationSettings || []).filter(appSetting => appSetting.type === "cxfirst" && (!appSetting._destroy && appSetting.editable));

  const handleChange = (values, appId, streamId) => {
    const newAppSettings = [...(editingUser.applicationSettings || [])];
    const index = newAppSettings.findIndex((appSetting => appSetting.applicationId === appId));
    const newPublicationLevels = [...(newAppSettings[index].publicationLevels || [])];
    const publicationLevelIndex = newPublicationLevels.findIndex(pl => pl.parentId === streamId);
    if (publicationLevelIndex >= 0) {
      newPublicationLevels[publicationLevelIndex] = { ...newPublicationLevels[publicationLevelIndex], ...values };
    } else {
      newPublicationLevels.push({ parentId: streamId, ...values });
    }
    const newAppSetting = { ...newAppSettings[index], publicationLevels: newPublicationLevels };
    newAppSettings[index] = newAppSetting;
    onChange({ applicationSettings: newAppSettings });
  };

  return (
    <>
      {!hideTitle && (
        <PageHeader
          key="publication-level-form-header"
          title={intl.formatMessage({ id: "user.wizard.step.publication_levels" })}
          style={{ paddingLeft: "0px" }}
        />
      )}
      <Row
        key="publication-levels-forms"
        className={styles.applicationSettingsWrapper}
      >
        { cxfirstAppSetting.map((appSetting, i) => {
          const application = getApplication(applications, appSetting.applicationId);
          const appErrors = get(errors, appSetting.id);

          return (
            (
              <Col
                span={16}
                key={`publication-level-form-${i}`}
                className={`${styles.appSettingWrapper} ${appErrors ? 'error' : ""}`}
                style={{ width: "100%", marginTop: i > 0 ? "10px" : "unset" }}
              >
                <span
                  key={`app-setting-${i}`}
                  className={styles.appTitle}
                >
                  <img
                    width={30}
                    alt={`logo-for-${logoForApp['cxfirst']['compact']}`}
                    src={logoForApp["cxfirst"]['compact']}
                    style={{ marginRight: "5px", marginBottom: "5px" }}
                  />
                  { application.name }
                </span>
                <Card
                  key={`card-app-setting-${i}`}
                >
                  {/* {appErrors?.errors?.length && (
                    <Alert
                      type="error"
                      style={{ marginBottom: 16 }}
                      description={(
                        <>
                          {appErrors.errors.map(err => (
                            <div key={err}>{err}</div>
                          ))}
                        </>
                      )}
                    />
                  )} */}
                  <div
                    key={`publication-level-form-wrapper-${i}`}
                    className={styles.publicationLevelFormsWrapper}
                  >
                    { availableStreams[appSetting.applicationId || ""].filter(s => s.hasPublicationState && (appSetting.streamIds || []).includes(s.id)).map((stream, i) => (
                      <Row
                        key={`publication-level-form-${stream.title}`}
                        className={styles.publicationLevelFormWrapper}
                        style={{ marginTop: i === 0 ? "0" : "10px" }}
                      >
                        <Col
                          key={`publication-level-${stream.title}`}
                          className={styles.streamTitleWrapper}
                          span={4}
                        >
                          {stream.titleTranslations[locale]}
                        </Col>
                        <Col
                          key={`publication-level-${stream.title}-level`}
                          span={12}
                        >
                          <PublicationLevelForm
                            errors={get(appErrors, stream.id, {})}
                            key={`publication-level-${stream.id}`}
                            publicationLevel={find(appSetting.publicationLevels, { parentId: stream.id })}
                            stream={stream}
                            onChange={values => handleChange(values, appSetting.applicationId, stream.id)}
                          />
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Card>
              </Col>
            )
          )
        })}
      </Row>
    </>
  );
};

export default PublicationLevelsForm;