import { useState } from 'react';
import { useQueryClient, useMutation, useQuery } from 'react-query';

import client from '../../../utils/client';
import { ApplicationType } from '../../../types';
import castAttributesFromModel, { ModelDefinitionType } from '../../../utils/castAttributesFromDefinition';

const applicationDefinition: ModelDefinitionType = {
  active: "Boolean",
  subdomain: "String",
  name: "String",
  applicationType: "String",
  templateImage: "File",
  mongodbAddonId: "String",
  webAddonId: "String",
  elasticsearchAddonId: "String",
};

/**
 * Retrieve all user's applications
 * @returns {array[ApplicationType]}
 */
export const getApplications = async () => {
  const response: { applications: ApplicationType[] } = await client.get('/applications.json');
  return response;
};

/**
 * Update (server-side) data of an application 
 * @param {dict} params - dictionnary of all parameters to send 
 * @returns {XMLHttpRequest.responseType}
 */
const save = async (params) => {
  const application = castAttributesFromModel(applicationDefinition, params, 'application')
  let response;
  if (params.id) {
    response = await client.put(`/applications/${params.id}`, application, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      httpRequest: true
    });
  } else {
    response = await client.post(`/applications`, application );
  }
  return response;
};


const newApp = async () => {
  const response: { availableAddons: any, application: ApplicationType } = await client.get('/applications/new');
  return response
}

/**
 * Save data application hook
 */
const useApplication = (editingApplication) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [availableAddons, setAvailableAddons] = useState({});
  const queryClient = useQueryClient();
  const saveApplication = useMutation(save);

  
  const { isLoading, data } = useQuery(['application'], newApp, {
    enabled: !!editingApplication,
    refetchInterval: false,
    //@ts-ignore
    onSuccess: (data) => {
      setAvailableAddons(data);
    }
  });

  const onSave = (application: ApplicationType, options?: any) => {
    setLoading(true);
    saveApplication.mutate(application, {
      onError: (data: any) => {
        setLoading(false);
        setErrors(data?.errors);
      },
      onSuccess: (data) => {
        setErrors({});
        setLoading(false);
        queryClient.invalidateQueries(['applications']);
        if (options?.onSuccess) options.onSuccess(data);
      }    
    })
  };

  return {
    errors,
    onSave,
    availableAddons,
    isLoading,
    loading
  };
};

export default useApplication;