import React from 'react';
import {
  Table,
} from 'antd';
import { useIntl } from 'react-intl';
import columns from './columns';
import memoOnlyForKeys from "../../../../utils/memoOnlyForKeys";
import { ApplicationType } from '../../../../types';

interface Props {
  applications: ApplicationType[];
  loading: boolean;
  pagination: any;
  fetchData: (page: number, pageSize: number) => void;
  onEdit: (id: string) => void;
}

const ApplicationList: React.FC<Props> = props => {
  const {
    applications,
    loading,
    pagination,
    fetchData,
    onEdit,
  } = props;

  const intl = useIntl();
  const { total = 2, pageSize } = pagination;
  
  return (
    <>
      <Table
        key="answer-table"
        dataSource={applications || []}
        loading={loading}
        size="small"
        columns={columns(onEdit, intl)}
        sticky={{ offsetHeader: 80 }}
        rowKey={(record: any, index: number) => `site-${index}`}
        pagination={{
          onChange: (page: number, pageSize: number) => fetchData(page, pageSize),
          total: total * pageSize,
          pageSizeOptions: ["15", "30", "50", "100"],
          showSizeChanger: true,
          pageSize,
          onShowSizeChange: (page, pageSize) => fetchData(page, pageSize),
        }}
      />
    </>
  );
};

export default React.memo(ApplicationList, memoOnlyForKeys(['applications', 'loading']));