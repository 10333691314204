import client from "../utils/client";

import castAttributesFromModel from "../utils/castAttributesFromDefinition";

const definition = {
  title: "String",
  logo: "File",
  navigationColor: "String",
  secondaryColor: "String",
  primaryColor: "String",
  availableLocales: "Array",
  themeColors: "Array",
  rootHierarchyTitleTranslations: "Map",
  hierarchies: "Nested",
  _destroy: "Boolean",
};

const fetch = async (search = {}) => {
  const response = await client.get('/tenants.json');
  return response;
};

const save = async (tenant) => {
  const response = await client.request({
    method: "put",
    url: `/tenants/${tenant.id}`,
    //@ts-ignore
    data: castAttributesFromModel(definition, tenant, "tenant"),
  });
  return response;
};

const Tenant = {
  fetch,
  save
};

export default Tenant;