import React, { useState, useEffect } from "react";
import { PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Select,
} from "antd";
import { useIntl } from 'react-intl';
import AntForm, { AntSchema } from "@9troisquarts/ant-form.ant-form";
import sortBy from "lodash/sortBy";
import findIndex from "lodash/findIndex";
import filter from "lodash/filter";
import DraggableTable from "../../../../components/DraggableTable/DraggableTable";
import { columns } from "./columns";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import styles from "./index.module.sass";
import memoOnlyForKeys from "../../../../utils/memoOnlyForKeys";
import { HierarchyBranch, ApplicationType, Group } from "../../../../types";
import { hasRole } from "../../../../utils/Authorization";

const { Option } = Select;

const schema = (intl: any, group: any):AntSchema => ([[
  {
    name: "titleTranslations",
    label: intl.formatMessage({id: "activerecord.attributes.hierarchies.title_hierarchy" }),
    input: {
      type: "string",
      localize: true,
      inputProps: {
        disabled: !hasRole(group, ["hierarchy_create", "hierarchy_update"])
      }
    },
    colProps: {
      xs: 16,
      md: 8,
    },
  },
  {
    name: "rootHierarchyTitleTranslations",
    label: intl.formatMessage({ id: "activerecord.attributes.hierarchies.title_highest_level" }),
    input: {
      type: "string",
      localize: true,
      inputProps: {
        disabled: !hasRole(group, ["hierarchy_create", "hierarchy_update"])
      }
    },
    colProps: {
      xs: 16,
      md: 8,
    },
  },
]]);

interface Props {
  hierarchyTree: any;
  treeErrors: any;
  onChange: (hierarchyTree: any, index: number) => void;
  index: number;
  onEditBranch: (branch: any) => void;
  locale: string;
  applications: ApplicationType[];
  group: Group;
}

const HierarchyTreeTabContent: React.FC<Props> = props => {
  const {
    hierarchyTree,
    treeErrors,
    onChange,
    onEditBranch,
    index,
    locale,
    applications,
    group,
  } = props;

  const intl = useIntl();

  const [branches, setBranches] = useState(filter(hierarchyTree.hierarchyBranches, hb => !hb._destroy).map((b, i) => ({ ...b, position: i + 1 })));
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  useEffect(() => setBranches(filter(hierarchyTree.hierarchyBranches, hb => !hb._destroy).map((b, i) => ({ ...b, position: i + 1 }))), [hierarchyTree]);
  const getLevelOptions = () => {
    let options: Array<{value: number; label: string}> = [];
    if (branches) options = branches.map((branch) => ({ value: branch.position, label: branch.title || branch.titleTranslations[locale] }));
    options.unshift({ value: 0, label: hierarchyTree?.rootHierarchyTitleTranslations ? hierarchyTree?.rootHierarchyTitleTranslations["fr"] : "" });
    return options;
  };

  const onMoveRow = (hierarchyBranches: HierarchyBranch[], branch: HierarchyBranch, position: number) => {
    const nextBranches = hierarchyBranches.map((hb, i) => ({
      ...hb,
      position: i + 1
    }));
    if (branch.highestComparedLevel >= position) {
      const newBranch = { ...branch };
      newBranch.highestComparedLevel = position - 1;
      newBranch.position = position;
      nextBranches[findIndex(hierarchyBranches, b => b.position === branch.position)] = newBranch;
    }
    onChange({ hierarchyBranches: nextBranches }, index);
  };

  const getStringForType = (type) => intl.formatMessage({ id: `application_type.${type}`})
  return (
    <div className={styles.hierarchyTreeWrapper}>
      {hierarchyTree._destroy && (
        <>
          <div className={styles.cancelDeleteBackground} />
          <div className={styles.cancelDelete} onClick={() => onChange({ _destroy: false }, index)}>
            <div>
              Annuler la suppression
            </div>
          </div>
        </>
      )}
      {hierarchyTree.slug && (
        <div className={styles.slugWrapper}>
          Slug :
          <span>
            {hierarchyTree.slug}
          </span>
        </div>
      )}
      <AntForm
        errors={treeErrors?.errors}
        schema={schema(intl, group)}
        object={hierarchyTree}
        locale={locale}
        onChange={(value) => onChange(value, index)}
      />
      {treeErrors?.errors?.messages && (
        <Alert style={{ textAlign: 'center', marginBottom: "5px" }} message="Au moins une branche doit être renseignée" type="error" />
      )}
      <DraggableTable
        onMoveEnd={onMoveRow}
        size="small"
        key="section-table"
        pagination={false}
        columns={columns(getLevelOptions(), onEditBranch, treeErrors, locale, group)}
        dataSource={filter(sortBy(branches, hb => hb.position), hb => !hb._destroy) || []}
        scroll={{ y: 600 }}
        rowKey={(record: any, index: number) => `section-${index}`}
        footer={() => (
          hasRole(group, ["hierarchy_update", "hierarchy_create"]) ? (
            <a onClick={() => onEditBranch({ position: branches?.length ? filter(branches, hb => !hb._destroy)?.length + 1 : 1 })}>
              <PlusOutlined />
              Ajouter un niveau hiérarchique
            </a>
          ) : undefined
        )}
      />
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <span className={styles.selectLabel}> Niveau de comparaison le plus haut pour les sites </span>
        <br />
        <Select
          className={styles.selectWrapper}
          disabled={!hasRole(group, ["hierarchy_update", "hierarchy_create"])}
          //@ts-ignore
          getPopupContainer={() => document.getElementById("layout-network-manager")}
          defaultValue={hierarchyTree.siteHighestComparedLevel}
          onChange={(value) => onChange({ siteHighestComparedLevel: value }, index)}
        >
          {getLevelOptions().map((option) => (
            <Option key={`option-${option.value}`} value={option.value}>{option.label}</Option>
          ))}
        </Select>
      </div>
      <div className={styles.appSelectorWrapper}>
        <span style={{ fontWeight: 500, fontSize: "14px" }}>Utilisable par</span>
        <Select
          onChange={(value) => onChange({ applicationIds: value }, index)}
          disabled={!hasRole(group, ["hierarchy_create", "hierarchy_update"])}
          value={hierarchyTree.applicationIds}
          //@ts-ignore
          getPopupContainer={() => document.getElementById("layout-network-manager")}
          mode="multiple"
        >
          {applications.filter(app => app.active).map(app => (
            <Select.Option
              value={app.id}
            >
              {`${app.name} (${getStringForType(app.applicationType)})`}
            </Select.Option>
          ))}
        </Select>
      </div>
      {hasRole(group, ["hierarchy_delete"]) && (
        <div className={styles.deleteWrapper}>
          <ConfirmationModal
            title={"Supprimer la hiérarchie"}
            open={confirmationOpen}
            onOk={() => onChange({ _destroy: true }, index)}
            onCancel={() => setConfirmationOpen(false)}
            content={(
              <div style={{ color: "red" }}>
                {intl.formatMessage({ id: "hierarchy_tree.delete.warning"})}
              </div>
            )}
            keyPhrase="supprimer"
          />
          <Button
            danger
            onClick={() => setConfirmationOpen(true)}
          >
            {intl.formatMessage({ id: "delete" })}
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(HierarchyTreeTabContent, memoOnlyForKeys(["hierarchyTree", 'treeErrors', 'locale']));
