/* eslint-disable no-console */
import { useEffect } from 'react';

interface Params {
  key: string;
  callback: any;
  debug?: boolean;
  enabled: boolean;
  ctrlKey?: boolean;
  deps: any;
}

const useDocumentKeypress = (options: Params) => {
  const {
    key,
    callback,
    debug = false,
    enabled = true,
    ctrlKey = true,
    deps = [],
  } = options;
  useEffect(() => {
    const onKeyPress = e => {
      if ((!ctrlKey || e.ctrlKey) && e.key === key && enabled) {
        e.stopPropagation();
        e.preventDefault();
        callback();
      }
    };

    document.addEventListener('keydown', onKeyPress);

    return () => document.removeEventListener('keydown', onKeyPress);
  }, deps);
};

export default useDocumentKeypress;