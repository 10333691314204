import client from "../utils/client";
import { extractResource, throwResource } from "../utils/restApi";

const sync = () => {
  const promise = client.get('/hierarchies/sync')
  return promise.then(extractResource).catch(throwResource);
}

const Hierarchies = {
  sync
};

export default Hierarchies;