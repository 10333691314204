import uniqBy from "lodash/uniqBy";
import { useQuery } from 'react-query';
import FullCxLogo from "../../../../../../../public/cx_first.svg";
import FullMtLogo from "../../../../../../../public/cx_training.svg";
import FullNwLogo from "../../../../../../../public/nw_manager.svg";
import UserService from "../../../../services/user";

export const getApplication = (applications, appId) => {
  if (appId === "network-manager") return { name: "Network Manager" };

  return applications[applications.findIndex(app => app.id === appId)];
};

export const readeableApps = (applications, currentUser, group) => {
  let apps = applications.filter(app => app.active);
  if (["super-administrateur", "administrateur"].includes(group.slug)) {
    return apps;
  }
  if (currentUser.applicationSettings?.length) {
    const applicationSettingIds = currentUser.applicationSettings.map(app => app.applicationId);
    apps = apps.filter(app => applicationSettingIds.includes(app.id));
  }
  if (group.roles) {
    apps = apps.filter(app => group.roles.includes(`user_application_${app.id}`));
  }
  return apps;
}

// TODO: Refactor en utilisant readableApps
export const availableApps = (applications, editedUser, group, currentUser) => {
  let apps: any = applications.filter(app => app.active);
  if (["super-administrateur", "administrateur"].includes(group.slug)) {
    return apps.map(app => ({ type: app.applicationType, applicationId: app.id, editable: true }));
  }

  if (editedUser.persisted) {
    apps = editedUser.applicationSettings;
    apps = apps.concat(currentUser.applicationSettings);

    apps = uniqBy(apps, app => app.applicationId);
  } else {
    apps = currentUser.applicationSettings;
  }
  if (group.roles) {
    apps = apps.filter(app => group.roles.includes(`user_application_${app.applicationId}`));
  }

  return apps;
};

export const logoForApp = {
  cxfirst: {
    compact: FullCxLogo,
    full: FullCxLogo,
  },
  "micro-training": {
    compact: FullMtLogo,
    full: FullMtLogo,
  },
  "network-manager": {
    compact: FullNwLogo,
    full: FullNwLogo,
  }
};

const getCurrentUser = async () => {
  const response = await UserService.current();
  return response;
};

export const useCurrentUser = () => {
  const { data } = useQuery(['user'], getCurrentUser, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const user = data?.user;

  return user;
};