import {
  PageHeader,
  Card,
  Button
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationType as ApplicationType } from '../../types';
import ApplicationList from './components/ApplicationList';
import ApplicationEdit from './components/ApplicationEdit';
import styles from './index.module.sass';
import memoOnlyForKeys from '../../utils/memoOnlyForKeys';
import useI18n from '../../utils/useI18n';

interface IProps {
  applications: ApplicationType[];
  currentApplication: ApplicationType;
  onEdit: (id) => void;
  pagination: any;
  open: boolean;
  onCancel: () => void;
  onSave: (params: any) => void;
  errors: { [k: string]: string };
  loading: boolean;
}

const Application: React.FC<IProps> = props => {
  const {
    applications,
    currentApplication,
    onEdit,
    pagination,
    open,
    onCancel,
    errors,
    onSave,
    loading,
  } = props;

  const { t } = useI18n();

  return (
    <div className={styles.applicationWrapper}>
      <PageHeader className={styles.pageHeader} title={t("applications.header")} />
      <div className={styles.actionsWrapper} key="actions-wrapper">
        <Button onClick={() => onEdit(null)}>Ajouter un portail</Button>
      </div>
      <Card>
        <ApplicationList
          applications={applications}
          onEdit={onEdit}
          pagination={pagination || {}}
          loading={false}
          fetchData={() => undefined}
        />
        {open && (
          <ApplicationEdit
            application={currentApplication}
            open={open}
            onClose={onCancel}
            errors={errors}
            onSubmit={onSave}
            loading={loading}
          />
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  applications: state.applications.list,
  pagination: state.applications.pagination,
  open: state.applications.open,
  errors: state.applications.errors,
  currentApplication: state.applications.current,
  loading: state.applications.loading,
});

const mapDispatchToProps = dispatch => ({
  fetchData: (page, pageSize, params) => dispatch({ type: 'application/fetch', payload: { page, pageSize, params } }),
  onEdit: (id) => dispatch({ type: 'application/setEdit', payload: id }),
  onCancel: () => {
    dispatch({ type: 'application/setOpen', payload: false });
    dispatch({ type: 'application/setCurrent', payload: {} });
    dispatch({ type: 'application/setErrors', payload: {} });
  },
  onSave: (params) => dispatch({ type: "application/saveApplication", payload: params }),
  onDelete: (id) => dispatch({ type: "application/deleteApplication", payload: id }),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Application), memoOnlyForKeys(["applications", "open"]));