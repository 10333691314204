import React, { useState } from "react";
import { Tabs, Tooltip } from 'antd';
import { Group } from "../../../../types";
import HierarchyTitleListTab from "./HierarchyTitleListTab";

const { TabPane } = Tabs;

interface HierarchyListProps {
  branches: {withoutBranch?: boolean; slug: string; title: string }[];
  onEdit: (id: string) => void;
  group: Group;
}

const HierarchyList: React.FC<HierarchyListProps> = props => {
  const {
    branches,
    onEdit,
    group,
  } = props;

  const [key, setKey] = useState(branches[0]?.slug);

  return (
    <Tabs
      activeKey={key}
      onChange={(active) => setKey(active)}
    >
      {branches.map((branch: { slug: string; title: string; withoutBranch: boolean }) => (
        <TabPane
          tab={branch.withoutBranch ? (<Tooltip title="Aucune branche associée a ces titres">{branch.title}*</Tooltip>) : branch.title}
          key={branch.slug}
        >
          <HierarchyTitleListTab
            branch={branch}
            onEdit={onEdit}
            group={group}
          />
        </TabPane>
      ))}
    </Tabs>
  );
};

export default HierarchyList;