import React, { useState, useEffect, useMemo } from 'react';
import { Collapse } from 'antd';
import { useIntl } from 'react-intl';
import AccountForm from './AccountForm';
import PortalList from './PortalList';
import LocalesForm from './LocalesForm';
import HierarchyFiltersForm from "./HierarchyFiltersForm";
import RolesForm from "./RolesForm";
import StreamListForm from "./StreamListForm";
import PublicationLevelForms from './PublicationLevelsForm';
import NotificationForm from './EditNotification';
import { User, ApplicationType, HierarchyTree, Group } from '../../../../types';
import styles from "./index.module.sass";

interface IProps {
  editingUser: User;
  currentUser: User;
  onChange: (values: any) => void;
  applications: ApplicationType[];
  availableStreams: string[];
  availableLocales: {
    [key: string]: string[];
  };
  tenantLocales: string[];
  concurrentLocales: boolean;
  sameLocale: boolean;
  concurrentTrees: boolean;
  hasIdenticalHierarchyFilters: boolean;
  availableTrees: HierarchyTree[];
  sameTrees: boolean;
  nmGroupOptions: { value: string; label: string }[];
  availableRolesForApp: { [key: string]: string[] };
  wizardSteps: string[];
  errors: any;
  locale: string;
  setNextIsAvailable: (nextIsAvailable: boolean) => void;
  onEdit: (id: string) => void;
  saveLoading: boolean;
  group: Group;
  authProviders: { name: string; provider: string }[];
  hasApiKey: boolean;
}
const { Panel } = Collapse;

type PanelTitleProps = {
  title: string;
  error: boolean;
};
const PanelTitle: React.FC<PanelTitleProps> = ({ title, error }) => (
  <span className={error ? 'error' : ''}>
    {title}
  </span>
)

const hasErrorOnType = (errors, type) => !!errors[type]?.length

const EditSummary: React.FC<IProps> = props => {
  const {
    wizardSteps,
    errors,
    applications,
    saveLoading,
    editingUser
  } = props;
  const intl = useIntl();

  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const errorsByType = useMemo(() => {
    if (!errors) return {};
    
    const groupedErrors = {};
    applications.forEach(app => {
      if (errors[app.id]) {
        Object.keys(errors[app.id]).forEach(key => {
          if (!groupedErrors[key]) groupedErrors[key] = [];
          if (groupedErrors[key]) {
            groupedErrors[key].push({
              applicationId: app.id,
              errors: errors[app.id][key]
            });
          }
        });
      }
    });
    return groupedErrors;
  }, [errors]);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      const newKeys = [...activeKeys];
      applications.forEach(app => {
        if (errors[app.id]) {
          if (errors[app.id].roles) newKeys.push('roles');
          if (errors[app.id].hierarchyFilters) newKeys.push('hierarchy_filters');
          if (errors[app.id].access) newKeys.push('streams');
        }
      });
      setActiveKeys(newKeys);
    }
  }, [saveLoading, errors]);

  const onCollapseClick = (key) => setActiveKeys([...key]);

  const selectedApplications = (editingUser.applicationSettings || []).filter(appSetting => (!appSetting._destroy && appSetting.editable));
  const hasVisibleApplications = selectedApplications.length > 0;

  return (
    <div style={{ marginTop: "-24px" }}>
      <AccountForm {...props} />
      { wizardSteps.includes('portals') && (
        <PortalList {...props} />
      )}
      <Collapse className={styles.collapseWrapper} ghost activeKey={activeKeys} onChange={onCollapseClick}>
        { hasVisibleApplications && wizardSteps.includes('language') && (
          <Panel header={<PanelTitle title={intl.formatMessage({ id: "user.wizard.step.language" })} error={hasErrorOnType(errorsByType, 'locale')} />} key="language">
            <LocalesForm
              {...props}
              editingUser={editingUser}
              hideTitle
              errors={errorsByType['locale']}
            />
          </Panel>
        )}
        { wizardSteps.includes('hierarchy_filters') && (
          <Panel header={<PanelTitle title={intl.formatMessage({ id: "user.wizard.step.hierarchy_filters" })} error={hasErrorOnType(errorsByType, 'hierarchy_filters') || !!errors?.hierarchyFilters} />} key="hierarchy_filters">
            <HierarchyFiltersForm
              {...props}
              errors={errors?.hierarchyFilters}
              editingUser={editingUser}
              hideTitle
            />
          </Panel>
        )}
        { hasVisibleApplications && wizardSteps.includes('roles') && (
          <Panel header={<PanelTitle title={intl.formatMessage({ id: "user.wizard.step.roles" })} error={hasErrorOnType(errorsByType, 'roles')} />} key="roles">
            <RolesForm
              {...props}
              errors={errorsByType['roles']}
              editingUser={editingUser}
              hideTitle
            />
          </Panel>
        )}
        { wizardSteps.includes('streams') && (
          <Panel header={<PanelTitle title={intl.formatMessage({ id: "user.wizard.step.streams" })} error={hasErrorOnType(errorsByType, 'access')} />} key="streams">
            <StreamListForm
              {...props}
              errors={errorsByType['access']}
              editingUser={editingUser}
              hideTitle
            />
          </Panel>
        )}
        { wizardSteps.includes('publication_levels') && (
          <Panel header={<PanelTitle title={intl.formatMessage({ id: "user.wizard.step.publication_levels" })} error={!!errors?.publicationLevels} />} key="publication_levels">
            <PublicationLevelForms
              {...props}
              errors={errors?.publicationLevels}
              editingUser={editingUser}
              hideTitle
            />
          </Panel>
        )}
        { wizardSteps.includes('notifications') && (
          <Panel header={<PanelTitle title={intl.formatMessage({ id: "user.wizard.step.notifications" })} error={!!errorsByType['notifications']?.length} />} key="notifications">
            <NotificationForm
              {...props}
              editingUser={editingUser}
              hideTitle
            />
          </Panel>
        )}
      </Collapse>
    </div>
  );
};

export default EditSummary;