import React, { useEffect, useState } from "react";
import { AntForm } from "@9troisquarts/ant-form.ant-form";
import {
  Modal,
  Button,
} from "antd";
import { useIntl } from "react-intl";
import styles from "../index.module.sass";

interface IProps {
  open: boolean;
  group: any;
  onSubmit: (group) => void;
  onCancel: () => void;
}

const GroupShow: React.FC<IProps> = props => {
  const {
    open,
    group,
    onSubmit,
    onCancel,
  } = props;
  const intl = useIntl();
  const [editGroup, setEditGroup] = useState<any>(group);
  useEffect(() => setEditGroup(group), [group]);

  return (
    <Modal
      visible={open}
      onCancel={onCancel}
      title={editGroup.name || intl.formatMessage({ id: "groups.new" })}
      footer={[
        <div className={styles.footerAction}>
          <Button key="cancel" onClick={onCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button
            loading={false}
            type="primary"
            key="submit"
            disabled={!editGroup?.name}
            onClick={() => onSubmit(editGroup)}
          >
            {intl.formatMessage({ id: "save" })}
          </Button>
        </div>
      ]}
    >
      <AntForm
        layout="vertical"
        schema={[{
          name: "name",
          label: intl.formatMessage({ id: "activerecord.attributes.group.name" }),
          required: true,
          input: {
            type: "string",
          }
        }]}
        onChange={(value) => setEditGroup({ ...editGroup, ...value })}
        object={editGroup}
      />
    </Modal>
  )
}

export default GroupShow;