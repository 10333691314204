import React, { useEffect, useState } from "react";
import { findIndex, filter, sortBy } from "lodash";
import AntForm, { AntSchema } from "@9troisquarts/ant-form.ant-form";
import { useIntl } from 'react-intl';
import {
  Button,
  Popconfirm,
  Modal,
  PageHeader,
} from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import memoOnlyForKeys from "../../../../utils/memoOnlyForKeys";
import styles from "./index.module.sass";
import { Group, HierarchyBranch } from "../../../../types";
import { hasRole } from "../../../../utils/Authorization";
import LocalesSwitcher from "../../../../components/LocaleSwitcher";

const schema = (levelOptions: { value: number; label: string }[]):AntSchema => {
  return [
    {
      name: "titleTranslations",
      label: "Titre",
      input: {
        type: "string",
        localize: true,
      }
    },
    {
      name: "codeHeader",
      label: "Code Header",
      input: {
        type: "string"
      }
    },
    {
      name: "highestComparedLevel",
      label: "Niveau comparé le plus haut",
      input: {
        type: "select",
        inputProps: {
          //@ts-ignore
          getPopupContainer: () => document.getElementById("hierarchyBranchForm")
        },
        options: levelOptions
      }
    }
  ];
};

interface Props {
  hierarchyBranch: any;
  hierarchyTree: any;
  errors: { errors: { [key:string]: string } };
  open: boolean;
  onTreeChange: (values: any) => void;
  onClose: () => void;
  locale: string;
  group: Group;
}

const HierarchyBranchForm: React.FC<Props> = props => {
  const {
    hierarchyBranch,
    errors,
    open,
    onClose,
    onTreeChange,
    locale,
    hierarchyTree,
    group,
  } = props;

  const intl = useIntl();

  const [nextHierarchyBranch, setNextHierarchyBranch] = useState(hierarchyBranch);

  useEffect(() => {
    setNextHierarchyBranch(hierarchyBranch);
  }, [open]);

  const onChange = (values: any) => {
    setNextHierarchyBranch({ ...nextHierarchyBranch, ...values });
  };

  const onDelete = () => {
    let newBranches = hierarchyTree.hierarchyBranches ? [...hierarchyTree.hierarchyBranches] : [];
    const branchIndex = findIndex(newBranches, b => b.position === nextHierarchyBranch.position);
    const newBranch = {
      ...nextHierarchyBranch,
      _destroy: true
    };
    if (nextHierarchyBranch.id) {
      newBranches[branchIndex] = newBranch;
    } else {
      newBranches.splice(branchIndex, 1);
    }
    newBranches = sortBy(newBranches, b => b.position);

    let branches: any = [];
    let newPosition = 1;
    newBranches.forEach(b => {
      if (!b._destroy) {
        branches.push({ ...b, position: newPosition })
        newPosition += 1;
      } else {
        branches.push({ ...b, position: null })
        b.position = null;
      }
    });
    onTreeChange({ hierarchyBranches: branches })
    onClose();
    setNextHierarchyBranch({});
  };

  const onSubmit = () => {
    const newBranches = hierarchyTree.hierarchyBranches ? [...hierarchyTree.hierarchyBranches] : [];
    const branchIndex = findIndex(newBranches, b => b.position === nextHierarchyBranch.position);
    const newBranch = {
      ...nextHierarchyBranch,
      highestComparedLevel: nextHierarchyBranch.highestComparedLevel
        ? nextHierarchyBranch.highestComparedLevel
        : 0
    };
    if (branchIndex >= 0) {
      newBranches[branchIndex] = newBranch;
    } else {
      newBranches.push(newBranch);
    }
    onTreeChange({ hierarchyBranches: newBranches });
    onClose();
    setNextHierarchyBranch({});
  };

  const getLevelOptions = () => {
    let options: Array<{value: number; label: string}> = [];
    if (hierarchyTree?.hierarchyBranches) options = filter(hierarchyTree.hierarchyBranches, hb => !hb._destroy).map((branch) => ({ value: branch.position, label: branch.title || branch.titleTranslations[locale] }));
    options.unshift({ value: 0, label: hierarchyTree?.rootHierarchyTitleTranslations ? hierarchyTree?.rootHierarchyTitleTranslations["fr"] : "" });
    return filter(options, o => o.value < nextHierarchyBranch.position);
  };

  const branchErrors:any = {};
  if (errors && errors["errors"] && hierarchyBranch) {
    const index = findIndex(hierarchyTree.hierarchyBranches, (hb: HierarchyBranch) => hb.position === hierarchyBranch.position);
    Object.keys(errors["errors"]).forEach((key: string) => {
      if (key.includes(`hierarchyBranches[${index}].`)) branchErrors[key.replace(`hierarchyBranches[${index}].`, '')] = errors["errors"][key];
    });
  }

  if (!open) return null;

  return (
    <Modal
      visible={open}
      onOk={onSubmit}
      width={600}
      title={
        <span>
          {nextHierarchyBranch.titleTranslations ? nextHierarchyBranch.titleTranslations[locale] : "Branche Hiérarchique"}
        </span>
      }
      onCancel={onClose}
      footer={[
        <div className={styles.footerAction}>
          {hasRole(group, ["hierarchy_delete"]) && (nextHierarchyBranch.position <= filter(hierarchyTree?.hierarchyBranches, hb => !hb._destroy).length) && (
            <Popconfirm
              onConfirm={() => onDelete()}
              {...(nextHierarchyBranch.id ? {} : { visible: false })}
              title="Attention, Les utilisateurs restreint a cette branche auront un accès total a la hiérarchie"
            >
              <Button danger>
                <DeleteOutlined />
                {intl.formatMessage({ id:"delete" })}
              </Button>
            </Popconfirm>
          )}
          <div className="ant-form-modal-actions" key="modalActions">
            <Button key="cancel" onClick={onClose}>
              {intl.formatMessage({ id:"cancel" })}
            </Button>
            <Button
              loading={false}
              type="primary"
              disabled={!nextHierarchyBranch.titleTranslations}
              key="submit"
              onClick={onSubmit}
            >
              {intl.formatMessage({ id:"save" })}
            </Button>
          </div>
        </div>,
      ]}
    >
      <div id="hierarchyBranchForm">
        <LocalesSwitcher />
        <AntForm
          schema={schema(getLevelOptions())}
          locale={locale || 'fr'}
          layout="vertical"
          object={nextHierarchyBranch}
          onChange={onChange}
          errors={branchErrors}
        />
      </div>
    </Modal>
  );
};

export default React.memo(HierarchyBranchForm, memoOnlyForKeys(["hierarchyBranch", 'open', 'locale']));
